export const Permission = {
    ApiViewShortBuildInfo: 10,

    ViewUsers: 101,
    ViewUserInfo: 102,
    ViewUserOwner: 103,
    CreateUsers: 104,
    EditUsers: 105,
    EditUserOwner: 106,
    DeleteUsers: 107,
    ViewMyself: 108,
    ViewOwnUserInfo: 109,

    ViewSnapshots: 151,
    ViewSnapshotsInfo: 152,
    ManualUploadSnapshots: 153,
    RebuildSnapshots: 154,
    MountSnapshots: 155,
    DownloadSnapshots: 156,
    UnmountSnapshots: 157,
    ViewOwnSnapshots: 158,
    ViewOwnSnapshotsInfo: 159,
    MountOwnSnapshots: 160,
    DownloadOwnSnapshots: 161,
    UnmountOwnSnapshots: 162,
    RebuildOwnSnapshots: 163,

    ViewBuildServers: 201,
    AddBuildServers: 202,
    DeleteBuildServers: 203,
    ViewBuildBranches: 204,
    ViewBuildBranchesInfo: 205,
    UpdateBranches: 206,
    AddBuildRequest: 207,
    ViewBuildRequests: 208,
    ViewBuildRequestInfo: 209,
    DeleteBuildRequest: 210,
    MountBuildRequestToDomains: 211,
    RevertBuildRequestToDomains: 212,
    ViewOwnBuildRequests: 213,
    ViewOwnBuildRequestsInfo: 214,
    DeleteOwnBuildRequest: 215,
    MountOwnBuildRequestsToDomains: 216,
    RevertOwnBuildRequestToDomains: 217,

    ViewConfigs: 251,
    ViewConfigsInfo: 252,
    AddConfigs: 253,
    EditConfigs: 254,
    DeleteConfigs: 255,
    ViewSnapshotsConfigsInfo: 256,
    ViewOwnConfigsInfo: 257,

    ViewDeployKeys: 301,
    AddDeployKeys: 302,
    BlockDeployKeys: 303,
    UnblockDeployKeys: 304,
    EditDeployKeys: 305,
    ViewDeployKeysInfo: 306,
    ViewOwnDeployKeys: 307,
    BlockOwnDeployKeys: 308,
    UnblockOwnDeployKeys: 309,
    EditOwnDeployKeys: 310,
    ViewOwnDeployKeysInfo: 311,

    ViewHosts: 351,
    AddHosts: 352,
    SearchDomains: 353,
    EditHosts: 354,
    ViewHostsInfo: 355,
    AddDomainInHost: 356,
    DeleteHosts: 357,
    DeleteDomains: 358,
    UnmountDomains: 359,
    ViewOwnHosts: 360,
    ViewOwnHostsInfo: 361,
    SearchOwnDomains: 362,
    EditOwnHosts: 363,
    AddDomainInOwnHost: 364,
    DeleteOwnHosts: 365,
    DeleteOwnDomains: 366,
    UnmountOwnDomains: 367,

    ViewJobs: 401,
    DeleteJobs: 402,

    ViewReports: 451,
    DeleteReports: 452,
    ViewReportsInfo: 453,

    ViewDashboard: 501
};


