import {customFetchDeployApi} from "../requests/requests";

export class HostsAPI {

    static async getHosts(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/hosts?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getHostInfo(id, page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/host/" + id + '?Page=' + (page === undefined ? 1 : page) + '&PageSize=' + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async HostDelete(id, body) {
        return await customFetchDeployApi({path: "/api/v2/host/" + id, body, method: 'DELETE'})
            .then(response => {
                return response
            })
    }

    static async addNewHost(body) {
        return await customFetchDeployApi({path: "/api/v2/hosts/", body, method: 'PUT'})
            .then(response => {
                return response
            })
    }

    static async editHost(body, id) {
        return await customFetchDeployApi({path: "/api/v2/host/" + id, body, method: 'POST'})
            .then(response => {
                return response
            })
    }

    static async getDomainInfo(hostId, domainId) {
        return await customFetchDeployApi({path: "/api/v2/host/" + hostId + '/' + domainId, method: 'GET'})
            .then(response => {
                return response
            })
    }


}