import React, {useCallback, useEffect, useState} from 'react';
import AsyncSelect from "react-select/async";
import {components} from "react-select";
import makeAnimated from "react-select/animated";

import Utils from "../../Auth/Utils";
import {API} from "../../api/API";

// In parent component
// const [selected, setSelected] = useState("");
// ...
// <AsyncSearchBar setSelected={setSelected} />

// const Input = props => {
//     const {onPaste} = props.selectProps;
//     return <components.Input {...props} onPaste={onPaste} />
// };

export const StyledAsyncSelect = props => {
    const {
        getOptionLabel, getOptionValue, isMulti = false, fetchMethod, customStyles,
        onChange: onChangeCallback, className, keyValue, onPaste
    } = props;

    const [defaultValue, setDefaultValue] = useState(props.defaultValue?.length > 0 ? props.defaultValue : []);
    const [value, setValue] = useState(props.defaultValue?.length > 0 ? props.defaultValue : []);
    const [query, setQuery] = useState("");

    const onChange = (e) => {
        let newValue = [...Object.assign([], e)].filter((v, i, a) => a.findIndex(v2 => (v2[keyValue] === v[keyValue])) === i);
        setValue(newValue);
        onChangeCallback(newValue);
    };

    useEffect(() => {
        if (props.defaultValue && props.defaultValue instanceof Array
            && (defaultValue?.length !== props.defaultValue?.length || !Utils.isEquivalent(defaultValue, props.defaultValue || []))
        ) {
            setDefaultValue([...props.defaultValue]);
            setValue([...props.defaultValue]);
            return;
        }
        if (props.defaultValue?.length > 0) {
            setValue([...value, ...props.defaultValue].filter((v, i, a) => a.findIndex(v2 => (v2[keyValue] === v[keyValue])) === i));
        }
    }, [props.defaultValue]);

    //get animated components wrapper
    // const animatedComponents = makeAnimated({Input});
    const animatedComponents = makeAnimated();

    const styles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
        }),
        menu: (provided, state) => ({
            ...provided,
        })
    };

    const loadOptions = useCallback(async (query) => {
        const response = await fetchMethod(query !== '' ? query : '');
        return new Promise((resolve => resolve(response ? response.data : [])))
    }, [query]);

    const onQuery = (value) => {
        setQuery(value);
    };

    // const onPaste = useCallback(async event => {
    //     const paste = (event.clipboardData || window.clipboardData).getData('text');
    //     const pastedItems = paste.split(/[\s,\n\r ]+/).filter(t => `${t}`.trim().length > 0);
    //     if (pastedItems?.length > 0) {
    //         event.preventDefault();
    //         const validation = await API.SnapShots.ValidateDomains(validationKey,  {domains: pastedItems});
    //         if (validation.data) {
    //             props.defaultValue?.length > 0 ?
    //                 setValue([...value, ...props.defaultValue, ...validation.data].filter((v, i, a) => a.findIndex(v2 => (v2[keyValue] === v[keyValue])) === i))
    //                 : setValue([...value, ...validation.data].filter((v, i, a) => a.findIndex(v2 => (v2[keyValue] === v[keyValue])) === i))
    //         }
    //         return false;
    //     }
    // },[]);

    return (
        <AsyncSelect
            isMulti={isMulti}
            value={value}
            components={animatedComponents}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadOptions={loadOptions}
            onInputChange={(value) => onQuery(value)}
            // onPaste={onPaste}
            onChange={onChange}
            classNamePrefix={className}
            styles={customStyles ? customStyles : styles}
            openMenuOnClick={true}
        />
    );
};
