import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import {Link} from "react-router-dom";
import Utils from "../../../Auth/Utils";
import {API} from "../../../api/API";
import CustomTable from "../../customTables/CustomTable";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {StyledButton} from "../../../customstyled/StyledButton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {UsersStore} from "../../../stores/UsersStore";
import {Permission} from "../../../rbac/Permissions";

const BuildRequestsTable = () => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [openForDevMount, setOpenForDevMount] = useState(false);
    const [buildRequestId, setBuildRequestId] = useState('');
    const [openForProdMount, setOpenForProdMount] = useState(false);
    const [openForDelete, setOpenForDelete] = useState(false);

    const status = [
        'Processing',
        'WaitBuildServer',
        'Building',
        'BuildError',
        'SnapshotUploaded',
        'MountedForTesting',
        'MountedForProduction'
    ];
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const onClicked = (e, id) => {
        e.preventDefault();
        setOpenForDevMount(true);
        setBuildRequestId(id)
    };

    const unmountOnClicked = (e, id) => {
        e.preventDefault();
        setBuildRequestId(id);
        setOpenForProdMount(true);
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setBuildRequestId(id);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForDevMount(false);
        setOpenForProdMount(false);
        setOpenForDelete(false);
    };

    const devMount = async () => {
        await API.BuildRequests.buildRequestDevMount(buildRequestId);
        setOpenForDevMount(false);
    };

    const prodMount = async () => {
        await API.BuildRequests.buildRequestProdMount(buildRequestId);
        setOpenForProdMount(false);
    };

    const deleteBuildRequest = async () => {
        const totalAfterDelete = await API.BuildRequests.buildRequestDelete(buildRequestId);
        setOpenForDelete(false);
        let currentPage = page;
        if (totalAfterDelete.total % size === 0) {
            currentPage = page - 1;
        }
        const table = await API.BuildRequests.getBuildRequests(currentPage === 0 ? '' : currentPage, size === 0 ? '' : size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start fileName">
                    {
                        userState.permissions?.includes(Permission.ViewBuildRequestInfo) || userState.permissions?.includes(Permission.ViewOwnBuildRequestsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewBuildRequestInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnBuildRequestsInfo)
                            ? <Link to={'/build-request/' + rowData.id}
                                    style={{textDecoration: 'underline', color: 'black', textAlign: 'start', marginLeft: 21}}>
                                <div className="d-flex flex-column align-items-start black">
                                    <span>{rowData.id}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{ marginLeft: 21}}>
                                <span>{rowData.id}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(4)
        },
        {
            name: 'configName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Name</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    {
                        userState.permissions?.includes(Permission.ViewConfigs) ||
                        userState.permissionsAssigned?.includes(Permission.ViewConfigs)
                            ? <Link to={'/config/' + rowData.configId}>
                                <div className="fileName" style={{textAlign: 'left!important', color: 'black'}}>
                                    <span style={{textDecoration: 'underline'}}>{rowData.configName}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{textAlign: 'left!important', color: 'black'}}>
                                <span>{rowData.configName}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(16)
        },
        {
            name: 'reportId',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Report</span>,
            cell: rowData => (
                (rowData.reportId === null) ? (
                    <div className="d-flex flex-column align-items-center black">
                        <span>no report</span>
                    </div>
                ) : (
                    <div className="d-flex flex-column align-items-center black">
                        {
                            userState.permissions?.includes(Permission.ViewReports) ||
                            userState.permissionsAssigned?.includes(Permission.ViewReports)
                                ? <Link to={'/report/' + rowData.reportId}
                                        style={{textDecoration: 'underline', color: 'black'}}>
                                    <span>{'#' + rowData.reportId}</span>
                                </Link>
                                : <span>{'#' + rowData.reportId}</span>
                        }
                    </div>
                )
            ),
            width: Utils.RemToPx(5)
        },
        {
            name: 'status',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Last status</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{status[rowData.status]}</span>
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            name: 'snapshotFileName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Snapshot</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    {
                        userState.permissions?.includes(Permission.ViewSnapshots) || userState.permissions?.includes(Permission.ViewOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.ViewSnapshots) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshots)
                            ? <Link to={'/snapshot/' + rowData.snapshotFileName}
                                    style={{textDecoration: 'underline', color: 'black'}}>
                                <span>{rowData.snapshotFileName}</span>
                            </Link>
                            : <span>{rowData.snapshotFileName}</span>
                    }
                </div>
            ),
            width: Utils.RemToPx(26)
        },
        {
            name: 'backupSnapshotFileName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Backup Snapshot</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    {
                        userState.permissions?.includes(Permission.ViewSnapshotsInfo) || userState.permissions?.includes(Permission.ViewOwnSnapshotsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewSnapshotsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshotsInfo)
                            ? <Link to={'/snapshot/' + rowData.backupSnapshotFileName}
                                    style={{textDecoration: 'underline', color: 'black'}}>
                                <span>{rowData.backupSnapshotFileName}</span>
                            </Link>
                            : <span>{rowData.backupSnapshotFileName}</span>
                    }
                </div>
            ),
            width: Utils.RemToPx(35)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex align-items-center justify-content-center">
                    {
                        userState.permissions?.includes(Permission.MountBuildRequestToDomains) || userState.permissions?.includes(Permission.MountOwnBuildRequestsToDomains) ||
                        userState.permissionsAssigned?.includes(Permission.MountBuildRequestToDomains) || userState.permissionsAssigned?.includes(Permission.MountOwnBuildRequestsToDomains)
                            ? <StyledButton onClick={e => onClicked(e, rowData.id)} type="danger"
                                            tooltip={"Mount for testing"}
                                            variant="contained"
                                            style={{
                                                height: 25,
                                                width: 28,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.MountBuildRequestToDomains) || userState.permissions?.includes(Permission.MountOwnBuildRequestsToDomains) ||
                        userState.permissionsAssigned?.includes(Permission.MountBuildRequestToDomains) || userState.permissionsAssigned?.includes(Permission.MountOwnBuildRequestsToDomains)
                            ? <StyledButton onClick={e => unmountOnClicked(e, rowData.id)}
                                            tooltip={"Mount for production"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                height: 25,
                                                width: 28,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 3
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.DeleteBuildRequest) || userState.permissions?.includes(Permission.DeleteOwnBuildRequest) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteBuildRequest) || userState.permissionsAssigned?.includes(Permission.DeleteOwnBuildRequest)
                            ?
                            <StyledButton onClick={e => deleteOnClicked(e, rowData.id)} icon="fas fa-solid fa-trash-can"
                                          type="danger"
                                          tooltip={"Delete build request"}
                                          variant="contained"
                                          style={{
                                              height: 25,
                                              width: 28,
                                              background: '#343a40',
                                              color: '#c2c7d0',
                                              border: 'none',
                                              borderRadius: 3,
                                              cursor: 'pointer',
                                              marginLeft: 3
                                          }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(25)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.BuildRequests.getBuildRequests(page, size);
        const total = table.totalCount;
        dispatch({type: "SET_TABLE_INFO", payload: table});
        setPage(page);
        setSize(size);
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div className="snapShotsTable">
                <div style={{float: 'none', marginBottom: '10px'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="route">Build Requests</span>
                        </Breadcrumbs>
                    </div>
                </div>
                <h3 className="tableTitle marginZero">ACP : Build Requests</h3>
                <section className="content">
                    <div id="table-container">
                        <CustomTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true,
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                     texts={{noItems: "No build requests..."}}/>
                    </div>
                </section>
            </div>

            <Dialog open={openForDevMount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to mount build to development domains
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={devMount} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDevMount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForProdMount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to mount build to production domains?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={prodMount} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForProdMount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot & then delete it?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteBuildRequest} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BuildRequestsTable;