import React from 'react';
import '../../../styles/All.css';
import SnapShotsVirtualizedTable from "../alltables/SnapShotsVirtualizedTable";

const Snapshots = () => {

    return (
        <div className="snapShotsTable">
            <SnapShotsVirtualizedTable/>
        </div>
    );
};

export default Snapshots;