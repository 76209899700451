import {customFetchDeployApi} from "../requests/requests";

export class BuildRequestsAPI {

    static async getBuildRequests(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/build-requests?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getBuildRequestInfo(id) {
        return await customFetchDeployApi({path: "/api/v2/build-request/" + id, method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async buildRequestDevMount(id) {
        return await customFetchDeployApi({path: "/api/v2/build-request/mount-development/" + id})
            .then(response => {
                return response
            })
    }

    static async buildRequestProdMount(id) {
        return await customFetchDeployApi({path: "/api/v2/build-request/mount-production/" + id})
            .then(response => {
                return response
            })
    }

    static async buildRequestDelete(id) {
        return await customFetchDeployApi({path: "/api/v2/build-request/" + id, method: 'DELETE'})
            .then(response => {
                return response
            })
    }

    static async buildRequest() {
        return await customFetchDeployApi({path: "/api/v2/build", method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async addBuildRequest(body) {
        return await customFetchDeployApi({path: "/api/v2/build/request", body, method: 'PUT'})
            .then(response => {
                return response
            })
    }

    static async updateBranches() {
        return await customFetchDeployApi({path: "api/v2/build/branches", method: 'POST'})
            .then(response => {
                return response
            })
    }
}