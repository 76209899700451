import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import {Link} from "react-router-dom";
import Utils from "../../../Auth/Utils";
import {API} from "../../../api/API";
import CustomTable from "../../customTables/CustomTable";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {StyledButton} from "../../../customstyled/StyledButton";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MenuItem from "@material-ui/core/MenuItem";
import {UsersStore} from "../../../stores/UsersStore";
import {Permission} from "../../../rbac/Permissions";
import {StyledAsyncSelect} from "../../../customstyled/AsyncSelect/StyledAsyncSelect";
import {StyledCreatableAsyncSelect} from "../../../customstyled/CreatableAsyncSelect/StyledCreatableAsyncSelect";
import md5 from "md5";
import {styled} from "@mui/material/styles";
import Pagination from "@material-ui/lab/Pagination";

const KeysTable = () => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [keysId, setKeysId] = useState('');
    const [open, setOpen] = useState(false);
    const [addNewKey, setAddNewKey] = useState({email: ''});
    const [editKey, setEditKey] = useState({});
    const [openWildcardEdit, setOpenWildcardEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);
    const [openForBlockUnblock, setOpenForBlockUnblock] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [blockedAddButton, setBlockedAddButton] = useState(false);
    const data = [
        {type: 'Yes', value: true},
        {type: 'No', value: false}
    ];

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const blockUnblockClicked = (e, id, isBlocked) => {
        e.preventDefault();
        setKeysId(id);
        setIsBlocked(isBlocked);
        setOpenForBlockUnblock(true);
    };

    const editWildcardClicked = async (e, id, wildcard, hosts, domains, isExpired) => {
        e.preventDefault();
        setKeysId(id);
        setEditKey({...editKey, wildcard: wildcard, hosts: hosts, domains: domains, isExpired: isExpired});
        setOpenWildcardEdit(true);

    };

    const editWildcard = async () => {
        await API.Keys.editKey(keysId, {
            wildcard: editKey.wildcard,
            isExpired: editKey.isExpired,
            domains: editKey.domains?.map(domain => domain.domainId),
            hosts: editKey.hosts?.map(host => host.hostId)
        });
        setOpenWildcardEdit(false);
        const table = await API.Keys.getKeys(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const handleClose = () => {
        setOpenWildcardEdit(false);
        setOpenForBlockUnblock(false);
    };

    const blockUnBlockKey = async () => {
        isBlocked === false ? (await API.Keys.keyBlock(keysId)) : (await API.Keys.keyUnBlock(keysId));
        setOpenForBlockUnblock(false);
        const table = await API.Keys.getKeys(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const onClicked = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected;
            setAddNewKey({
                ...addNewKey,
                domains: domainNames
            });
            setEditKey({
                ...editKey,
                domains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setAddNewKey({
                ...addNewKey,
                domains: domainNames
            });
            setEditKey({
                ...editKey,
                domains: domainNames
            });
        }
    };

    const selectedHostsChange = (selected) => {
        let hostNames = [];
        if (selected && selected.length > 0) {
            hostNames = selected;
            setAddNewKey({
                ...addNewKey,
                hosts: hostNames
            });
            setEditKey({
                ...editKey,
                hosts: hostNames
            });
        } else if (selected && selected.length === 0) {
            setAddNewKey({
                ...addNewKey,
                hosts: hostNames
            });
            setEditKey({
                ...editKey,
                hosts: hostNames
            });
        }
    };

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const emailChange = (selected) => {
        let isEmailCorrect = validateEmail(selected?.emailName);
        if (isEmailCorrect === null) {
            setBlockedAddButton(true);
        } else {
            setBlockedAddButton(false);
            selected ?
                setAddNewKey({
                    ...addNewKey,
                    email: selected.emailName
                }) :
                setAddNewKey({
                    ...addNewKey,
                    email: ''
                });
        }
    };

    const randomString = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const generateRandomKey = () => {
        let date = new Date().toISOString();
        let generated = md5(date + randomString(32));
        let randomKey = generated.substring(0, 32).toUpperCase();
        setAddNewKey({
            ...addNewKey,
            key: randomKey
        });
    };

    const addKey = async () => {
        await API.Keys.addNewKey({
            key: addNewKey.key || '',
            email: addNewKey.email,
            wildcard: addNewKey.wildcard,
            domains: addNewKey.domains?.map(domain => domain.domainId) || [],
            hosts: addNewKey.hosts?.map(host => host.hostId) || []
        });
        setOpen(false);
        const table = await API.Keys.getKeys(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{marginLeft: 21}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(5)
        },
        {
            name: 'email',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Email</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.email}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(25)
        },
        {
            name: 'key',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Key</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewDeployKeysInfo) || userState.permissions?.includes(Permission.ViewOwnDeployKeysInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewDeployKeysInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnDeployKeysInfo)
                            ? <Link to={'/key/' + rowData.key} style={{textDecoration: 'underline', color: "black"}}>
                                <span>{rowData.key}</span>
                            </Link>
                            : <span>{rowData.key}</span>
                    }
                </div>
            ),
            width: Utils.RemToPx(20)
        },
        {
            name: 'hostsCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Hosts</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewDeployKeysInfo) || userState.permissions?.includes(Permission.ViewOwnDeployKeysInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewDeployKeysInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnDeployKeysInfo)
                            ? <Link to={'/key/' + rowData.key} style={{textDecoration: 'underline', color: "black"}}>
                                <span>{rowData.hostsCount + ' [ details ] '}</span>
                            </Link>
                            : <span>{rowData.hostsCount + ' [ details ] '}</span>
                    }
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'domainsCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Domains</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewDeployKeysInfo) || userState.permissions?.includes(Permission.ViewOwnDeployKeysInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewDeployKeysInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnDeployKeysInfo)
                            ? <Link to={'/key/' + rowData.key} style={{textDecoration: 'underline', color: "black"}}>
                                <span>{rowData.domainsCount === 0 ? 'details' : rowData.domainsCount}</span>
                            </Link>
                            : <span>{rowData.domainsCount === 0 ? 'details' : rowData.domainsCount}</span>
                    }
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'isExpired',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Is Expired</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.isExpired === true ? 'Yes' : 'No'}</span>
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'wildcard',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Is Wildcard</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.wildcard === true ? 'Yes' : 'No'}</span>
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex justify-content-center align-items-center">
                    {
                        userState.permissions?.includes(Permission.BlockDeployKeys) || userState.permissions?.includes(Permission.BlockOwnDeployKeys) ||
                        userState.permissions?.includes(Permission.UnblockDeployKeys) || userState.permissions?.includes(Permission.UnblockOwnDeployKeys) ||
                        userState.permissionsAssigned?.includes(Permission.BlockDeployKeys) || userState.permissionsAssigned?.includes(Permission.BlockOwnDeployKeys) ||
                        userState.permissionsAssigned?.includes(Permission.UnblockDeployKeys) || userState.permissionsAssigned?.includes(Permission.UnblockOwnDeployKeys)
                            ? <StyledButton onClick={e => blockUnblockClicked(e, rowData.id, rowData.isExpired)}
                                            icon={rowData.isExpired === false ? "fas fa-lock-open" : "fas fa-lock-alt"}
                                            tooltip={"Block/Unblock key"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                height: 25,
                                                width: 28,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.EditDeployKeys) || userState.permissions?.includes(Permission.EditOwnDeployKeys) ||
                        userState.permissionsAssigned?.includes(Permission.EditDeployKeys) || userState.permissionsAssigned?.includes(Permission.EditOwnDeployKeys)
                            ? <StyledButton
                                onClick={e => editWildcardClicked(e, rowData.id, rowData.wildcard, rowData.hosts, rowData.domains, rowData.isExpired)}
                                icon="fas fa-edit"
                                tooltip={"Edit key"}
                                type="danger"
                                variant="contained"
                                style={{
                                    height: 25,
                                    width: 28,
                                    background: '#343a40',
                                    color: '#c2c7d0',
                                    border: 'none',
                                    borderRadius: 3,
                                    marginLeft: 3,
                                    cursor: 'pointer',
                                }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                </span>
            ),
            // width: Utils.RemToPx(13)
        }
    ].filter(el => el));
    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Keys.getKeys(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        setPage(page);
        setSize(size);
        const total = table.totalCount;
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    const customSelect = {
        control: (provided, state) => ({
            ...provided,
            width: 371
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
        }),
        menu: (provided, state) => ({
            ...provided,
        }),
    };

    return (
        <>
            <div className="snapShotsTable">
                <div style={{float: 'none'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="route">Keys</span>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="tableTitle d-flex justify-content-between">
                    <h3 className="keyTableTitle">ACP : Keys</h3>
                    <StyledButton onClick={e => onClicked(e)}
                                  titleClassname="addItemButton"
                                  icon="fas fa-plus"
                                  title="Add Key" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                        marginBottom: 10,
                        float: 'right'
                    }}
                    />
                </div>
                <section className="content">
                    <div id="table-container">
                        <CustomTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                     texts={{noItems: "No keys..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={openForBlockUnblock} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to {isBlocked === false ? 'block' : 'unblock'} the deploy key?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={blockUnBlockKey} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForBlockUnblock(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose} className="modal-addKey">
                <DialogTitle>
                    Add deploy key
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">1) Key:</label></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="Key"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={addNewKey.key?.length > 0 ? addNewKey?.key : ''}
                            key={'unique-key'}
                            sx={{
                                '& .css-ghsjzk-MuiInputBase-root-MuiInput-root': {
                                    borderTopRightRadius: 'unset',
                                    borderBottomRightRadius: 'unset'
                                }
                            }}
                            style={{width: 335}}
                            onChange={(event => setAddNewKey({...addNewKey, key: event.target.value}))}
                        />
                        <StyledButton onClick={() => generateRandomKey()}
                                      disabled={false} tooltip="Generate random key"
                                      type="success" variant="contained" icon="fas fa-dice" style={{
                            background: '#343a40',
                            color: '#c2c7d0',
                            border: 'none',
                            borderRadius: 3,
                            borderTopLeftRadius: 'unset',
                            borderBottomLeftRadius: 'unset',
                            cursor: 'pointer',
                            height: 34,
                            width: 36,
                            marginTop: 8,
                            marginRight: 7
                        }}/>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">2) Email:</label></div>
                        <StyledCreatableAsyncSelect
                            isMulti={false}
                            getOptionLabel={(e) => e.emailName}
                            getOptionValue={(e) => e.emailId}
                            onChange={emailChange}
                            className={"keysStyledAsyncSelect"}
                            fetchMethod={API.Users.getEmailsList}
                            keyValue='emailName'
                            isInputValueCorrect={!blockedAddButton}
                            customStyles={customSelect}
                        />
                    </div>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">3) Develop Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainId}
                                onChange={handleChange}
                                className={"keysStyledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                                customStyles={customSelect}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 last padding-4">
                        <div className="col-1"><label className="col-form-label">4) Hosts:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.hostName}
                                getOptionValue={(e) => e.hostId}
                                onChange={selectedHostsChange}
                                className={"keysStyledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchHosts}
                                keyValue='hostName'
                                customStyles={customSelect}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">5) Wildcard:</label></div>
                        <TextField
                            // autoFocus
                            margin="dense"
                            select
                            id="wildcard"
                            type="text"
                            value={addNewKey?.wildcard || false}
                            fullWidth
                            variant="standard"
                            onChange={(event => setAddNewKey({...addNewKey, wildcard: event.target.value}))}
                            style={{width: 335}}
                        >
                            {data.map((option, i) => (
                                <MenuItem key={`data-${i}`} value={option.value}>
                                    {option.type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={addKey} title="Add key" type="danger"
                                  variant="contained" disabledButton={blockedAddButton} style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openWildcardEdit} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Edit Key: {keysId}
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">1) Wildcard:</label></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            select
                            id="wildcard"
                            label="Is Wildcard"
                            type="text"
                            value={editKey?.wildcard}
                            fullWidth
                            variant="standard"
                            onChange={(event => setEditKey({...editKey, wildcard: event.target.value}))}
                        >
                            {data.map((option, i) => (
                                <MenuItem key={`data-${i}`} value={option.value}>
                                    {option.type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">2) IsExpired:</label></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            select
                            id="isExpired"
                            label="Is Expired"
                            type="text"
                            value={editKey?.isExpired}
                            fullWidth
                            variant="standard"
                            onChange={(event => setEditKey({...editKey, isExpired: event.target.value}))}
                        >
                            {data.map((option, i) => (
                                <MenuItem key={`data-${i}`} value={option.value}>
                                    {option.type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">3) Develop Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={editKey.domains}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainId}
                                onChange={handleChange}
                                className={"keysStyledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 last padding-4">
                        <div className="col-1"><label className="col-form-label">4) Hosts:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={editKey.hosts}
                                getOptionLabel={(e) => e.hostName}
                                getOptionValue={(e) => e.hostId}
                                onChange={selectedHostsChange}
                                className={"keysStyledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchHosts}
                                keyValue='hostName'
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={editWildcard} title="Edit" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenWildcardEdit(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default KeysTable;