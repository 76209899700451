import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../../../stores/MainStore";
import {SnapshotsDomainsStore} from "../../../../../stores/SnapshotDomainsStore";
import Utils from "../../../../../Auth/Utils";
import {API} from "../../../../../api/API";
import DomainsTable from "../../../../customTables/DomainsTable";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {Permission} from "../../../../../rbac/Permissions";
import {UsersStore} from "../../../../../stores/UsersStore";

export const KeyDomainsInfo = (props) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(SnapshotsDomainsStore);
    const {state: userState} = useContext(UsersStore);
    const [openForUnmount, setOpenForUnmount] = useState(false);
    const [domainId, setDomainId] = useState('');
    const [hostId, setHostId] = useState('');

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const handleClose = () => {
        setOpenForUnmount(false);
    };

    const unmountOnClicked = (e, id, hostId) => {
        e.preventDefault();
        setDomainId(id);
        setHostId(hostId);
        setOpenForUnmount(true);
    };

    const unmountDomain = async () => {
        await API.SnapShots.unmountDomain(hostId, domainId);
        setOpenForUnmount(false);
    };


    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 23}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 23}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(7)
        },
        {
            name: 'domainName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Domain</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.domainName}</span>
                </div>
            ),
            minWidth: Utils.RemToPx(24)
        },
        {
            name: 'type',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Type</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.type}</span>
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'configName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Config</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.configName}
                </span>
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'keysCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Keys count</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.keysCount}
                </span>
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    {
                        userState.permissions?.includes(Permission.UnmountDomains) || userState.permissions?.includes(Permission.UnmountOwnDomains) ||
                        userState.permissionsAssigned?.includes(Permission.UnmountDomains) || userState.permissionsAssigned?.includes(Permission.UnmountOwnDomains)
                            ? <StyledButton onClick={e => unmountOnClicked(e, rowData.id, rowData.hostId)}
                                            icon="fas fa-eraser" type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(10)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);


    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Keys.getKeyDomainsInfo(props.keys, page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div className="snapshotDomains">
                <section className="content">
                    <div id="table-container">
                        <DomainsTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                      texts={{noItems: "No results..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={openForUnmount} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Are you sure you want to unmount the domain?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={unmountDomain} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForUnmount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

