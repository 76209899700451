import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@mui/material/Divider";
import {API} from "../../../api/API";
import {StyledButton} from "../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {Permission} from "../../../rbac/Permissions";
import {Role} from "../../../rbac/Roles";

/**
 * @param {number} permissionId
 * @returns {string}
 */
const RenderPermissionName = permissionId => Object.keys(Permission)
    .find(key => Permission[key] === permissionId) || `UnknownPermission#${permissionId}`;

const RenderRoleName = roleId => Object.keys(Role)
    .find(key => Role[key] === roleId) || `UnknownRole#${roleId}`;

const RbacView = (props) => {
    const [openPermissionAvailable, setOpenPermissionAvailable] = useState(true);
    const [openRoleAvailable, setOpenRoleAvailable] = useState(true);
    const [openPermissionAssigned, setOpenPermissionAssigned] = useState(true);
    const [openRoleAssigned, setOpenRoleAssigned] = useState(true);
    const [permissionsAvailable, setPermissionsAvailable] = useState([]);
    const [rolesAvailable, setRolesAvailable] = useState([]);
    const [permissionsAssign, setPermissionsAssign] = useState([]);
    const [rolesAssign, setRolesAssign] = useState([]);
    const [permissionsPopValue, setPermissionsPopValue] = useState('');
    const [rolesPopValue, setRolesPopValue] = useState('');
    const [permissionsAddValue, setPermissionsAddValue] = useState('');
    const [rolesAddValue, setRolesAddValue] = useState('');
    const [arrowReverse, setArrowReverse] = useState(true);
    const [openForEdit, setOpenForEdit] = useState(false);


    useEffect(() => {
        (async () => {
            let assign = await API.Users.getUserRBAC(props.id);
            setRolesAssign(assign.data.roles);
            setPermissionsAssign(assign.data.permissions);
            let checkedRoles = assign.data.roles;
            let roles = assign.available[0].roles;
            let roleIndex = false;
            let checkPermissions = assign.data.permissions;
            let permissions = assign.available[0].permissions;
            let permissionIndex = false;
            checkedRoles.map((role) => {
                roleIndex = roles.indexOf(role);
                roles.splice(roleIndex, 1);
            });
            checkPermissions.map((permission) => {
                permissionIndex = permissions.indexOf(permission);
                permissions.splice(permissionIndex, 1)
            });
            setPermissionsAvailable(permissions);
            setRolesAvailable(roles);
        })();
    }, []);

    const handleClickPermissionAvailable = () => {
        setOpenPermissionAvailable(!openPermissionAvailable);
    };

    const handleClickRoleAvailable = () => {
        setOpenRoleAvailable(!openRoleAvailable);
    };

    const handleClickPermissionAssigned = () => {
        setOpenPermissionAssigned(!openPermissionAssigned);
    };

    const handleClickRoleAssigned = () => {
        setOpenRoleAssigned(!openRoleAssigned);
    };

    const permissionsAvailableClick = (e, index) => {
        setPermissionsAddValue(e);
        setArrowReverse(true);
    };


    const roleAvailableClick = (e, index) => {
        setArrowReverse(true);
        setRolesAddValue(e);
    };

    const permissionsAssignClick = (e, index) => {
        setPermissionsPopValue(e);
        setArrowReverse(false);
    };

    const rolesAssignClick = (e, index) => {
        setRolesPopValue(e);
        setArrowReverse(false);
    };

    const handleChange = () => {
        if (arrowReverse === false) {
            const permissions = permissionsAssign;
            const roles = rolesAssign;
            if (permissionsPopValue !== '') {
                const permissionsAdd = permissionsAvailable;
                const permissionIndex = permissions.indexOf(permissionsPopValue);
                permissions.splice(permissionIndex, 1);
                setPermissionsAssign([...permissions]);
                setRolesAssign([...roles]);
                permissionsAdd.push(permissionsPopValue);
                setPermissionsAvailable([...permissionsAdd]);
                setPermissionsPopValue('');
            }
            if (rolesPopValue !== '') {
                const rolesAdd = rolesAvailable;
                const roleIndex = roles.indexOf(rolesPopValue);
                roles.splice(roleIndex, 1);
                rolesAdd.push(rolesPopValue);
                setRolesAvailable([...rolesAdd]);
                setRolesPopValue('');
            }
        } else if (arrowReverse === true) {
            const permissions = permissionsAvailable;
            const roles = rolesAvailable;
            if (permissionsAddValue !== '') {
                const permissionsAdd = permissionsAssign;
                const permissionIndex = permissions.indexOf(permissionsAddValue);
                permissions.splice(permissionIndex, 1);
                setPermissionsAvailable([...permissions]);
                permissionsAdd.push(permissionsAddValue);
                setPermissionsAssign([...permissionsAdd]);
                setPermissionsAddValue('');
            }
            if (rolesAddValue !== '') {
                const rolesAdd = rolesAssign;
                const roleIndex = roles.indexOf(rolesAddValue);
                roles.splice(roleIndex, 1);
                setRolesAvailable([...roles]);
                rolesAdd.push(rolesAddValue);
                setRolesAssign([...rolesAdd]);
                setRolesAddValue('');
            }
        }
    };

    const editOnClicked = (e) => {
        e.preventDefault();
        setOpenForEdit(true);
    };

    const filterPermissionsAssign = permissionsAssign.filter(permission => {
        // return permission.name.toLocaleLowerCase().includes(props.valueAssign.toLocaleLowerCase());
        const _permission = Object.keys(Permission).find(key => Permission[key] === permission).toLocaleLowerCase();
        return _permission.includes(props.valueAssign?.toLocaleLowerCase());
    });

    const editUserRbac = async () => {
        const data = {
            roles: rolesAssign,
            permissions: permissionsAssign
        };
        await API.Users.editUserRBAC(props.id, data);
        setOpenForEdit(false);
    };

    const handleClose = () => {
        setOpenForEdit(false);
    };

    const filterPermissionsAvailable = permissionsAvailable.filter(permission => {
        // return permission.name.toLocaleLowerCase().includes(props.valueAvailable.toLocaleLowerCase());
        const _permission = Object.keys(Permission).find(key => Permission[key] === permission);
        let _permissionReturned = [];
        if(_permission !== undefined) {
            _permission.toLocaleLowerCase()
        }
        // return _permission.includes(props.valueAvailable?.toLocaleLowerCase());

        if(_permission !== undefined) {
            _permission.includes(props.valueAvailable?.toLocaleLowerCase());
            _permissionReturned = _permission;
        }
        return _permissionReturned
    });

    return (
        <>
            <div className={"top-button"}>
                <StyledButton onClick={e => editOnClicked(e)}
                              title={"Edit user RBAC"}
                              titleClassname={"margin-left-3px"}
                              tooltip={"Edit user RBAC"}
                              icon="fas fa-edit" type="danger"
                              variant="contained"
                              style={{
                                  padding: 5,
                                  background: '#343a40',
                                  color: '#c2c7d0',
                                  border: 'none',
                                  borderRadius: 3,
                                  cursor: 'pointer',
                              }}/>
            </div>
            <div className="rbac-container">
                <div className="bottom-content-container">
                    <div className="bottom-left-container">
                        <Box sx={{width: 450, height: 600}}>
                            <List component="nav" aria-label="roleAvailable">
                                <ListItemButton onClick={handleClickRoleAvailable}>
                                    <ListItemText primary="Roles"/>
                                    {openRoleAvailable ? <ExpandLess/> : <ExpandMore/>}
                                </ListItemButton>
                                <Collapse in={openRoleAvailable} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {rolesAvailable?.map((role, index) => (
                                            <ListItemButton
                                                onClick={() => roleAvailableClick(role, index)}
                                                sx={{pl: 4}}
                                                key={`roleAvailable` + index}
                                                selected={rolesAddValue === role}
                                            >
                                                <ListItemText primary={RenderRoleName(role)}/>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </List>
                            <Divider/>
                            <List component="div" aria-label="permissionAvailable">
                                <ListItemButton onClick={handleClickPermissionAvailable}>
                                    <ListItemText primary="Permissions"/>
                                    {openPermissionAvailable ? <ExpandLess/> : <ExpandMore/>}
                                </ListItemButton>
                                <Collapse in={openPermissionAvailable} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {
                                            props.valueAvailable === '' ?
                                                (permissionsAvailable?.map((permission, index) => (
                                                    <ListItemButton
                                                        onClick={() => permissionsAvailableClick(permission, index)}
                                                        sx={{pl: 4}}
                                                        key={`permissionAvailable` + index}
                                                        selected={permissionsAddValue === permission}
                                                    >
                                                        <ListItemText primary={RenderPermissionName(permission)}/>
                                                    </ListItemButton>
                                                ))) : (
                                                    filterPermissionsAvailable?.map((permission, index) => (
                                                        <ListItemButton
                                                            onClick={() => permissionsAvailableClick(permission, index)}
                                                            sx={{pl: 4}}
                                                            key={`permissionAvailable` + index}
                                                            selected={permissionsAddValue === permission}
                                                        >
                                                            <ListItemText primary={RenderPermissionName(permission)}/>
                                                        </ListItemButton>
                                                    )))
                                        }
                                    </List>
                                </Collapse>
                            </List>
                        </Box>
                    </div>
                </div>
                <div className="middle-bottom-container">
                    <div className={arrowReverse ? 'deletePermission' : 'deletePermissionReverse'}
                         style={{width: 28, height: 28, backgroundColor: 'rgb(52, 58, 64)', cursor: 'pointer'}}
                         onClick={() => handleChange()}
                    >
                        <i className="fas fa-arrow-right"/>
                    </div>
                </div>
                <div className="bottom-right-container">
                    <Box sx={{width: 450, height: 600}}>
                        <List component="nav" aria-label="roleAvailable">
                            <ListItemButton onClick={handleClickRoleAssigned}>
                                <ListItemText primary="Roles"/>
                                {openRoleAssigned ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={openRoleAssigned} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {rolesAssign.map((role, index) => (
                                        <ListItemButton
                                            onClick={() => rolesAssignClick(role, index)}
                                            sx={{pl: 4}}
                                            key={`roleAssign` + index}
                                            selected={rolesPopValue === role}
                                        >
                                            <ListItemText primary={RenderRoleName(role)}/>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Collapse>
                        </List>
                        <Divider/>
                        <List component="div" aria-label="permissionAvailable">
                            <ListItemButton onClick={handleClickPermissionAssigned}>
                                <ListItemText primary="Permissions"/>
                                {openPermissionAssigned ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={openPermissionAssigned} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        props.valueAssign === '' ?
                                            (permissionsAssign.map((permission, index) => (
                                                <ListItemButton
                                                    onClick={() => permissionsAssignClick(permission, index)}
                                                    sx={{pl: 4}}
                                                    key={`permissionAssign` + index}
                                                    selected={permissionsPopValue === permission}
                                                >
                                                    <ListItemText
                                                        primary={Object.keys(Permission).find(key => Permission[key] === permission)}/>
                                                </ListItemButton>
                                            ))) :
                                            (filterPermissionsAssign.map((permission, index) => (
                                                <ListItemButton
                                                    onClick={() => permissionsAssignClick(permission, index)}
                                                    sx={{pl: 4}}
                                                    key={`permissionAssign` + index}
                                                    selected={permissionsPopValue === permission}
                                                >
                                                    <ListItemText
                                                        primary={Object.keys(Permission).find(key => Permission[key] === permission)}/>
                                                </ListItemButton>
                                            )))
                                    }
                                </List>
                            </Collapse>
                        </List>
                    </Box>
                </div>
            </div>
            <div>
                <div className={"bottom-button"}>
                    <StyledButton onClick={e => editOnClicked(e)}
                                  title={"Edit user RBAC"}
                                  titleClassname={"margin-left-3px"}
                                  tooltip={"Edit user RBAC"}
                                  icon="fas fa-edit" type="danger"
                                  variant="contained"
                                  style={{
                                      padding: 5,
                                      background: '#343a40',
                                      color: '#c2c7d0',
                                      border: 'none',
                                      borderRadius: 3,
                                      cursor: 'pointer',
                                  }}/>
                </div>
            </div>
            <Dialog open={openForEdit} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want save changes in roles and permissions?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={editUserRbac} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForEdit(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>

    );
};

export default RbacView;