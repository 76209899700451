import {customFetchDeployApi} from "../requests/requests";

export class BuildServersAPI {

    static async getBuildServers(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/build-servers?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async buildServerDelete(id) {
        return await customFetchDeployApi({path: "/api/v2/build-servers/" + id, method: 'DELETE'})
            .then(response => {
                return response
            })
    }

    static async addBuildServer(body) {
        return await customFetchDeployApi({path: "/api/v2/build-servers/", body, method: 'PUT'})
            .then(response => {
                return response
            })
    }
}