import React from 'react';
import {useParams} from "react-router";
import {KeyInfo} from "./components/key/KeyInfo";
import {KeyHostsInfo} from "./components/key/KeyHostsInfo";
import {KeyDomainsInfo} from "./components/key/KeyDomainsInfo";
import {Link, useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

export const CurrentKeyInfo = () => {
    const {key} = useParams();
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);

    const LinkRouter = (props) => {
        return (
            <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                <Link to="/" className="black routes">
                    <span>Dashboard</span>
                </Link>
                <Link to="/keys" className="black routes">
                    <span>Keys table</span>
                </Link>
                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                    if (last) {
                        return (
                            <Typography color="textPrimary" key={to} className="lastItem">
                                {'Key : ' + value}
                            </Typography>
                        )
                    }
                })}
            </Breadcrumbs>
        );
    };

    return (
        <div className="snapShotsTable">
            <LinkRouter/>
            <div style={{float: 'none', height: '22px', marginBottom: '10px'}}/>
                <KeyInfo keys={key}/>
            <div className="snapshotInfo">
                <h3 className="tableTitle hosts marginZero">
                    <span>Hosts</span>
                </h3>
                <KeyHostsInfo keys={key}/>
            </div>
            <div className="snapshotInfo">
                <h3 className="tableTitle domains marginZero">
                    <span>Domains</span>
                </h3>
                <KeyDomainsInfo keys={key}/>
            </div>
        </div>
    );
};
