import {customFetchDeployApi} from "../requests/requests";

export class AuthAPI {
    static async login(body, onError) {
        return await customFetchDeployApi({path: "/api/auth/token", body, noSession: true})
            .then(response => ({response: response, status: response.status}))
            .catch(onError);
    }

    static logout(onSuccess, onError) {
        customFetchDeployApi({path: "/api/auth/logout"})
            .then(response => {
                if (!response.error.status) onSuccess(response);
            })
            .catch(onError);
    }
}