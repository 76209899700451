import {customFetchDeployApi} from "../requests/requests";

export class ConfigsAPI {

    static async getConfigs(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/configs?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getConfigInfo(id) {
        return await customFetchDeployApi({path: "/api/v2/config/" + id, method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async getConfigSnapshotsInfo(id, page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/config/" + id + "/snapshots?Page=" + (page === undefined ? 1 : page) + '&PageSize=' + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getConfigsInit() {
        return await customFetchDeployApi({path: "/api/v2/configs/init", method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async ConfigDataUpdate(id, body) {
        let data = new FormData();
        data.append('configName', body.configName);
        data.append('configData', body.configData);

        return await customFetchDeployApi({
            path: "/api/v2/config/" + id,
            body: data,
            isForm: true
        })
            .then(response => {
                return response
            })
    }

    static async configDelete(id) {
        return await customFetchDeployApi({path: "/api/v2/config/" + id, method: 'DELETE'})
            .then(response => {
                return response
            })
    }


    static async addConfig(body) {
        let data = new FormData();
        data.append('configName', body.configName);
        data.append('configData', body.configData);

        return await customFetchDeployApi({
            path: "/api/v2/configs/",
            body: data,
            method: 'PUT',
            isForm: true
        })
            .then(response => {
                return response
            })
    }
}