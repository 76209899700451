import React from 'react';
import {useParams} from "react-router";
import {Link, useLocation} from "react-router-dom";
import {SnapshotHistoryInfo} from "./components/snapshot/SnapshotHistoryInfo";
import {SnapshotDomainsInfo} from "./components/snapshot/SnapshotDomainsInfo";
import {SnapshotJobsInfo} from "./components/snapshot/SnapshotJobsInfo";
import {SnapshotFileNameInfo} from "./components/snapshot/SnapshotFileNameInfo";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

export const CurrentSnapshotInfo = () => {
    const {fileName} = useParams();
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);

    const LinkRouter = (props) => {
        return (
                <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                    <Link to="/" className="black routes">
                        <span>Dashboard</span>
                    </Link>
                    <Link to="/snapshots" className="black routes">
                        <span>Snapshots table</span>
                    </Link>
                    {pathNames.map((value, index) => {
                        const last = index === pathNames.length - 1;
                        const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                        if (last) {
                            return (
                                <Typography color="textPrimary" key={to} className="lastItem">
                                    {'Snapshot : ' + value}
                                </Typography>
                            )
                        }
                    })}
                </Breadcrumbs>
        );
    };

    return (
        <div className="snapShotsTable">
            <LinkRouter/>
            <div style={{float: 'none', height: '22px', marginBottom: '10px'}}/>
            <h3 className="tableTitle fileNameInfo marginZero">
                <Link to={"/snapshots"} style={{textDecoration: "underline", color: "black"}}>
                    <span>Snapshot</span>
                </Link>
                <span>: {fileName}</span>
            </h3>
            <div className="snapshotFilenameInfo">
                <SnapshotFileNameInfo fileName={fileName}/>
            </div>
            <div className="snapshotInfo">
                <h3 className="tableTitle jobs marginZero">
                    <span>Jobs</span>
                </h3>
                <SnapshotJobsInfo fileName={fileName}/>
            </div>
            <div className="snapshotInfo">
                <h3 className="tableTitle history marginZero">
                    <span>History</span>
                </h3>
                <SnapshotHistoryInfo fileName={fileName}/>
            </div>
            <div className="snapshotInfo">
                <h3 className="tableTitle domains marginZero">
                    <span>Domains</span>
                </h3>
                <SnapshotDomainsInfo fileName={fileName}/>
            </div>
        </div>
    );
};

