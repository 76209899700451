import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import '../all.min.css';
import '../styles/Styles.css';

const SLink = props => {
    const {nested = false, link, icon, title} = props;
    const [opened, setOpened] = useState(false);

    const onClickEvent = e => {
        const parent = e.currentTarget;
        const ul = e.currentTarget.children[1];
        if (opened) setOpened(false);
        else {
            parent.classList.add("menu-is-opening");
            $(ul).slideDown(300, () => {
                $(ul).first().addClass("menu-open");
                setOpened(true);
            });
        }
    };
    const selectFirst = e => {
        e.preventDefault();
        const target = e.target;
        if (target.id !== "open-menu-list") {
        } else {
            e.currentTarget = e.target.parentNode.parentNode.parentNode;
            onClickEvent(e);
        }
    };

    return (
        <li className="nav-item"
            onClick={nested ? () => {
            } : onClickEvent}>
            <Link to={nested ? "#" : link} style={{fontSize: "1rem"}}
                  className={`nav-link`}
                  onClick={nested ? selectFirst : () => false}>
                <i className={`nav-icon nav-panel ${icon}`} style={{fontSize: "1.1rem"}}/>
                <div className="title">
                    <p>
                        {title}
                        {nested ? <i className="right fas fa-angle-left" id="open-menu-list"/> : null}
                    </p>
                </div>
            </Link>
            <ul className="nav nav-treeview"
                style={{display: "none"}}>
                {nested ? React.Children.map(props.children, child => child) : null}
            </ul>
        </li>
    );
};

const SideBarLink = (props) => {
    return (
        <div>
            <SLink link={props.link} title={props.title} icon={props.icon}/>
        </div>
    );
};

export default SideBarLink;