import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {MainProvider} from "../src/stores/MainStore";
import {UserProvider} from "../src/stores/UsersStore";
import Auth from "./Auth/Auth";
import Snapshots from "./tables/component/alltables/Snapshots";
import {PrivateRoute} from "./routes/Routes";
import {SnapShotsProvider} from "./stores/TableStore";
import {ThemeProvider} from "@material-ui/styles";
import {THEME} from "./theme";
import {CurrentSnapshotInfo} from "./tables/component/currentTablesView/CurrentSnapshotInfo";
import BuildServersTable from "./tables/component/alltables/BuildServersTable";
import ConfigsTable from "./tables/component/alltables/ConfigsTable";
import UsersTable from "./tables/component/alltables/UsersTable";
import KeysTable from "./tables/component/alltables/KeysTable";
import JobsTable from "./tables/component/alltables/JobsTable";
import HostsTable from "./tables/component/alltables/HostsTable";
import ReportsTable from "./tables/component/alltables/ReportsTable";
import BuildRequestsTable from "./tables/component/alltables/BuildRequestsTable";
import {CurrentBuildRequestInfo} from "./tables/component/currentTablesView/CurrentBuildRequestInfo";
import {CurrentReportInfo} from "./tables/component/currentTablesView/CurrentReportInfo";
import {CurrentHostInfo} from "./tables/component/currentTablesView/CurrentHostInfo";
import {CurrentKeyInfo} from "./tables/component/currentTablesView/CurrentKeyInfo";
import {CurrentConfigInfo} from "./tables/component/currentTablesView/CurrentConfigInfo";
import {SnapshotsDomainsProvider} from "./stores/SnapshotDomainsStore";
import {SnapshotsJobsProvider} from "./stores/SnapshotJobsStore";
import {ConfigProvider} from "./stores/ConfigStore";
import Profile from "./profile/Profile";
import {Dashboard} from "./dashboard/Dashboard";
import {IncrementalBuild} from "./dashboard/components/IncrementalBuild";
import {DashboardProvider} from "./stores/DashboardStore";
import {DefaultBuild} from "./dashboard/components/DefaultBuild";
import {ConfigDomainsBuild} from "./dashboard/components/ConfigDomainsBuild";
import {CustomConfigBuild} from "./dashboard/components/CustomConfigBuild";
import {CustomBuildProvider} from "./stores/CustomBuildStore";
import {CurrentDomainInfo} from "./tables/component/currentTablesView/components/domain/CurrentDomainInfo";
import InfoPanel from "./infoPanel/infoPanel/InfoPanel";
import {Sidebar} from "./sideBar/SideBar";
import UsersRbac from "./users/UsersRbac";

ReactDOM.render(
    <MainProvider>
        <UserProvider>
            <SnapShotsProvider>
                <SnapshotsDomainsProvider>
                    <SnapshotsJobsProvider>
                        <ConfigProvider>
                            <DashboardProvider>
                                <CustomBuildProvider>
                                    <ThemeProvider theme={THEME}>
                                        <InfoPanel/>
                                        <BrowserRouter>
                                            <Sidebar/>
                                            <Switch>
                                                <Route exact path="/auth" component={Auth}/>
                                                <PrivateRoute exact path={["/"]} component={Dashboard}/>
                                                <PrivateRoute exact path={["/dashboard"]} component={Dashboard}/>
                                                <PrivateRoute exact path={"/dashboard/incremental-build"}
                                                              component={IncrementalBuild}/>
                                                <PrivateRoute exact path={"/dashboard/default-build"}
                                                              component={DefaultBuild}/>
                                                <PrivateRoute exact path={"/dashboard/config-domains-build"}
                                                              component={ConfigDomainsBuild}/>
                                                <PrivateRoute exact path={"/dashboard/custom-config-build"}
                                                              component={CustomConfigBuild}/>
                                                <PrivateRoute exact path="/profile/:email" component={Profile}/>
                                                <PrivateRoute exact path="/snapshots" component={Snapshots}/>
                                                <PrivateRoute exact path="/snapshot/:fileName"
                                                              component={CurrentSnapshotInfo}/>
                                                <PrivateRoute exact path="/build-requests"
                                                              component={BuildRequestsTable}/>
                                                <PrivateRoute exact path="/build-request/:id"
                                                              component={CurrentBuildRequestInfo}/>
                                                <PrivateRoute exact path="/reports" component={ReportsTable}/>
                                                <PrivateRoute exact path="/report/:id" component={CurrentReportInfo}/>
                                                <PrivateRoute exact path="/hosts" component={HostsTable}/>
                                                <PrivateRoute exact path="/host/:id" component={CurrentHostInfo}/>
                                                <PrivateRoute exact path="/host/:hostId/domain/:domainId"
                                                              component={CurrentDomainInfo}/>
                                                <PrivateRoute exact path="/jobs" component={JobsTable}/>
                                                <PrivateRoute exact path="/keys" component={KeysTable}/>
                                                <PrivateRoute exact path="/key/:key" component={CurrentKeyInfo}/>
                                                <PrivateRoute exact path="/users" component={UsersTable}/>
                                                <PrivateRoute exact path="/configs" component={ConfigsTable}/>
                                                <PrivateRoute exact path="/config/:id" component={CurrentConfigInfo}/>
                                                <PrivateRoute exact path="/build-servers"
                                                              component={BuildServersTable}/>
                                                <PrivateRoute exact path="/users/user/:userId"
                                                              component={UsersRbac}/>
                                            </Switch>
                                        </BrowserRouter>
                                    </ThemeProvider>
                                </CustomBuildProvider>
                            </DashboardProvider>
                        </ConfigProvider>
                    </SnapshotsJobsProvider>
                </SnapshotsDomainsProvider>
            </SnapShotsProvider>
        </UserProvider>
    </MainProvider>,
    document.getElementById('root')
);
