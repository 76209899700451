import React, {useEffect} from 'react';
import {SnackbarProvider, enqueueSnackbar} from "notistack";

const InfoPanel = () => {

    useEffect(() => {
        const onMessage = (e) => {
            enqueueSnackbar(e.detail.message, { variant: e.detail.type}, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }}, {autoHideDuration: 3000});
        };
        document.addEventListener('responseMessage', onMessage);
        return () => {
            window.removeEventListener('responseMessage', onMessage);
        }
    }, []);

    return (
        <SnackbarProvider anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }} autoHideDuration={3000}/>
    );
};

export default InfoPanel;