import React, {useCallback, useState} from 'react';
import {styled, alpha} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import RbacView from "./rbacComponent/RbacView";
import RbacInput from "./rbacComponent/RbacInput";

const CustomSearch = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    border: '1px solid rgba(0,0,0,.125)',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(194, 199, 208)'
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const RBAC = (props) => {

    const [searchInputValueAvailable, setSearchInputValueAvailable] = useState('');
    const [searchInputValueAssign, setSearchInputValueAssign] = useState('');

    const handleChangeAvailable = useCallback((event) => {
        setSearchInputValueAvailable(event.target.value)
    }, []);

    const handleChangeAssign = useCallback((event) => {
        setSearchInputValueAssign(event.target.value)
    }, []);

    return (
        <div className="wrapper-background">
            <div className="rbac-tittle">
                <h3>User RBAC</h3>
            </div>
            <div className="contentContainer">
                <div className="left-side">
                    <div className="top-left-container">
                        <CustomSearch>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>
                            <RbacInput value={searchInputValueAvailable}
                                       onChange={handleChangeAvailable}/>
                        </CustomSearch>
                        <div className="titles">
                            <div className="availableTittle">
                                <h4>Available</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <div className="top-right-container">
                        <CustomSearch>
                            <SearchIconWrapper>
                                <SearchIcon/>
                            </SearchIconWrapper>
                            <RbacInput
                                value={searchInputValueAssign}
                                onChange={handleChangeAssign}
                            />
                        </CustomSearch>
                    </div>
                    <div className="assignedTittle titles">
                        <h4>Assigned</h4>
                    </div>
                </div>
            </div>
            <RbacView valueAvailable={searchInputValueAvailable} valueAssign={searchInputValueAssign}
                      id={props.id}/>
        </div>
    );
};

export default RBAC;