import Cookies from 'js-cookie';
import Utils from "../Auth/Utils";
import {API} from "../api/API";

const defaultHeaders = {
    'X-Network': Cookies.get('networkId') || "roomful",
};

export class CustomResponse {

    result = null;
    value = null;
    status = 400;
    ok = true;

    constructor(value, status, ok) {
        this.value = value;
        this.status = status;
        this.ok = ok;
    }

    observe() {
        if (this.result) return this.result;

        try {
            this.result = JSON.parse(this.value);
        } catch (e) {
            this.result = {error: {message: this.value}};
        }

        return this.result;
    }

    json() {
        return this.observe();
    }

    text() {
        return this.observe();
    }
}

export const customFetchOld = async settings => {
    const {path, method = "POST", body = {}, headers: userHeaders, params, network, withoutNetwork, noSession} = settings;

    const networkHeaders = network ? {'X-Network': network} : defaultHeaders;
    const contentTypeHeaders = userHeaders && userHeaders["Content-Type"] ? {} : {'Content-Type': 'application/json'};

    return await fetch(Utils.buildURLs(path, withoutNetwork, noSession, params), {
        method,
        headers: {
            ...(Cookies.get('DerpyPonyToken') ? {'X-Session-Id': Cookies.get('DerpyPonyToken')} : {}),
            ...networkHeaders,
            ...userHeaders,
            ...contentTypeHeaders
        },
        body: method === "POST" ? JSON.stringify(body) : null
    });
};

export const customFetchDeployApi = async settings => {
    const {path, method = "POST", isForm = false, body = {}, headers: userHeaders, params, responseType} = settings;
    const contentTypeHeaders = userHeaders && userHeaders["Content-Type"] || isForm ? {} : {'Content-Type': 'application/json'};
    return await fetch(Utils.buildURLs(path, true, true, params), {
        method,
        credentials: 'include',
        headers: {
            ...userHeaders,
            ...contentTypeHeaders,
        },
        body: method === "POST" || method === "PUT" || method === "DELETE" ? (!isForm ? JSON.stringify(body) : body) : null
    }).then((response) => {
        switch (responseType) {
            case 'blob' : return response.blob();
            case 'text' : return response.text();
            default : return response.json();
        }
    }).then((result) => {
            const warningMessage = new CustomEvent('responseMessage', {
                detail: {
                    message: result.error.message,
                    type: 'warning'
                },
                bubbles: true
            });
            const errorMessage = new CustomEvent('responseMessage', {
                detail: {
                    message: result.error.message,
                    type: 'error'
                },
                bubbles: true
            });
            if (result.error.code >= 400 && result.error.code < 500 && !window.location.pathname.startsWith('/auth')) {
                document.dispatchEvent(errorMessage);
            }
            if (result.error.code >= 500 && result.error.code < 600 && !window.location.pathname.startsWith('/auth')) {
                document.dispatchEvent(errorMessage);
            }
            if (result.error.code === 401) {
                window.location.href = "/auth";
            }
            return result;
        });
};

export const customFetch = async settings => {
    const {path, method = "POST", body = {}, headers: userHeaders, params, network, withoutNetwork, noSession} = settings;

    const networkHeaders = network ? {'X-Network': network} : defaultHeaders;
    const contentTypeHeaders = userHeaders && userHeaders["Content-Type"] ? {} : {'Content-Type': 'application/json'};

    const result = await fetch(Utils.buildURLs(path, withoutNetwork, noSession, params), {
        method,
        headers: {
            ...(Cookies.get('sessionId') ? {'X-Session-Id': Cookies.get('sessionId')} : {}),
            ...networkHeaders,
            ...userHeaders,
            ...contentTypeHeaders
        },
        body: method === "POST" ? JSON.stringify(body) : null
    });

    return new CustomResponse(await result.text(), result.status, result.ok);
};

export const parseSpreadsheetRequest = async settings => {
    const {path, body = {}} = settings;

    const formData = new FormData();
    formData.append("file", body);

    return fetch(Utils.buildURLs(path), {
        method: "POST",
        headers: {
            ...defaultHeaders,
        },
        body: formData
    });
};

export const createResource = async (file, networkId = "roomful", onError, customBodyParams = {}) => {
    const resourceMetadata = {
        resource: {
            metadata: {
                fileName: file.name,
                fileSize: file.size,
                contentType: file.type
            }
        },
        ...customBodyParams
    };

    const resource = (await API.Resource.createResourceMetadata(resourceMetadata, networkId, onError)).data.resource;
    const url = await API.Resource.getLink(resource.id, networkId, onError);

    const directUploadToGoogleBucket = async file => {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.onload = function () {
                const arrayBuffer = this.result;

                resolve(arrayBuffer);
            };
            reader.onerror = onError;

            reader.readAsArrayBuffer(file);
        })
    };

    const arrayBuffer = await directUploadToGoogleBucket(file);

    await API.Resource.directUploadToGoogleBucket(url, arrayBuffer, file.type, onError);

    await API.Resource.completeUpload(resource.id, networkId, onError);

    return resource.id;
};