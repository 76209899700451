import React from "react";
import './styles/All.css';
import './styles/Styles.css';

const Navbar = props => {

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <div className="nav-link" data-widget="pushmenu" role="button">
                        <i className="fas fa-bars"/>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;