import React, {useContext, useEffect, useState} from "react";
import Logo from "../RoomfulLogo.svg";
import '../all.min.css';
import '../styles/All.css';
import Image from 'material-ui-image';
import {API} from "../api/API";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import {Link, useLocation} from 'react-router-dom'
import SideBarLink from "./SideBarLink";
import {StyledButton} from "../customstyled/StyledButton";
import {UsersStore} from "../stores/UsersStore";
import Utils from "../Auth/Utils";
import NoUserAvatar from "../no-user-avatar.png";
import userEvent from "@testing-library/user-event/dist";
import {Permission} from "../rbac/Permissions";

export const Sidebar = () => {
    const {state: userState, dispatch} = useContext(UsersStore);
    const [logoutModalShow, setLogoutModalShow] = useState(false);
    const [logoutBtnDisabled] = useState(false);
    const location = useLocation();
    const [display, setDisplay] = useState(!window.location.pathname.startsWith('/auth'));

    useEffect(() => {
        setDisplay(!window.location.pathname.startsWith('/auth'));
    }, [location]);

    useEffect(() => {
        if (display) {
            (async () => {
                const mySelf = await API.Users.getUserInfo();
                dispatch({
                    type: "SET_USER",
                    payload: {
                        email: mySelf.data.email,
                        permissions: mySelf.data.permissions,
                        permissionsAssigned: mySelf.data.permissionsAssigned,
                        roles: mySelf.data.roles
                    }
                });
            })()
        }
    }, [display]);

    if (!display) return null;

    const onError = error => {
        console.log(error.data);
    };

    const onLogoutBtnClicked = e => {
        e.preventDefault();
        setLogoutModalShow(true);
    };

    const logout = () => {
        API.Auth.logout(() => {
            window.location.href = "/auth";
        }, onError);
    };

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4 overflow-x-hidden">
            <Link to="/dashboard" className="brand-link d-flex align-items-center" id="logo-text">
                <img src={Logo} alt=" Logo" className="brand-image img-circle" style={{opacity: "0.8"}}/>
                <span className="brand-text font-weight-light"
                      style={{fontSize: "0.95em !important"}}>Roomful Deploy</span>
            </Link>
            <OverlayScrollbarsComponent style={{maxHeight: '100%'}} options={{
                className: "os-theme-light",
                resize: 'none',
                paddingAbsolute: true,
                scrollbars: {
                    autoHide: 'move'
                }
            }}>
                <div className="sidebar">
                    <div className={`user-panel mt-3 mb-3 d-flex flex-column justify-content-center`}>
                        <div className="d-flex">
                            <div className="image mr-3">
                                {
                                    <Image imageStyle={{
                                        position: "initial",
                                        width: "2.125rem", height: "2.125rem",
                                        objectFit: "cover"
                                    }}
                                           style={{
                                               display: "flex",
                                               justifyContent: "center",
                                               background: "transparent",
                                               padding: 0
                                           }}
                                           className="img-circle elevation-2"
                                           src={NoUserAvatar}
                                    />
                                }
                            </div>
                            <div className="info d-flex row justify-content-between mr-3"
                                 style={{width: "100%", whiteSpace: 'nowrap', overflow: 'hidden', maxHeight: '34px'}}>
                                <Link to={`/profile/` + userState.email} className="d-block">
                                    {Utils.limitStringLength(
                                        userState && userState.model && (userState.model.firstName || userState.model.lastName)
                                            ? `${userState.model.firstName} ${userState.model.lastName}`
                                            : userState.email
                                        , 10)}
                                </Link>
                                <span onClick={logoutBtnDisabled ? () => false : onLogoutBtnClicked}
                                      className="d-flex align-items-center logoutButton">
                                    <i className="fas fa-sign-out-alt logoutIcon" style={{fontSize: "1.25em"}}/>
                                </span>
                            </div>
                        </div>
                    </div>
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">
                            <SideBarLink title="Dashboard" link="/" icon="fas fa-chart-line"/>
                            {
                                userState.permissions?.includes(Permission.ViewBuildRequests) || userState.permissions?.includes(Permission.ViewOwnBuildRequests) ||
                                userState.permissionsAssigned?.includes(Permission.ViewBuildRequests) || userState.permissionsAssigned?.includes(Permission.ViewOwnBuildRequests)
                                    ? <SideBarLink title="Build Requests" link="/build-requests" icon="fas fa-hammer"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewReports) ||
                                userState.permissionsAssigned?.includes(Permission.ViewReports)
                                    ? <SideBarLink title="Reports" link="/reports" icon="fas fa-list"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewSnapshots) || userState.permissions?.includes(Permission.ViewOwnSnapshots)
                                || userState.permissionsAssigned?.includes(Permission.ViewSnapshots) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshots)
                                    ? <SideBarLink title="Snapshots" link="/snapshots" icon="fas fa-database"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewHosts) || userState.permissions?.includes(Permission.ViewOwnHosts) ||
                                userState.permissionsAssigned?.includes(Permission.ViewHosts) || userState.permissionsAssigned?.includes(Permission.ViewOwnHosts)
                                    ? <SideBarLink title="Hosts" link="/hosts" icon="fas fa-database"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewJobs) ||
                                userState.permissionsAssigned?.includes(Permission.ViewJobs)
                                    ? <SideBarLink title="Jobs" link="/jobs" icon="fas fa-wrench"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewDeployKeys) || userState.permissions?.includes(Permission.ViewOwnDeployKeys) ||
                                userState.permissionsAssigned?.includes(Permission.ViewDeployKeys) || userState.permissionsAssigned?.includes(Permission.ViewOwnDeployKeys)
                                    ? <SideBarLink title="Keys" link="/keys" icon="fas fa-key"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewUsers) || userState.permissions?.includes(Permission.ViewUserOwner) ||
                                userState.permissionsAssigned?.includes(Permission.ViewUsers) || userState.permissionsAssigned?.includes(Permission.ViewUserOwner)
                                    ? <SideBarLink title="Users" link="/users" icon="fas fa-users"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewConfigs) ||
                                userState.permissionsAssigned?.includes(Permission.ViewConfigs)
                                    ? <SideBarLink title="Configs" link="/configs" icon="fas fa-tools"/>
                                    : null
                            }
                            {
                                userState.permissions?.includes(Permission.ViewBuildServers) ||
                                userState.permissionsAssigned?.includes(Permission.ViewBuildServers)
                                    ? <SideBarLink title="Build Servers" link="/build-servers" icon="fas fa-database"/>
                                    : null
                            }
                        </ul>
                    </nav>
                </div>
            </OverlayScrollbarsComponent>

            <Dialog open={logoutModalShow} onClose={() => setLogoutModalShow(false)}>
                <DialogTitle>
                    Are you sure to logout from roomful deploy panel?
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        By pressing "Logout" you will logout immediately!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={() => setLogoutModalShow(false)} title="Cancel" type="danger"
                                  variant="contained"
                                  style={{
                                      background: '#343a40',
                                      borderColor: '#343a40',
                                      color: '#c2c7d0',
                                  }}/>
                    <StyledButton onClick={logoutBtnDisabled ? () => false : logout} autoFocus
                                  disabled={logoutBtnDisabled} title="Logout"
                                  type="danger" variant="contained"
                                  style={{
                                      // color: '#c2c7d0',
                                  }}/>
                </DialogActions>
            </Dialog>
        </aside>
    );
};