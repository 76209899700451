import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../../../stores/MainStore";
import {API} from "../../../../../api/API";
import Utils from "../../../../../Auth/Utils";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {TableStore} from "../../../../../stores/TableStore";
import {Link} from "react-router-dom";
import CustomTable from "../../../../customTables/CustomTable";
import {StyledAsyncSelect} from "../../../../../customstyled/AsyncSelect/StyledAsyncSelect";
import {UsersStore} from "../../../../../stores/UsersStore";
import {Permission} from "../../../../../rbac/Permissions";

export const DomainSnapshotInfo = (props) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [snapshotFileName, setSnapshotFileName] = useState('');
    const [openForUnmount, setOpenForUnmount] = useState(false);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [openForReBuild, setOpenForReBuild] = useState(false);

    const status = [
        'Deployed',
        'ReDeployed',
        'Removed'
    ];

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };
    const reBuildOnClicked = () => {
        setOpenForReBuild(true);
    };

    const mountOnClicked = (e, fileName) => {
        e.preventDefault();
        setOpen(true);
        setSnapshotFileName(fileName)
    };

    const unmountOnClicked = (e, fileName) => {
        e.preventDefault();
        setSnapshotFileName(fileName);
        setOpenForUnmount(true);
    };

    const deleteOnClicked = (e, fileName) => {
        e.preventDefault();
        setSnapshotFileName(fileName);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenForUnmount(false);
        setOpenForDelete(false);
    };

    const mount = async () => {
        await API.SnapShots.snapshotMount(snapshotFileName, {value});
        setOpen(false);
        setValue('');
    };

    const unmount = async () => {
        await API.SnapShots.snapshotUnmount(snapshotFileName);
        setOpenForUnmount(false);
    };

    const deleteSnapshot = async () => {
        await API.SnapShots.snapshotDelete(snapshotFileName);
        setOpenForDelete(false);
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setValue(domainNames);
        } else if (selected && selected.length === 0) {
            setValue(domainNames);
        }
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 23}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 23}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(8)
        },
        {
            name: 'fileName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Filename</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    {
                        userState.permissions?.includes(Permission.ViewSnapshots) || userState.permissions?.includes(Permission.ViewOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.ViewSnapshots) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshots)
                            ? <Link to={'/snapshot/' + rowData.fileName}>
                                <div className="fileName" style={{textAlign: 'left!important'}}>
                                    <span style={{textDecoration: 'underline'}}>{rowData.fileName}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{textAlign: 'left!important'}}>
                                <span>{rowData.fileName}</span>
                            </div>
                    }
                </div>
            ),
            minWidth: Utils.RemToPx(25)
        },
        {
            name: 'status',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Status</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{status[rowData.status]}</span>
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'created',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Created At</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{new Date(rowData.created).toLocaleString()}</span>
                </div>
            ),
            width: Utils.RemToPx(12)
        },
        {
            name: 'updated',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Updated</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{new Date(rowData.updated).toLocaleString()}</span>
                </div>
            ),
            width: Utils.RemToPx(12)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex align-items-center justify-content-center">
                    {
                        userState.permissions?.includes(Permission.RebuildSnapshots) || userState.permissions?.includes(Permission.RebuildOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.RebuildSnapshots) || userState.permissionsAssigned?.includes(Permission.RebuildOwnSnapshots)
                            ? <StyledButton onClick={e => reBuildOnClicked(e, rowData.fileName)} icon="fas fa-hammer"
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.MountSnapshots) || userState.permissions?.includes(Permission.MountOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.MountSnapshots) || userState.permissionsAssigned?.includes(Permission.MountOwnSnapshots)
                            ? <StyledButton onClick={e => mountOnClicked(e, rowData.fileName)}
                                            icon="fas fa-arrow-alt-circle-down"
                                            tooltip={"Mount snapshot to domains"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 4
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.UnmountSnapshots) || userState.permissions?.includes(Permission.UnmountOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.UnmountSnapshots) || userState.permissionsAssigned?.includes(Permission.UnmountOwnSnapshots)
                            ? <StyledButton onClick={e => unmountOnClicked(e, rowData.fileName)} icon="fas fa-eraser"
                                            tooltip={"Unmount all domains with this snapshot"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 4
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.RebuildSnapshots) || userState.permissions?.includes(Permission.RebuildOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.RebuildSnapshots) || userState.permissionsAssigned?.includes(Permission.RebuildOwnSnapshots)
                            ? <StyledButton onClick={e => deleteOnClicked(e, rowData.fileName)}
                                            icon="fas fa-solid fa-trash-can"
                                            tooltip={"Delete snapshot"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 4
                                            }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(20)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Configs.getConfigSnapshotsInfo(props.configId, page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <section className="content">
                <div id="table-container">
                    <CustomTable state={{
                        typeOfRender: state.renderType,
                        canSelect: false,
                        canHover: false,
                        canSearch: false,
                        pagination: true
                    }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                 texts={{noItems: "No snapshots..."}}/>
                </div>
            </section>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Mount snapshot to domains
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0 border-bottom-none align-items-center">
                        <div className="col-1"><label className="col-form-label">Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={handleChange}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={mount} title="Mount to this domains" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForUnmount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={unmount} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForUnmount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot & then delete it?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteSnapshot} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForReBuild} onClose={handleClose}>
                <DialogTitle>
                    This functional in development...
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={() => setOpenForReBuild(false)} autoFocus
                                  disabled={false} title="OK"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};
