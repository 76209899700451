import React, {useContext, useState} from 'react';
import {useStyles} from "./styles/style";
import isEmail from 'validator/lib/isEmail';
import {API} from "../api/API";
import {Store} from "../stores/MainStore";
import {useHistory, withRouter} from "react-router-dom";

// Components
import {StyledButton} from "../customstyled/StyledButton";

// MUI
import {Avatar, TextField, Link, Box, Typography, Container} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {UsersStore} from "../stores/UsersStore";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function SignIn({history}) {
    const {dispatch: mainDispatch} = useContext(Store);
    const {dispatch: userDispatch} = useContext(UsersStore);
    const classes = useStyles();
    let navigation = useHistory();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [openWarning, setOpenWarning] = useState(false);
    const [openError, setOpenError] = useState(false);

    const onEnterKeyDown = e => e.key === "Enter" && onSubmit();

    const onError = (error, title, text) => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: title ?? "Login failed!",
                text: text ?? "Invalid email or password",
                type: "error"
            }
        });
        setIsButtonDisabled(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenWarning(false);
        setOpenError(false);
    };

    const onSubmit = async () => {
        if (isEmail(email) && password.length >= 3) {
            setIsButtonDisabled(true);
            try {
                const data = await API.Auth.login({email, password}, onError);
                if (data?.response.error.code === 200) {
                    userDispatch({type: 'SET_EMAIL', payload: email});
                    navigation.push('/');
                } else if (data?.response.error.code === 401) {
                    setOpenError(true);
                    setIsButtonDisabled(false);
                } else if (data?.response.error.code === 400) {
                    setOpenWarning(true);
                    setIsButtonDisabled(false);
                }
            } catch (e) {
                onError(e, "Error!", e.message)
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.container}>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}><LockOutlinedIcon/></Avatar>
                    <Typography component="h1" variant="h5">Sign in</Typography>
                    <form className={classes.form} noValidate>
                        <TextField variant="outlined" margin="normal" required fullWidth id="email"
                                   label="Email Address" onKeyDown={onEnterKeyDown} value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   name="email" autoComplete="email" autoFocus/>
                        <TextField variant="outlined" margin="normal" required fullWidth name="password"
                                   label="Password" onKeyDown={onEnterKeyDown} value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   type="password" id="password" autoComplete="current-password"/>
                        <Box mt={2}>
                            <StyledButton fullWidth variant="contained" type="primary"
                                          onClick={onSubmit} disabled={isButtonDisabled} title="Sign In"/>
                        </Box>
                    </form>
                </div>
                <Box mt={4}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        {'Copyright © '}
                        <Link color="inherit" href="https://roomful.net/">Roomful</Link>
                        {` 2015-${new Date().getFullYear()}.`}
                    </Typography>
                </Box>
            </div>
            <Snackbar open={openWarning} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Wrong login data! Change login or password, and then try again!
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Unauthorized!
                </Alert>
            </Snackbar>
        </Container>

    );
}

export default withRouter(SignIn);