import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import {Link} from "react-router-dom";
import Utils from "../../../Auth/Utils";
import {API} from "../../../api/API";
import CustomTable from "../../customTables/CustomTable";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {StyledButton} from "../../../customstyled/StyledButton";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Checkbox from "@material-ui/core/Checkbox";
import {Permission} from "../../../rbac/Permissions";
import {UsersStore} from "../../../stores/UsersStore";

const HostsTable = () => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [openForEdit, setOpenForEdit] = useState(false);
    const [hostId, setHostId] = useState('');
    const [open, setOpen] = useState(false);
    const [addHost, setAddHost] = useState({
        hostName: '',
        ipAddress: '',
        port: 0,
        selfHost: false,
        hasWildcard: false,
        accessKey: ''
    });
    const [secretKey, setSecretKey] = useState('');
    const [editHostData, setEditHostData] = useState({
        hostName: '',
        ipAddress: '',
        port: 0,
        selfHost: false,
        hasWildcard: false,
        accessKey: ''
    });
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);
    const [needReRender, setNeedReRender] = useState(false);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setHostId(id);
        setOpenForDelete(true);
    };

    const editOnClicked = (e, hostData) => {
        e.preventDefault();
        setHostId(hostData.id);
        setEditHostData({
            hostName: hostData.hostName,
            ipAddress: hostData.ipAddress,
            port: hostData.port,
            selfHost: hostData.selfHost,
            hasWildcard: hostData.hasWildcard,
            accessKey: hostData.accessKey
        });
        setOpenForEdit(true);
    };

    const handleClose = () => {
        setOpenForDelete(false);
        setOpen(false);
        setOpenForEdit(false);
    };

    const deleteHost = async () => {
        const totalAfterDelete = await API.Hosts.HostDelete(hostId, secretKey);
        setOpenForDelete(false);
        let currentPage = page;
        if (totalAfterDelete.total % size === 0) {
            currentPage = page - 1;
        }
        const table = await API.Hosts.getHosts(currentPage === 0 ? '' : currentPage, size === 0 ? '' : size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const onClicked = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const addNewHost = async () => {
        await API.Hosts.addNewHost(addHost);
        setOpen(false);
        const table = await API.Hosts.getHosts(page === 0 ? '' : page, size === 0 ? '' : size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
        setNeedReRender(true);
    };

    const editHost = async () => {
        await API.Hosts.editHost(editHostData, hostId);
        setOpenForEdit(false);
        const table = await API.Hosts.getHosts(page === 0 ? '' : page, size === 0 ? '' : size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 21}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(5)
        },
        {
            name: 'hostName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Name</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewHostsInfo) || userState.permissions?.includes(Permission.ViewOwnHostsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewHostsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnHostsInfo)
                            ? <Link to={'/host/' + rowData.id}>
                                <div>
                                    <span style={{textDecoration: 'underline', color: "black"}}>{rowData.hostName}</span>
                                </div>
                            </Link>
                            : <div>
                                <span>{rowData.hostName}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(30)
        },
        {
            name: 'ipAddress',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Ip</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.ipAddress}</span>
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            name: 'port',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Port</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.port}</span>
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'selfHost',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Is Me?</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.selfHost === true ? 'Yes' : 'No'}</span>
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'hasWildcard',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Wildcard?</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.hasWildcard === true ? 'Yes' : 'No'}</span>
                </div>
            ),
            width: Utils.RemToPx(9)
        },
        {
            name: 'domainsCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Domains</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewHostsInfo) || userState.permissions?.includes(Permission.ViewOwnHostsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewHostsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnHostsInfo)
                            ? <Link to={'/host/' + rowData.id}>
                                <div>
                                    <span style={{
                                        textDecoration: 'underline',
                                        color: "black"
                                    }}>{rowData.domainsCount + ' details'}
                                    </span>
                                </div>
                            </Link>
                            : <div>
                                <span>{rowData.domainsCount + ' details'}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(14)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex align-items-center justify-content-center">
                    {
                        userState.permissions?.includes(Permission.EditHosts) || userState.permissions?.includes(Permission.EditOwnHosts) ||
                        userState.permissionsAssigned?.includes(Permission.EditHosts) || userState.permissionsAssigned?.includes(Permission.EditOwnHosts)
                            ? <StyledButton onClick={e => editOnClicked(e, rowData)} icon="fas fa-edit"
                                            tooltip={"Edit host"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                height: 25,
                                                width: 28,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer'
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.DeleteHosts) || userState.permissions?.includes(Permission.DeleteOwnHosts) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteHosts) || userState.permissionsAssigned?.includes(Permission.DeleteOwnHosts)
                            ?
                            <StyledButton onClick={e => deleteOnClicked(e, rowData.id)} icon="fas fa-solid fa-trash-can"
                                          tooltip={"Delete host"}
                                          type="danger"
                                          variant="contained"
                                          style={{
                                              height: 25,
                                              width: 28,
                                              background: '#343a40',
                                              color: '#c2c7d0',
                                              border: 'none',
                                              borderRadius: 3,
                                              cursor: 'pointer',
                                              marginLeft: 3
                                          }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                </span>
            ),
            // width: Utils.RemToPx(13)
        }
    ].filter(el => el));
    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Hosts.getHosts(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        setPage(page);
        setSize(size);
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div className="snapShotsTable">
                <div style={{float: 'none'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="route">Hosts</span>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="tableTitle d-flex justify-content-between">
                    <h3 className="hostTableTitle">ACP : Hosts</h3>
                    <StyledButton onClick={e => onClicked(e)}
                                  titleClassname="addItemButton"
                                  icon="fas fa-plus"
                                  title="Add host" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                        marginBottom: 10,
                        float: 'right'
                    }}
                    />
                </div>
                <section className="content">
                    <div id="table-container">
                        <CustomTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true,
                            reRender: needReRender
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                     texts={{noItems: "No hosts..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Add deploy host
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Host Name:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="HostName"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setAddHost({...addHost, hostName: event.target.value}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">2) Ip Address:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="IpAddress:"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setAddHost({...addHost, ipAddress: event.target.value}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">3) Port:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Port:"
                                type="number"
                                fullWidth
                                variant="standard"
                                onChange={(event => setAddHost({...addHost, port: parseInt(event.target.value)}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">4) Self Host:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                onChange={(event => setAddHost({...addHost, selfHost: event.target.checked}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">5) Wildcard:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                onChange={(event => setAddHost({...addHost, hasWildcard: event.target.checked}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">6) AccessKey:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="AccessKey:"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setAddHost({...addHost, accessKey: event.target.value}))}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={addNewHost} title="Add host" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to delete the host?
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0 last">
                        <div className="col-1"><label className="col-form-label">Secret key:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="secretKey"
                                label="Secret key"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setSecretKey(event.target.value))}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={deleteHost} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>

            <Dialog open={openForEdit} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Are you sure you want edit host?
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Host Name:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="HostName"
                                value={editHostData.hostName}
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setEditHostData({...editHostData, hostName: event.target.value}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">2) Ip Address:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                value={editHostData.ipAddress}
                                label="IpAddress:"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setEditHostData({...editHostData, ipAddress: event.target.value}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">3) Port:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Port:"
                                value={editHostData.port}
                                type="number"
                                fullWidth
                                variant="standard"
                                onChange={(event => setEditHostData({
                                    ...editHostData,
                                    port: parseInt(event.target.value)
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">4) Self Host:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={editHostData?.selfHost || false}
                                onChange={(event => setEditHostData({...editHostData, selfHost: event.target.checked}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">5) Wildcard:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={editHostData?.hasWildcard || false}
                                onChange={(event => setEditHostData({
                                    ...editHostData,
                                    hasWildcard: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">5) AccessKey:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="AccessKey:"
                                type="text"
                                value={editHostData.accessKey}
                                fullWidth
                                variant="standard"
                                onChange={(event => setEditHostData({...editHostData, accessKey: event.target.value}))}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={editHost} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForEdit(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default HostsTable;