import React, {useContext, useEffect} from 'react';
import {Link} from "react-router-dom";
import '../styles/All.css'
import Checkbox from "@material-ui/core/Checkbox";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {API} from "../api/API";
import {UsersStore} from "../stores/UsersStore";
import {Permission} from "../rbac/Permissions";

export const Dashboard = () => {
    const {state: userState} = useContext(UsersStore);

    const FileNameInfo = () => {

        useEffect(() => {
            (async () => {
                await API.BuildRequests.buildRequest();
            })();
        }, []);

        return (
            <>
                <div className={"dashboardNav"}>
                    <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                        <span className="route">Dashboard</span>
                    </Breadcrumbs>
                </div>
                {
                    userState.permissions?.includes(Permission.ViewDashboard) ||
                    userState.permissionsAssigned?.includes(Permission.ViewConfigs) ?
                        <>
                            <div className="dashboardTitle">
                                <h3>Change scenario of request build:</h3>
                            </div>
                            <div className="background">
                                <Link to={"/dashboard/incremental-build"}
                                      className="d-flex border-bottom padding-4 black">
                                    <div className="col-1 textField">
                                        <Checkbox disabled={true} className="col-form-label"/>
                                    </div>
                                    <div className="col-11 d-flex align-items-center" style={{cursor: 'pointer'}}>
                                        <label className="col-form-label" style={{cursor: 'pointer'}}>Incremental Build
                                            (Release
                                            staging
                                            fo all incremental build configurations)</label>
                                    </div>
                                </Link>
                                <Link to={"/dashboard/default-build"} className="d-flex border-bottom padding-4 black">
                                    <div className="col-1 textField">
                                        <Checkbox disabled={true} className="col-form-label"/>
                                    </div>
                                    <div className="col-11 d-flex align-items-center" style={{cursor: 'pointer'}}>
                                        <label className="col-form-label" style={{cursor: 'pointer'}}>Default
                                            build(beta)
                                            .roomful.net &
                                            tau.roomful.net from staging</label>
                                    </div>
                                </Link>
                                <Link to={"/dashboard/config-domains-build"}
                                      className="d-flex border-bottom padding-4 black">
                                    <div className="col-1 textField">
                                        <Checkbox disabled={true} className="col-form-label"/>
                                    </div>
                                    <div className="col-11 d-flex align-items-center" style={{cursor: 'pointer'}}>
                                        <label className="col-form-label" style={{cursor: 'pointer'}}>Build
                                            configuration to its
                                            domains</label>
                                    </div>
                                </Link>
                                <Link to={"/dashboard/custom-config-build"}
                                      className="d-flex border-bottom last padding-4 black">
                                    <div className="col-1 textField">
                                        <Checkbox disabled={true} className="col-form-label"/>
                                    </div>
                                    <div className="col-11 d-flex align-items-center" style={{cursor: 'pointer'}}>
                                        <label className="col-form-label" style={{cursor: 'pointer'}}>Custom
                                            configuration
                                            build</label>
                                    </div>
                                </Link>
                            </div>
                        </>
                        : null
                }
            </>
        )
    };

    return (
        <div className="snapShotsTable">
            <div className="w-100 d-flex flex-column pl-5">
                <FileNameInfo/>
            </div>
        </div>
    );
};

