import React, {useContext, useEffect, useState} from 'react';
import {API} from "../../../../../api/API";
import {Link} from "react-router-dom";
import {UsersStore} from "../../../../../stores/UsersStore";
import {Permission} from "../../../../../rbac/Permissions";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import {StyledCreatableAsyncSelect} from "../../../../../customstyled/CreatableAsyncSelect/StyledCreatableAsyncSelect";
import {StyledAsyncSelect} from "../../../../../customstyled/AsyncSelect/StyledAsyncSelect";
import MenuItem from "@material-ui/core/MenuItem";

export const KeyInfo = (props) => {
    const {state: userState} = useContext(UsersStore);
    const [keyInfo, setKeyInfo] = useState({});
    const [editKey, setEditKey] = useState({});
    const [openWildcardEdit, setOpenWildcardEdit] = useState(false);
    const [openForBlockUnblock, setOpenForBlockUnblock] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const data = [
        {type: 'Yes', value: true},
        {type: 'No', value: false}
    ];

    useEffect( () => {
        (async () => {
            const table = await API.Keys.getKeyInfo(props.keys);
            setKeyInfo({
                ...keyInfo,
                id: table.data.id,
                email: table.data.email,
                key: table.data.key,
                hosts: table.data.hostsCount,
                domains: table.data.domainsCount,
                hostsNames: table.data.hosts,
                domainsNames: table.data.domains,
                isExpired: table.data.isExpired,
                wildcard: table.data.wildcard
            });
        })();
    }, []);

    const blockUnblockClicked = (e, id, isBlocked) => {
        e.preventDefault();
        setIsBlocked(isBlocked);
        setOpenForBlockUnblock(true);
    };

    const editWildcardClicked = async (e, id, wildcard, hosts, domains, isExpired) => {
        e.preventDefault();
        setEditKey({...editKey, wildcard: wildcard, hosts: hosts, domains: domains, isExpired: isExpired});
        setOpenWildcardEdit(true);
    };

    const editWildcard = async () => {
        await API.Keys.editKey(keyInfo.id, {
            wildcard: editKey.wildcard,
            isExpired: editKey.isExpired,
            domains: editKey.domains.map(domain => domain.domainId),
            hosts: editKey.hosts.map(host => host.hostId)
        });
        setOpenWildcardEdit(false);
        const table = await API.Keys.getKeyInfo(props.keys);
        setKeyInfo({
            ...keyInfo,
            id: table.data.id,
            email: table.data.email,
            key: table.data.key,
            hosts: table.data.hostsCount,
            domains: table.data.domainsCount,
            hostsNames: table.data.hosts,
            domainsNames: table.data.domains,
            isExpired: table.data.isExpired,
            wildcard: table.data.wildcard
        });
    };

    const handleClose = () => {
        setOpenWildcardEdit(false);
        setOpenForBlockUnblock(false);
    };

    const blockUnBlockKey = async () => {
        isBlocked === false ? (await API.Keys.keyBlock(keyInfo.id)) : (await API.Keys.keyUnBlock(keyInfo.id));
        setOpenForBlockUnblock(false);
        const table = await API.Keys.getKeyInfo(props.keys);
        setKeyInfo({
            ...keyInfo,
            id: table.data.id,
            email: table.data.email,
            key: table.data.key,
            hosts: table.data.hostsCount,
            domains: table.data.domainsCount,
            hostsNames: table.data.hosts,
            domainsNames: table.data.domains,
            isExpired: table.data.isExpired,
            wildcard: table.data.wildcard
        });
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected;
            setEditKey({
                ...editKey,
                domains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setEditKey({
                ...editKey,
                domains: domainNames
            });
        }
    };

    const selectedHostsChange = (selected) => {
        let hostNames = [];
        if (selected && selected.length > 0) {
            hostNames = selected;
            setEditKey({
                ...editKey,
                hosts: hostNames
            });
        } else if (selected && selected.length === 0) {
            setEditKey({
                ...editKey,
                hosts: hostNames
            });
        }
    };

    const KeyInfo = () => {
        return (
            <>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">#</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{keyInfo.id}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Email</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{keyInfo.email}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Key</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{keyInfo.key}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Hosts</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{keyInfo.hosts}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Domains</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{keyInfo.domains}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Is Expired</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{keyInfo.isExpired ? 'Yes' : 'No'}</span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Has Wildcard?</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{keyInfo.wildcard ? 'Yes' : 'no'}</span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Actions</label></div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.BlockDeployKeys) || userState.permissions?.includes(Permission.BlockOwnDeployKeys) ||
                            userState.permissions?.includes(Permission.UnblockDeployKeys) || userState.permissions?.includes(Permission.UnblockOwnDeployKeys) ||
                            userState.permissionsAssigned?.includes(Permission.BlockDeployKeys) || userState.permissionsAssigned?.includes(Permission.BlockOwnDeployKeys) ||
                            userState.permissionsAssigned?.includes(Permission.UnblockDeployKeys) || userState.permissionsAssigned?.includes(Permission.UnblockOwnDeployKeys)
                                ? <StyledButton onClick={e => blockUnblockClicked(e, keyInfo.id, keyInfo.isExpired)}
                                                icon={keyInfo.isExpired === false ? "fas fa-lock-open" : "fas fa-lock-alt"}
                                                tooltip={"Block/Unblock key"}
                                                type="danger"
                                                variant="contained"
                                                style={{
                                                    height: 25,
                                                    width: 28,
                                                    background: '#343a40',
                                                    color: '#c2c7d0',
                                                    border: 'none',
                                                    borderRadius: 3,
                                                    cursor: 'pointer',
                                                }}/>
                                : null
                        }
                        {
                            userState.permissions?.includes(Permission.EditDeployKeys) || userState.permissions?.includes(Permission.EditOwnDeployKeys) ||
                            userState.permissionsAssigned?.includes(Permission.EditDeployKeys) || userState.permissionsAssigned?.includes(Permission.EditOwnDeployKeys)
                                ? <StyledButton
                                    onClick={e => editWildcardClicked(e, keyInfo.id, keyInfo.wildcard, keyInfo.hostNames, keyInfo.domainNames, keyInfo.isExpired)}
                                    icon="fas fa-edit"
                                    tooltip={"Edit key"}
                                    type="danger"
                                    variant="contained"
                                    style={{
                                        height: 25,
                                        width: 28,
                                        background: '#343a40',
                                        color: '#c2c7d0',
                                        border: 'none',
                                        borderRadius: 3,
                                        marginLeft: 3,
                                        cursor: 'pointer',
                                    }}/>
                                : null
                        }
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
        <div className="keyInfoContainer">
            <h3 className="currentKeyTableTitle tableTitle marginZero">
                <Link to={"/keys"} style={{textDecoration: "underline", color: "black"}}>
                    <span>Key</span>
                </Link>
                <span>: {props.keys}</span>
            </h3>
            <div className="w-100 d-flex flex-column pl-5 snapshotFilenameInfo report">
                <KeyInfo/>
            </div>
        </div>
            <Dialog open={openForBlockUnblock} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to {isBlocked === false ? 'block' : 'unblock'} the deploy key?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={blockUnBlockKey} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForBlockUnblock(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openWildcardEdit} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Edit Key
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">1) Wildcard:</label></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            select
                            id="wildcard"
                            label="Is Wildcard"
                            type="text"
                            value={editKey?.wildcard}
                            fullWidth
                            variant="standard"
                            onChange={(event => setEditKey({...editKey, wildcard: event.target.value}))}
                        >
                            {data.map((option, i) => (
                                <MenuItem key={`data-${i}`} value={option.value}>
                                    {option.type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">2) IsExpired:</label></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            select
                            id="isExpired"
                            label="Is Expired"
                            type="text"
                            value={editKey?.isExpired}
                            fullWidth
                            variant="standard"
                            onChange={(event => setEditKey({...editKey, isExpired: event.target.value}))}
                        >
                            {data.map((option, i) => (
                                <MenuItem key={`data-${i}`} value={option.value}>
                                    {option.type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">3) Develop Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={editKey.domains}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainId}
                                onChange={handleChange}
                                className={"keysStyledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 last padding-4">
                        <div className="col-1"><label className="col-form-label">4) Hosts:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={editKey.hosts}
                                getOptionLabel={(e) => e.hostName}
                                getOptionValue={(e) => e.hostId}
                                onChange={selectedHostsChange}
                                className={"keysStyledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchHosts}
                                keyValue='hostName'
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={editWildcard} title="Edit" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenWildcardEdit(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            </>
    );
};
