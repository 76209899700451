import {AuthAPI} from "../modules/AuthAPI";
import {UsersAPI} from "../modules/UsersAPI";
import {SnapShotsAPI} from "../modules/SnapShotsAPI";
import {ConfigsAPI} from "../modules/ConfigsAPI";
import {BuildRequestsAPI} from "../modules/BuildRequestsAPI";
import {ReportsAPI} from "../modules/ReportsAPI";
import {HostsAPI} from "../modules/HostsAPI";
import {KeysAPI} from "../modules/KeysAPI";
import {BuildServersAPI} from "../modules/BuildServersAPI";
import {JobsAPI} from "../modules/JobsAPI";

export class API {
    static get Auth() {
        return AuthAPI;
    }

    static get Users() {
        return UsersAPI;
    }

    static get Configs() {
        return ConfigsAPI;
    }

    static get BuildRequests() {
        return BuildRequestsAPI;
    }

    static get Reports() {
        return ReportsAPI;
    }

    static get Hosts() {
        return HostsAPI;
    }

    static get Keys() {
        return KeysAPI;
    }

    static get SnapShots() {
        return SnapShotsAPI;
    }

    static get BuildServers() {
        return BuildServersAPI;
    }

    static get Jobs() {
        return JobsAPI;
    }
}