import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import Utils from "../../../Auth/Utils";
import {API} from "../../../api/API";
import CustomTable from "../../customTables/CustomTable";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {StyledButton} from "../../../customstyled/StyledButton";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import {Permission} from "../../../rbac/Permissions";
import {UsersStore} from "../../../stores/UsersStore";

const BuildServersTable = () => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [buildServerId, setBuildServerId] = useState('');
    const [open, setOpen] = useState(false);
    const [addNewBuildServer, setAddNewBuildServer] = useState({});
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);
    const [needReRender, setNeedReRender] = useState(false);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const blockClicked = (e, id) => {
        e.preventDefault();
        setBuildServerId(id);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForDelete(false);
        setOpen(false);
    };

    const deleteBuildServer = async () => {
        const totalAfterDelete = await API.BuildServers.buildServerDelete(buildServerId);
        setOpenForDelete(false);
        let currentPage = page;
        if (totalAfterDelete.data.total % size === 0) {
            currentPage = page - 1;
        }
        const table = await API.BuildServers.getBuildServers(currentPage === 0 ? '' : currentPage, size === 0 ? '' : size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const onClicked = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const addBuildServer = async () => {
        await API.BuildServers.addBuildServer(addNewBuildServer);
        setOpen(false);
        const table = await API.BuildServers.getBuildServers(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
        setNeedReRender(true);
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 21}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(5)
        },
        {
            name: 'serverName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Name</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.serverName}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(19)
        },
        {
            name: 'serverUrl',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Url</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.serverUrl}</span>
                    </div>
                </div>
            ),
            minWidth: Utils.RemToPx(30)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex justify-content-center align-items-center">
                    {
                        userState.permissions?.includes(Permission.DeleteBuildServers) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteBuildServers)
                            ? <StyledButton onClick={e => blockClicked(e, rowData.id)} icon="fas fa-solid fa-trash-can"
                                            tooltip={"Delete build server"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(15)
        }
    ].filter(el => el));
    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.BuildServers.getBuildServers(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        setPage(page);
        setSize(size);
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div className="snapShotsTable">
                <div style={{float: 'none'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="route">Build Servers</span>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="tableTitle d-flex justify-content-between">
                    <h3 className="buildServerTableTitle">ACP : Build Servers</h3>
                    {
                        userState.permissions?.includes(Permission.ViewDeployKeysInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewDeployKeysInfo)
                            ? <StyledButton onClick={e => onClicked(e)}
                                            titleClassname="addItemButton"
                                            icon="fas fa-plus"
                                            title="Add build server" type="danger"
                                            variant="contained" style={{
                                padding: 5,
                                background: '#343a40',
                                color: '#c2c7d0',
                                border: 'none',
                                borderRadius: 3,
                                cursor: 'pointer',
                                marginBottom: 10,
                                float: 'right'
                            }}
                            />
                            : null
                    }
                </div>
                <section className="content">
                    <div id="table-container">
                        <CustomTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true,
                            reRender: needReRender
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                     texts={{noItems: "No build servers..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to delete the build server?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteBuildServer} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Add build server
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="ServerName"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(event => setAddNewBuildServer({
                            ...addNewBuildServer,
                            serverName: event.target.value
                        }))}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="ServerUrl"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(event => setAddNewBuildServer({
                            ...addNewBuildServer,
                            serverUrl: event.target.value
                        }))}
                    />
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={addBuildServer} title="Add build server" type="danger"
                                  variant="contained" style={{
                        padding: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BuildServersTable;