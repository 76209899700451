import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {API} from "../../api/API";
import TextField from "@mui/material/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import {StyledButton} from "../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {CustomBuildStore} from "../../stores/CustomBuildStore";
import {DashboardStore} from "../../stores/DashboardStore";
import {ConfigStore} from "../../stores/ConfigStore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {StyledAsyncSelect} from "../../customstyled/AsyncSelect/StyledAsyncSelect";
import {StyledCreatableAsyncSelect} from "../../customstyled/CreatableAsyncSelect/StyledCreatableAsyncSelect";

let configData = {};

export const CustomConfigBuild = () => {
    const {state: customConfig, dispatch: setCustomConfig} = useContext(CustomBuildStore);
    const {state: dashboardConfig, dispatch: setDashboardConfig} = useContext(DashboardStore);
    const {state: config} = useContext(ConfigStore);
    const [open, setOpen] = useState(false);
    const [configDataInput, setConfigDataInput] = useState({
        buildServer: "2",
        configName: 'No Specific Config',
        unityBranch: 'develop',
        webglBranch: 'development',
        il2CppType: 'asm.js',
        buildConfig: 'DefaultConfiguration',
        logLevel: 'none',
        developDomains: [],
        productionDomains: [],
        excludePackages: [''],
        cdnLink: '',
        needManualTesting: false,
        readyForProduction: false,
        needOnlyStableStaging: false
    });
    const location = useHistory();

    useEffect(() => {
        (async () => {
            const buildData = await API.BuildRequests.buildRequest();
            setDashboardConfig({type: 'SET_BUILD_DATA', payload: buildData.data});
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setDashboardConfig({type: 'SET_SCENARIO', payload: 3});
            try {
                configData = JSON.parse(sessionStorage.getItem('customConfigData')) || {};
            } catch (_) {
                configData = {};
            }
            let config = {
                buildServer: configData.buildServer || "2",
                configName: 'No Specific Config',
                unityBranch: configData.unityBranch || 'develop',
                webglBranch: configData.webglBranch || 'development',
                il2CppType: configData.il2CppType || 'asm.js',
                buildConfig: configData.buildConfig || 'DefaultConfiguration',
                logLevel: configData.logLevel || 'none',
                developDomains: configData.developDomains || [],
                productionDomains: configData.productionDomains || [],
                excludePackages: configData.excludePackages || [],
                cdnLink: configData.cdnLink || '',
                needManualTesting: configData.needManualTesting || false,
                readyForProduction: configData.readyForProduction || false,
                needOnlyStableStaging: configData.needOnlyStableStaging || false
            };
            setConfigDataInput(config);
            sessionStorage.setItem('customConfigData', JSON.stringify(configData = config));
        })();
    }, []);

    const continueOnClicked = () => {
        setCustomConfig({type: "SET_DATA_INPUT", payload: configDataInput});
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const createConfig = async () => {
        await API.BuildRequests.addBuildRequest({
            key: dashboardConfig.deployKey,
            buildServerId: parseInt(dashboardConfig.dataInputBuildServer) || dashboardConfig.buildServer[0].id,
            scenario: dashboardConfig.scenario,
            configId: 0,
            configParsed: JSON.stringify({
                buildType: dashboardConfig.scenario !== 3 ? 'release' : 'snapshot',
                buildNumber: dashboardConfig.buildNumber,
                deployKey: dashboardConfig.deployKey,
                configName: config.staticValue.configName,
                buildServerId: configDataInput.buildServer || dashboardConfig.buildServer[0].id,
                unityBranch: configDataInput.unityBranch,
                webglBranch: configDataInput.webglBranch,
                il2CppType: configDataInput.il2CppType,
                buildConfig: configDataInput.buildConfig,
                logLevel: configDataInput.logLevel,
                developDomains: configDataInput.developDomains,
                productionDomains: configDataInput.productionDomains,
                excludePackages: configDataInput.excludePackages,
                cdnLink: configDataInput.cdnLink,
                needManualTesting: configDataInput.needManualTesting,
                readyForProduction: configDataInput.readyForProduction,
                needOnlyStableStaging: configDataInput.needOnlyStableStaging
            })
        });
        setOpen(false);
        location.push('/dashboard');
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            configData['developDomains'] = domainNames;
            sessionStorage.setItem('customConfigData', JSON.stringify(configData));
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        }
    };

    const selectedProductionDomains = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            configData['productionDomains'] = domainNames;
            sessionStorage.setItem('customConfigData', JSON.stringify(configData));
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        }
    };

    const onChange = (key, payload) => {
        configData[key] = payload;
        sessionStorage.setItem('customConfigData', JSON.stringify(configData));
        switch (key) {
            case 'buildServer':
                setConfigDataInput({
                    ...configDataInput,
                    buildServer: payload
                });
                break;
            case 'unityBranch':
                setConfigDataInput({
                    ...configDataInput,
                    unityBranch: payload
                });
                break;
            case 'webglBranch':
                setConfigDataInput({
                    ...configDataInput,
                    webglBranch: payload
                });
                break;
            case 'il2CppType':
                setConfigDataInput({
                    ...configDataInput,
                    il2CppType: payload
                });
                break;
            case 'buildConfig':
                setConfigDataInput({
                    ...configDataInput,
                    buildConfig: payload
                });
                break;
            case 'logLevel':
                setConfigDataInput({
                    ...configDataInput,
                    logLevel: payload
                });
                break;
            case 'excludePackages':
                setConfigDataInput({
                    ...configDataInput,
                    excludePackages: payload
                });
                break;
            case 'cdnLink':
                setConfigDataInput({
                    ...configDataInput,
                    cdnLink: payload
                });
                break;
            case 'needManualTesting':
                setConfigDataInput({
                    ...configDataInput,
                    needManualTesting: payload
                });
                break;
            case 'readyForProduction':
                setConfigDataInput({
                    ...configDataInput,
                    readyForProduction: payload
                });
                break;
            case 'needOnlyStableStaging':
                setConfigDataInput({
                    ...configDataInput,
                    needOnlyStableStaging: payload
                });
                break;
        }
    };

    const excludePackagesChange = (selected) => {
        let excludePackages = [];
        if (selected && selected.length > 0) {
            excludePackages = selected?.map(excludePackages => (excludePackages.excludePackageName));
            configData['excludePackages'] = excludePackages;
            sessionStorage.setItem('excludePackages', JSON.stringify(configData));
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        } else if (selected && selected.length === 0) {
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        }
    };

    return (
        <>
            <div className="contentWrapper">
                <div className="customConfigBuildNav">
                    <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                        <Link to="/" className="black routes">
                            <span>Dashboard</span>
                        </Link>
                        <span className="route">Custom Config Build</span>
                    </Breadcrumbs>
                </div>
                <div className="flex-column">
                    <div className="dashboardTitle">
                        <h3>Correct build request config if needed:</h3>
                    </div>
                    <div className="backgroundCustomConfig">
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">1) Build Server:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    value={dashboardConfig.buildServer.length > 0 ? (dashboardConfig.dataInputBuildServer || dashboardConfig.buildServer[0].id) : ''}
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('buildServer', event.target.value)}
                                >
                                    {dashboardConfig.buildServer.map((option, i) => (
                                        // {customConfig?.buildServer?.map((option, i) => (
                                        <MenuItem key={`row-serverName-branch-${i}`} value={option.id}>
                                            {option.serverName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">2) Unity Branch:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={dashboardConfig?.unity?.length > 0 ? (configDataInput.unityBranch || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('unityBranch', event.target.value)}
                                >
                                    {dashboardConfig?.unity?.map((option, i) => (
                                        <MenuItem key={`row-unity-branch-${i}`} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">3) WebGL Branch:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={dashboardConfig?.webgl?.length > 0 ? (configDataInput.webglBranch || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('webglBranch', event.target.value)}
                                >
                                    {dashboardConfig?.webgl?.map((option, i) => (
                                        <MenuItem key={`row-webgl-branch-${i}`} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">4) Il2Cpp Type:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={customConfig?.il2CppType?.length > 0 ? (configDataInput.il2CppType || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('il2CppType', event.target.value)}
                                >
                                    {customConfig?.il2CppType?.map((option, i) => (
                                        <MenuItem key={`row-webgl-branch-${i}`} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">5) Build Config:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={customConfig?.buildConfig?.length > 0 ? (configDataInput.buildConfig || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('buildConfig', event.target.value)}
                                >
                                    {customConfig?.buildConfig?.map((option, i) => (
                                        <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">6) Log Level:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={customConfig?.logLevel?.length > 0 ? (configDataInput.logLevel || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('logLevel', event.target.value)}
                                >
                                    {customConfig?.logLevel?.map((option, i) => (
                                        <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">7) Develop Domains:</label></div>
                            <div className="col-11 align-items-center">
                                <div style={{padding: '6px 0'}}>
                                    <StyledAsyncSelect
                                        isMulti={true}
                                        defaultValue={configDataInput.developDomains?.map(domainName => ({domainName}))}
                                        getOptionLabel={(e) => e.domainName}
                                        getOptionValue={(e) => e.domainName}
                                        onChange={handleChange}
                                        className={"dashboardStyledAsyncSelect"}
                                        fetchMethod={API.SnapShots.searchDomains}
                                        keyValue='domainName'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">8) Production Domains:</label>
                            </div>
                            <div className="col-11 align-items-center">
                                <div style={{padding: '6px 0'}}>
                                    <StyledAsyncSelect
                                        isMulti={true}
                                        defaultValue={configDataInput.productionDomains?.map(domainName => ({domainName}))}
                                        getOptionLabel={(e) => e.domainName}
                                        getOptionValue={(e) => e.domainName}
                                        onChange={selectedProductionDomains}
                                        className={"dashboardStyledAsyncSelect"}
                                        fetchMethod={API.SnapShots.searchDomains}
                                        keyValue='domainName'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">9) Exclude Packages:</label></div>
                            <div className="col-11 align-items-center">
                                <div style={{padding: '6px 0'}}>
                                    <StyledCreatableAsyncSelect
                                        isMulti={true}
                                        defaultValue={configDataInput.excludePackages?.map((excludePackage) => ({
                                            excludePackageName: excludePackage,
                                            excludePackageId: excludePackage
                                        }))}
                                        getOptionLabel={(e) => e.excludePackageName}
                                        getOptionValue={(e) => e.excludePackageId}
                                        onChange={excludePackagesChange}
                                        className={"dashboardStyledAsyncSelect"}
                                        isInputValueCorrect={true}
                                        keyValue='excludePackages'
                                    />
                                </div>
                            </div>
                            {/*</div>*/}
                            {/*    <TextField*/}
                            {/*        autoFocus*/}
                            {/*        margin="dense"*/}
                            {/*        id="name"*/}
                            {/*        value={configDataInput.excludePackages}*/}
                            {/*        type="text"*/}
                            {/*        multiline*/}
                            {/*        rows={3}*/}
                            {/*        fullWidth*/}
                            {/*        variant="standard"*/}
                            {/*        onChange={event => onChange('excludePackages', event.target.value)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">10) CDN Link:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type="text"
                                    value={configDataInput.cdnLink}
                                    multiline
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('cdnLink', event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">11) Need manual testing:</label>
                            </div>
                            <div className="col-11 d-flex align-items-center">
                                <Checkbox
                                    checked={configDataInput.needManualTesting}
                                    onChange={event => onChange('needManualTesting', event.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">12) Ready for production:</label>
                            </div>
                            <div className="col-11 d-flex align-items-center">
                                <Checkbox
                                    checked={configDataInput.readyForProduction}
                                    onChange={event => onChange('readyForProduction', event.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">13) Need only stable
                                staging:</label>
                            </div>
                            <div className="col-11 d-flex align-items-center">
                                <Checkbox
                                    checked={configDataInput.needOnlyStableStaging}
                                    onChange={event => onChange('needOnlyStableStaging', event.target.checked)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttonBackground">
                        <StyledButton onClick={continueOnClicked} title="Continue" type="danger"
                                      variant="contained" style={{
                            padding: 10,
                            background: '#343a40',
                            color: '#c2c7d0',
                            border: 'none',
                            borderRadius: 3,
                            cursor: 'pointer'
                        }}/>
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Create build request?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={createConfig} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};
