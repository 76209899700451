import React, {useEffect} from 'react';
import Navbar from "./TopBar";
import './Plugin';
import {withRouter} from "react-router-dom";
import $ from "jquery";
import NavbarAndSidebar from "./Plugin";

const SELECTOR_TOGGLE_BUTTON = '[data-widget="pushmenu"]';

const notificationEvent = () => {
    const notifications = document.querySelector("#notifications");
    if (notifications && notifications.classList.contains("show")) notifications.classList.remove("show");
};

const Panel = (props) => {

    useEffect(() => {
        document.body?.classList?.add("sidebar-mini");
        window.addEventListener("click", notificationEvent);
        NavbarAndSidebar._jQueryInterface.call($(SELECTOR_TOGGLE_BUTTON));
            return () => {
                window.removeEventListener("click", notificationEvent);
            }
    }, []);

    return (
        <div className="wrapper">
            <Navbar/>
            {props.children}
        </div>
    );
};

export default withRouter(Panel);