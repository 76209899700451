// Copyright © 2015-2020 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../../api/API";
import Utils from "../../../Auth/Utils";
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import CustomTable from "../../customTables/CustomTable";
import {Link} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {StyledButton} from "../../../customstyled/StyledButton";
import TextField from "@mui/material/TextField";
import '../../../all.min.css';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {StyledAsyncSelect} from "../../../customstyled/AsyncSelect/StyledAsyncSelect";
import {UsersStore} from "../../../stores/UsersStore";
import {Permission} from "../../../rbac/Permissions";

export default function SnapShotsTable() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state: storeState, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [snapshotFileName, setSnapshotFileName] = useState('');
    const [openForUnmount, setOpenForUnmount] = useState(false);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [addSnapshot, setAddSnapshot] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const status = [
        'Deployed',
        'ReDeployed',
        'Removed',
        'UnPacked',
        'Patched',
        'Mounted',
        'UnMounted',
        'Custom',
        'Removing'
    ];
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const onClicked = (e, fileName) => {
        e.preventDefault();
        setOpen(true);
        setSnapshotFileName(fileName)
    };

    const unmountOnClicked = (e, fileName) => {
        e.preventDefault();
        setSnapshotFileName(fileName);
        setOpenForUnmount(true);
    };

    const deleteOnClicked = (e, fileName) => {
        e.preventDefault();
        setSnapshotFileName(fileName);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenForUnmount(false);
        setOpenForDelete(false);
        setAddSnapshot(false);
    };

    const mount = async () => {
        await API.SnapShots.snapshotMount(snapshotFileName, value);
        setOpen(false);
        setValue('');
    };

    const unmount = async () => {
        await API.SnapShots.snapshotUnmount(snapshotFileName);
        setOpenForUnmount(false);
    };

    const deleteSnapshot = async () => {
        await API.SnapShots.snapshotDelete(snapshotFileName);
        setOpenForDelete(false);
        const table = await API.SnapShots.getSnapShots(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const addConfigOnClicked = (e) => {
        e.preventDefault();
        setAddSnapshot(true);
    };

    const uploadFile = async () => {
        await API.SnapShots.snapshotUpload({selectedFile});
        setAddSnapshot(false);
    };

    const downloadOnClicked = async (e, fileName) => {
        e.preventDefault();
        await API.SnapShots.downloadSnapshot(fileName);
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setValue({domains: domainNames.join(',')});
        } else if (selected && selected.length === 0) {
            setValue(domainNames);
        }
    };

    const onPaste = useCallback(async event => {
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        const pastedItems = paste.split(/[\s,\n\r ]+/).filter(t => `${t}`.trim().length > 0);
        if (pastedItems?.length > 0) {
            event.preventDefault();
            const validation = await API.SnapShots.ValidateDomains('domains',  {domains: pastedItems});
            if (validation.data) {
                    setValue([...value, ...validation.data].filter((v, i, a) => a.findIndex(v2 => (v2['domainName'] === v['domainName'])) === i));
                handleChange([...value, ...validation.data].filter((v, i, a) => a.findIndex(v2 => (v2['domainName'] === v['domainName'])) === i));
            }
            return false;
        }
    },[]);

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 21}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(6)
        },
        {
            name: 'fileName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Filename</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    {
                        userState.permissions?.includes(Permission.ViewSnapshotsInfo) || userState.permissions?.includes(Permission.ViewOwnSnapshotsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewSnapshotsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshotsInfo)
                            ? <Link to={'/snapshot/' + rowData.fileName}>
                                <div className="fileName" style={{textAlign: 'left!important'}}>
                                    <span style={{textDecoration: 'underline'}}>{rowData.fileName}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{textAlign: 'left!important'}}>
                                <span>{rowData.fileName}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(32)
        },
        {
            name: 'deployConfig.configName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Config</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    {
                        userState.permissions?.includes(Permission.ViewConfigs) ||
                        userState.permissionsAssigned?.includes(Permission.ViewConfigs)
                            ? <Link to={'/config/' + rowData?.deployConfig?.id}>
                                <div className="fileName" style={{textAlign: 'left!important'}}>
                                    <span style={{textDecoration: 'underline'}}>{rowData?.deployConfig?.configName}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{textAlign: 'left!important'}}>
                                <span>{rowData?.deployConfig?.configName}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(12)
        },
        {
            name: 'domainsCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Domains</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    {
                        userState.permissions?.includes(Permission.ViewSnapshotsInfo) || userState.permissions?.includes(Permission.ViewOwnSnapshotsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewSnapshotsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshotsInfo)
                            ? <Link to={'/snapshot/' + rowData.fileName}>
                                <div style={{textDecoration: 'underline', color: "black"}}>
                                    <span style={{
                                        textOverflow: "unset",
                                        whiteSpace: "normal",
                                        textAlign: "center",
                                        display: "block",
                                        color: "black"
                                    }}>
                                        {rowData.domainsCount + ' [ details ] '}
                                    </span>
                                </div>
                            </Link>
                            : <div style={{color: "black"}}>
                                <span style={{
                                    textOverflow: "unset",
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                    display: "block",
                                    color: "black"
                                }}>
                                    {rowData.domainsCount + ' [ details ] '}
                                </span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(7)
        },
        {
            name: 'status',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Status</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {status[rowData.status]}
                </span>
            ),
            width: Utils.RemToPx(7)
        },
        {
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                </span>
            ),
            // width: Utils.RemToPx(13)
        },
        {
            name: 'updated',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Updated</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {new Date(rowData.updated).toLocaleString()}
                </span>
            ),
            width: Utils.RemToPx(13)
        },
        {
            name: 'historyCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>History</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    {
                        userState.permissions?.includes(Permission.ViewSnapshotsInfo) || userState.permissions?.includes(Permission.ViewOwnSnapshotsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewSnapshotsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshotsInfo)
                            ? <Link to={'/snapshot/' + rowData.fileName}>
                                <div style={{textDecoration: 'underline', color: "black"}}>
                                    <span style={{
                                        textOverflow: "unset",
                                        whiteSpace: "normal",
                                        textAlign: "center",
                                        display: "block",
                                        color: "black"
                                    }}>
                                        {rowData.historyCount + ' [ details ] '}
                                    </span>
                                </div>
                            </Link>
                            : <div style={{color: "black"}}>
                                    <span style={{
                                        textOverflow: "unset",
                                        whiteSpace: "normal",
                                        textAlign: "center",
                                        display: "block",
                                        color: "black"
                                    }}>
                                        {rowData.historyCount + ' [ details ] '}
                                    </span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(7)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex align-items-center justify-content-center">
                    {
                        userState.permissions?.includes(Permission.MountSnapshots) || userState.permissions?.includes(Permission.MountOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.MountSnapshots) || userState.permissionsAssigned?.includes(Permission.MountOwnSnapshots)
                            ? <StyledButton onClick={e => onClicked(e, rowData.fileName)}
                                            icon="fas fa-arrow-alt-circle-down"
                                            // titleClassname="addItemButton"
                                            // title={"Mount snapshot to domains"}
                                            tooltip={"Mount snapshot to domains"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.UnmountSnapshots) || userState.permissions?.includes(Permission.UnmountOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.UnmountSnapshots) || userState.permissionsAssigned?.includes(Permission.UnmountOwnSnapshots)
                            ? <StyledButton onClick={e => unmountOnClicked(e, rowData.fileName)}
                                            icon="fas fa-eraser"
                                            // titleClassname="addItemButton"
                                            // title={"Unmount all domains with this snapshot"}
                                            tooltip={"Unmount all domains with this snapshot"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 3
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.DownloadSnapshots) || userState.permissions?.includes(Permission.DownloadOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.DownloadSnapshots) || userState.permissionsAssigned?.includes(Permission.DownloadOwnSnapshots)
                            ? <StyledButton onClick={e => downloadOnClicked(e, rowData.fileName)}
                                            icon="fas fa-download"
                                            // titleClassname="addItemButton"
                                            // title={"Download snapshot"}
                                            tooltip={"Download snapshot"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 3
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.RebuildSnapshots) || userState.permissions?.includes(Permission.RebuildOwnSnapshots) ||
                        userState.permissionsAssigned?.includes(Permission.RebuildSnapshots) || userState.permissionsAssigned?.includes(Permission.RebuildOwnSnapshots)
                            ? <StyledButton onClick={e => deleteOnClicked(e, rowData.fileName)}
                                            icon="fas fa-solid fa-trash-can"
                                            // titleClassname="addItemButton"
                                            // title={"Delete snapshot"}
                                            tooltip={"Delete snapshot"}
                                            type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 3
                                            }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(12)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return storeState.list;
    }, [storeState.list]);

    const getListItems = useCallback(async (renderType, {page, size}) => {
        const table = await API.SnapShots.getSnapShots(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        setPage(page);
        setSize(size);
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div style={{float: 'none'}}>
                <div className="routeContainer">
                    <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                        <Link to="/" className="black routes">
                            <span>Dashboard</span>
                        </Link>
                        <span className="snapshotRoute"> Snapshots</span>
                    </Breadcrumbs>
                </div>
            </div>
            <div className="tableTitle d-flex justify-content-between">
                <h3 className="snapshotTableTitle">ACP : Snapshots</h3>
                <StyledButton onClick={e => addConfigOnClicked(e)}
                              titleClassname="addItemButton"
                              icon="fas fa-upload"
                              title="Manual upload" type="danger"
                              variant="contained" style={{
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    background: '#343a40',
                    color: '#c2c7d0',
                    border: 'none',
                    borderRadius: 3,
                    cursor: 'pointer',
                    marginBottom: 10,
                    float: 'right'
                }}
                />
            </div>
            <section className="content">
                <div id="table-container">
                    <CustomTable state={{
                        typeOfRender: storeState.renderType,
                        canSelect: false,
                        canHover: false,
                        canSearch: false,
                        pagination: true,
                    }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                 texts={{noItems: "No snapshots..."}}/>
                </div>
            </section>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Mount snapshot to domains
                </DialogTitle>
                <DialogContent dividers style={{height: 400}}>
                    <div className="form-group w-100 d-flex mb-0 border-bottom-none align-items-center">
                        <div className="col-1"><label className="col-form-label">Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={handleChange}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                                validationKey="domains"
                                onPaste={onPaste}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={mount} title="Mount to this domains" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForUnmount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={unmount} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForUnmount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot & then delete it?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteSnapshot} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={addSnapshot} onClose={handleClose}>
                <DialogTitle>
                    Manual snapshot upload
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Snapshot:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        type="file"
                        fullWidth
                        variant="standard"
                        onChange={(event => setSelectedFile(event.target.files))}
                    />
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={uploadFile} title="Upload" type="danger"
                                  variant="contained"
                                  style={{
                                      padding: 5,
                                      background: '#343a40',
                                      color: '#c2c7d0',
                                      border: 'none',
                                      borderRadius: 3,
                                      cursor: 'pointer',
                                  }}/>
                    <StyledButton onClick={() => setAddSnapshot(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained"
                                  style={{
                                      padding: 5,
                                      background: '#343a40',
                                      color: '#c2c7d0',
                                      border: 'none',
                                      borderRadius: 3,
                                      cursor: 'pointer',
                                  }}/>
                </DialogActions>
            </Dialog>
        </>
    );
}