import React, {createContext, useReducer} from 'react';

export const initialState = {
    list: [],
    renderType: "basic",
    total: 0,
    table: [],
};

export const TableStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_LIST':
            return {...state, list: action.payload};
        case 'SET_RENDER_TYPE':
            return {...state, renderType: action.payload};
        case 'SET_TOTAL_COUNT':
            return {...state, total: action.payload};
        case 'SET_TABLE_INFO':
            return {...state, table: action.payload};
        default:
            return state;
    }
}

export const SnapShotsProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <TableStore.Provider value={value}>{props.children}</TableStore.Provider>;
};