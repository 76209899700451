import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import {Link} from "react-router-dom";
import Utils from "../../../Auth/Utils";
import {API} from "../../../api/API";
import CustomTableConfigs from "../../customTables/CustomTablesConfigs";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import {StyledButton} from "../../../customstyled/StyledButton";
import Checkbox from "@material-ui/core/Checkbox";
import "../../../styles/All.css"
import MenuItem from "@material-ui/core/MenuItem";
import {ConfigStore} from "../../../stores/ConfigStore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {StyledAsyncSelect} from "../../../customstyled/AsyncSelect/StyledAsyncSelect";
import {UsersStore} from "../../../stores/UsersStore";
import {Permission} from "../../../rbac/Permissions";
import {StyledCreatableAsyncSelect} from "../../../customstyled/CreatableAsyncSelect/StyledCreatableAsyncSelect";


const ConfigsTable = (callback, deps) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: config, dispatch: setConfig} = useContext(ConfigStore);
    const {state: userState} = useContext(UsersStore);
    const [open, setOpen] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [defaultConfigData, setDefaultConfigData] = useState({});
    const [configDataInput, setConfigDataInput] = useState({
        buildServer: '',
        unityBranch: '',
        webglBranch: '',
        il2CppType: '',
        buildConfig: '',
        logLevel: '',
        developDomains: [],
        productionDomains: [],
        excludePackages: [],
        cdnLink: '',
        needManualTesting: false,
        readyForProduction: false,
        needOnlyStableStaging: false,
        isIncrementalBuild: false,
        isDefaultBuild: false
    });
    const [configId, setConfigId] = useState('');
    const [openForDelete, setOpenForDelete] = useState(false);
    const [addConfigOpen, setAddConfigOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);
    const [needReRender, setNeedReRender] = useState(false);
    const [buildServer, setBuildServer] = useState({});

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const onClicked = async (e, configId, configName, configData) => {
        e.preventDefault();
        const data = await API.Configs.getConfigsInit();
        const buildServer = await API.BuildServers.getBuildServers();
        if (configData) {
            configData = JSON.parse(configData);
            setConfig({type: "SET_UNITY_BRANCH", payload: data.data.branches.unity});
            setConfig({type: "SET_WEBGL_BRANCH", payload: data.data.branches.webgl});
            setConfig({type: "SET_CONFIG_NAME", payload: configName});
            setConfig({type: "SET_BUILD_SERVER", payload: buildServer.data});
            try {
                setConfig({type: "SET_DEFAULT_CONFIG_DATA", payload: configData});
            } catch (e) {
                console.log(e);
            }
            setDefaultConfigData({
                ...defaultConfigData,
                buildServer: configData.buildServer,
                unityBranch: configData.unityBranch,
                webglBranch: configData.webglBranch,
                il2CppType: configData.il2CppType,
                buildConfig: configData.buildConfig,
                logLevel: configData.logLevel,
                developDomains: configData.developDomains,
                productionDomains: configData.productionDomains,
                excludePackages: configData.excludePackages,
                cdnLink: configData.cdnLink,
                needManualTesting: configData.needManualTesting,
                readyForProduction: configData.readyForProduction,
                needOnlyStableStaging: configData.needOnlyStableStaging,
                isIncrementalBuild: configData.isIncrementalBuild,
                isDefaultBuild: (configData.isDefaultBuild ? configData.isDefaultBuild : false)
            });
            setNameInput(configName);
        }
        setConfigId(configId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenForDelete(false);
        setAddConfigOpen(false);
    };

    const editConfig = async () => {
        let body = {
            configId: configId,
            configName: nameInput,
            configData: JSON.stringify(defaultConfigData)
        };
        await API.Configs.ConfigDataUpdate(configId, body);
        setOpen(false);
        const table = await API.Configs.getConfigs(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setConfigId(id);
        setOpenForDelete(true);
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setDefaultConfigData({
                ...defaultConfigData,
                developDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setDefaultConfigData({
                ...defaultConfigData,
                developDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        }
    };

    const selectedProductionDomains = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setDefaultConfigData({
                ...defaultConfigData,
                productionDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setDefaultConfigData({
                ...defaultConfigData,
                productionDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        }
    };

    const excludePackagesChange = (selected) => {
        let excludePackages = [];
        if (selected && selected.length > 0) {
            excludePackages = selected?.map(excludePackages => (excludePackages.excludePackageName));
            setDefaultConfigData({
                ...defaultConfigData,
                excludePackages: excludePackages
            });
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        } else if (selected && selected.length === 0) {
            setDefaultConfigData({
                ...defaultConfigData,
                excludePackages: excludePackages
            });
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        }
    };

    const deleteConfig = async () => {
        const totalAfterDelete = await API.Configs.configDelete(configId);
        setOpenForDelete(false);
        let currentPage = page;
        if (totalAfterDelete.data.total % size === 0) {
            currentPage = page - 1;
        }
        const table = await API.Configs.getConfigs(currentPage === 0 ? '' : currentPage, size === 0 ? '' : size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const addConfigOnClicked = async (e) => {
        e.preventDefault();
        const data = await API.Configs.getConfigsInit();
        const buildServer = await API.BuildServers.getBuildServers();
        setConfig({type: "SET_UNITY_BRANCH", payload: data.data.branches.unity});
        setConfig({type: "SET_WEBGL_BRANCH", payload: data.data.branches.webgl});
        setConfig({type: "SET_BUILD_SERVER", payload: buildServer.data});
        setAddConfigOpen(true);
    };

    const addConfig = async () => {
        let body = {
            configName: nameInput,
            configData: JSON.stringify(configDataInput)
        };
        await API.Configs.addConfig(body);
        setAddConfigOpen(false);
        const table = await API.Configs.getConfigs(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: [...table.data]});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
        setNeedReRender(true);
    };

    const updateBranches = async (e) => {
        e.preventDefault();
        await API.BuildRequests.updateBranches();
    };

    useEffect(() => {
        (async () => {
            if (Object.keys(buildServer).length > 0) return;
            const buildServersData = await API.BuildServers.getBuildServers();
            let result = {};
            buildServersData.data.map((buildServerData) => (
                result[buildServerData.id] = buildServerData.serverName
            ));
            setBuildServer(result);
        })()
    }, [needReRender]);

    const Parsing = useCallback(({data}) => {
        try {
            if (data.configData === undefined || data.configData === '' || data.configData === {} || data.configData === []) {
                return (
                    <div className="tableCellContainer">
                        <div className="left-column" style={{minWidth: '177px'}}>
                            <div>
                                <span>Ready For Production: false</span>
                            </div>
                            <div>
                                <span>Is Incremental Build: false</span>
                            </div>
                            <div>
                                <span>Is Default Build: false</span>
                            </div>
                        </div>
                        <div className="right-column" style={{paddingLeft: '200px'}}>
                            <div>
                                <span>Build Server: </span>
                            </div>
                            <div>
                                <span>Develop Domains: none</span>
                            </div>
                            <div>
                                <span>Production Domains: none</span>
                            </div>
                        </div>
                    </div>
                )
            }
            let dataParse = JSON.parse(data.configData);
            if (data.configId === 1) console.warn(buildServer, dataParse.buildServer);
            return (
                <div className="tableCellContainer">
                    <div className="left-column" style={{minWidth: '177px'}}>
                        <div>
                            <span>Ready For Production: {!dataParse.readyForProduction ? "false" : dataParse.readyForProduction + ""}</span>
                        </div>
                        <div>
                            <span>Is Incremental Build: {!dataParse.isIncrementalBuild ? "false" : dataParse.isIncrementalBuild + ""}</span>
                        </div>
                        <div>
                            <span>Is Default Build: {!dataParse.isDefaultBuild ? "false" : dataParse.isDefaultBuild + ""}</span>
                        </div>
                    </div>
                    <div className="right-column" style={{paddingLeft: '200px'}}>
                        <div>
                            <span>Build Server: {
                                buildServer[dataParse.buildServer] || dataParse.buildServer
                            }</span>
                        </div>
                        <div>
                            <span>Develop Domains: {dataParse.developDomains === undefined ? "none" : dataParse.developDomains?.length <= 0 ? "none" : Utils.limitStringLength(
                                dataParse.developDomains?.join(', '), 50)}</span>
                        </div>
                        <div>
                            <span>Production Domains: {dataParse.productionDomains === undefined ? "none" : dataParse.productionDomains?.length <= 0 ? "none" : Utils.limitStringLength(dataParse.productionDomains?.join(', '), 50)}</span>
                        </div>
                    </div>
                </div>
            )
        } catch (e) {
            console.warn(e);
        }
    }, [buildServer]);

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{marginLeft: 21}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(3)
        },
        {
            name: 'configName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Name</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    {
                        userState.permissions?.includes(Permission.ViewConfigsInfo) || userState.permissions?.includes(Permission.ViewOwnConfigsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewConfigsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnConfigsInfo)
                            ? <Link to={'/config/' + rowData.id}>
                                <div className="fileName" style={{textAlign: 'left!important'}}>
                                    <span style={{textDecoration: 'underline'}}>{rowData.configName}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{textAlign: 'left!important'}}>
                                <span>{rowData.configName}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'configData',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Config</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <Parsing disableEnforceFocus data={rowData}/>
                </div>
            ),
            minWidth: Utils.RemToPx(41.438),
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex align-items-center justify-content-center">
                    {
                        userState.permissions?.includes(Permission.EditConfigs) ||
                        userState.permissionsAssigned?.includes(Permission.EditConfigs)
                            ? <StyledButton
                                onClick={e => onClicked(e, rowData.id, rowData.configName, rowData.configData)}
                                tooltip={"Edit config"}
                                icon="fas fa-edit"
                                type="danger"
                                variant="contained" style={{
                                height: 32,
                                width: 40,
                                background: '#343a40',
                                color: '#c2c7d0',
                                border: 'none',
                                borderRadius: 3,
                                cursor: 'pointer',
                            }}
                            />
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.DeleteConfigs) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteConfigs)
                            ?
                            <StyledButton onClick={e => deleteOnClicked(e, rowData.id)} icon="fas fa-solid fa-trash-can"
                                          tooltip={"Delete config"}
                                          type="danger"
                                          variant="contained" style={{
                                height: 32,
                                width: 40,
                                background: '#343a40',
                                color: '#c2c7d0',
                                border: 'none',
                                borderRadius: 3,
                                cursor: 'pointer',
                                marginLeft: 3
                            }}
                            />
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(20)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Configs.getConfigs(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        setPage(page);
        setSize(size);
        dispatch({type: "SET_LIST", payload: [...table.data]});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
        const total = table.totalCount;

        return total ?? 0;
    }, [state.total, state.list]);


    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div className="snapShotsTable">
                <div style={{float: 'none'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="route">Configs</span>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="tableTitle d-flex justify-content-between">
                    <h3 className="configTableTitle">ACP : Configs</h3>
                    <div className="buttonsContainer">
                        <StyledButton onClick={e => updateBranches(e)}
                                      titleClassname="syncWithGit"
                                      icon="fas fa-sync"
                                      title="Sync with git hub" type="danger"
                                      variant="contained" style={{
                            padding: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            background: '#343a40',
                            color: '#c2c7d0',
                            border: 'none',
                            borderRadius: 3,
                            cursor: 'pointer',
                            float: 'left'
                        }}
                        />
                        {
                            userState.permissions?.includes(Permission.AddConfigs) ||
                            userState.permissionsAssigned?.includes(Permission.AddConfigs)
                                ? <StyledButton onClick={e => addConfigOnClicked(e)}
                                                titleClassname="addItemButton"
                                                icon="fas fa-plus"
                                                title="Add Config" type="danger"
                                                variant="contained" style={{
                                    padding: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    background: '#343a40',
                                    color: '#c2c7d0',
                                    border: 'none',
                                    borderRadius: 3,
                                    cursor: 'pointer',
                                    float: 'left',
                                    marginLeft: 10
                                }}
                                />
                                : null
                        }
                    </div>
                </div>
                <section className="content">
                    <div id="table-container">
                        <CustomTableConfigs state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true,
                            reRender: needReRender
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                            texts={{noItems: "No configs..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={open} onClose={handleClose} sx={{width: 600}} disableEnforceFocus>
                <DialogTitle>
                    Edit deploy config: {config.configName}
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Config Name:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Config Name"
                                id="name"
                                type="text"
                                value={nameInput}
                                fullWidth
                                variant="standard"
                                onChange={(event => setNameInput(
                                    event.target.value
                                ))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">2) Build Server:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                value={defaultConfigData.buildServer}
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    buildServer: parseInt(event.target.value)
                                }))}
                            >
                                {config.buildServer.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.id}>
                                        {option.serverName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">3) Unity Branch:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.unityBranch}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    unityBranch: event.target.value
                                }))}
                            >
                                {config.unity.map((option, i) => (
                                    <MenuItem key={`row-unity-branch-${i}`} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">4) WebGL Branch:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.webglBranch}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    webglBranch: event.target.value
                                }))}
                            >
                                {config.webgl.map((option, i) => (
                                    <MenuItem key={`row-webgl-branch-${i}`} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">5) Il2Cpp Type:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.il2CppType}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    il2CppType: event.target.value
                                }))}
                            >
                                {config.il2CppType.map((option, i) => (
                                    <MenuItem key={`row-webgl-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">6) Build Config:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.buildConfig}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    buildConfig: event.target.value
                                }))}
                            >
                                {config.buildConfig.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">7) Log Level:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.logLevel}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    logLevel: event.target.value
                                }))}
                            >
                                {config.logLevel.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">8) Develop Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={defaultConfigData.developDomains?.map(domainName => ({domainName}))}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={handleChange}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                                validationKey="domains"
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">9) Production Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={defaultConfigData.productionDomains?.map(domainName => ({domainName}))}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={(value => selectedProductionDomains(value))}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                                validationKey="domains"
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">10) Exclude Packages:</label></div>
                        <div className="col-11 align-items-center">
                                <StyledCreatableAsyncSelect
                                    isMulti={true}
                                    defaultValue={defaultConfigData.excludePackages?.map((excludePackage) => ({
                                        excludePackageName: excludePackage,
                                        excludePackageId: excludePackage
                                    }))}
                                    getOptionLabel={(e) => e.excludePackageName}
                                    getOptionValue={(e) => e.excludePackageId}
                                    onChange={excludePackagesChange}
                                    className={"styledAsyncSelect"}
                                    isInputValueCorrect={true}
                                    keyValue='excludePackages'
                                />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">11) CDN Link:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                type="text"
                                value={defaultConfigData.cdnLink}
                                multiline
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    cdnLink: event.target.value
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">12) Need manual testing:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.needManualTesting || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    needManualTesting: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">13) Ready for production:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.readyForProduction || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    readyForProduction: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">14) Need only stable staging:</label>
                        </div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.needOnlyStableStaging || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    needOnlyStableStaging: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">15) Is incremental build:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.isIncrementalBuild || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    isIncrementalBuild: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">16) Is default build:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.isDefaultBuild || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    isDefaultBuild: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={editConfig} title="Edit config" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={addConfigOpen} onClose={handleClose} sx={{width: 600}} disableEnforceFocus>
                <DialogTitle>
                    Add deploy config
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Config Name:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Config Name"
                                id="name"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setNameInput(event.target.value))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">2) Build Server:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                defaultValue={''}
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    buildServer: event.target.value
                                }))}
                            >
                                {config.buildServer.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.id}>
                                        {option.serverName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">3) Unity Branch:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                defaultValue={''}
                                fullWidth
                                variant="standard"
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    unityBranch: event.target.value
                                }))}
                            >
                                {config.unity.map((option, i) => (
                                    <MenuItem key={`row-unity-branch-${i}`} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">4) WebGL Branch:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                defaultValue={''}
                                fullWidth
                                variant="standard"
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    webglBranch: event.target.value
                                }))}
                            >
                                {config.webgl.map((option, i) => (
                                    <MenuItem key={`row-webgl-branch-${i}`} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">5) Il2Cpp Type:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                defaultValue={''}
                                fullWidth
                                variant="standard"
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    il2CppType: event.target.value
                                }))}
                            >
                                {config.il2CppType.map((option, i) => (
                                    <MenuItem key={`row-webgl-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">6) Build Config:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                defaultValue={''}
                                fullWidth
                                variant="standard"
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    buildConfig: event.target.value
                                }))}
                            >
                                {config.buildConfig.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">7) Log Level:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                defaultValue={''}
                                fullWidth
                                variant="standard"
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    logLevel: event.target.value
                                }))}
                            >
                                {config.logLevel.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">8) Develop Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={handleChange}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">9) Production Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={selectedProductionDomains}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">10) Exclude Packages:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledCreatableAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.excludePackageName}
                                getOptionValue={(e) => e.excludePackageId}
                                onChange={excludePackagesChange}
                                className={"styledAsyncSelect"}
                                isInputValueCorrect={true}
                                keyValue='excludePackages'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">11) CDN Link:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                type="text"
                                multiline
                                fullWidth
                                variant="standard"
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    cdnLink: event.target.value
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">12) Need manual testing:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={configDataInput?.needManualTesting}
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    needManualTesting: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">13) Ready for production:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={configDataInput?.readyForProduction}
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    readyForProduction: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">14) Need only stable staging:</label>
                        </div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={configDataInput?.needOnlyStableStaging}
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    needOnlyStableStaging: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">15) Is incremental build:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={configDataInput?.isIncrementalBuild}
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    isIncrementalBuild: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">16) Is default build:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={configDataInput?.isDefaultBuild}
                                onChange={(event => setConfigDataInput({
                                    ...configDataInput,
                                    isDefaultBuild: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={addConfig} title="Add config" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                    <StyledButton onClick={() => setAddConfigOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to delete the config?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteConfig} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfigsTable;