import React from 'react';
import RBAC from "./components/RBAC";
import UserInfo from "./components/UserInfo";
import {useParams} from "react-router";
import {Link, useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

const UsersRbac = () => {
    const {userId} = useParams();
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);

    const LinkRouter = (props) => {
        return (
            <div className="routeContainer">
                <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                    <Link to="/" className="black routes">
                        <span>Dashboard</span>
                    </Link>
                    <Link to="/users" className="black routes">
                        <span>Users</span>
                    </Link>
                    {pathNames.map((value, index) => {
                        const last = index === pathNames.length - 1;
                        const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                        if (last) {
                            return (
                                <Typography color="textPrimary" key={to} className="lastItem">
                                    {'User : ' + value}
                                </Typography>
                            )
                        }
                    })}
                </Breadcrumbs>
            </div>
        );
    };

    return (
        <>
            <div className="contentWrapper">
                <LinkRouter/>
                <UserInfo id={userId}/>
                <RBAC id={userId}/>
            </div>
        </>
    );
};

export default UsersRbac;