import React from "react";

// MUI
import '../styles/All.css'
import CircularProgress from "@material-ui/core/CircularProgress";

export const StyledButton = props => {
    const {
        title, type, size, outline = false, fullWidth, disabled, onClick, style, noLoading, id, className, elRef, tooltip = null,
        isLink = false, href = "", icon, titleClassname, disabledButton
    } = props;

    const fullWidthClass = fullWidth ? "btn-block" : "";
    const sizeClass = size ? `btn-${size}` : "";
    const disabledClass = disabled || disabledButton ? "disabled" : "";
    const disabledButtonClass = disabledButton ? "disabled" : "";

    const classes = `btn ${fullWidthClass} ${outline ? `btn-outline-${type}` : `btn-${type}`} ${sizeClass} ${disabledButtonClass} d-flex justify-content-center align-items-center`;

    return (
        isLink
            ? (
                <a href={href} target="_blank" rel="noreferrer" onClick={disabled || disabledButton ? () => false : onClick} {...(style ? {style} : {})}
                   ref={elRef ?? null} id={id} title={tooltip}
                   className={className ?? classes}>{title}</a>
            )
            : (
                <button id={id} type="button" onClick={disabled || disabledButton ? () => false : onClick} {...(style ? {style} : {})}
                        ref={elRef ?? null} className={className ?? classes} title={tooltip}>
                    <i className={`nav-icon ${icon}`} style={{fontSize: "1.1rem"}}/>
                    <span className={titleClassname}>{title}</span>
                    {disabled && !noLoading ?
                        <CircularProgress size={18} className="ml-3" style={{color: "white"}}/> : null}
                </button>
            )
    );
};
