import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import {Link} from "react-router-dom";
import Utils from "../../../Auth/Utils";
import {API} from "../../../api/API";
import CustomTable from "../../customTables/CustomTable";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {StyledButton} from "../../../customstyled/StyledButton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Permission} from "../../../rbac/Permissions";
import {UsersStore} from "../../../stores/UsersStore";

const ReportsTable = () => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [reportId, setReportId] = useState('');
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setReportId(id);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForDelete(false);
    };

    const deleteReport = async () => {
        const totalAfterDelete = await API.Reports.ReportDelete(reportId);
        setOpenForDelete(false);
        let currentPage = page;
        if (totalAfterDelete.total % size === 0) {
            currentPage = page - 1;
        }
        const table = await API.Reports.getReports(currentPage === 0 ? '' : currentPage, size === 0 ? '' : size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const unicodeToChar = (text) => {
        return text.replace(/\\u([\d\w]{5})/gi, match => {
            return String.fromCodePoint(parseInt(match.replace(/\\u/gi, ''), 16));
        });
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewReportsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewReportsInfo)
                            ? <Link to={'/report/' + rowData.id}>
                                <div>
                                    <span style={{textDecoration: 'underline', color: "black"}}>{'#' + rowData.id}</span>
                                </div>
                            </Link>
                            : <div>
                                <span style={{textDecoration: 'underline', color: "black"}}>{'#' + rowData.id}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(4)
        },
        {
            name: 'created',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Created At</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{new Date(rowData.created).toLocaleString()}</span>
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'data',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Data</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.data === null ? 'no data' : rowData.data}</span>
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            name: 'message',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Message</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <span>{unicodeToChar(rowData.message)}</span>
                </div>
            ),
            width: Utils.RemToPx(58)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    {
                        userState.permissions?.includes(Permission.DeleteReports) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteReports)
                            ?
                            <StyledButton onClick={e => deleteOnClicked(e, rowData.id)} icon="fas fa-solid fa-trash-can"
                                          type="danger"
                                          tooltip={"Delete report"}
                                          variant="contained"
                                          style={{
                                              height: 25,
                                              width: 28,
                                              background: '#343a40',
                                              color: '#c2c7d0',
                                              border: 'none',
                                              borderRadius: 3,
                                              cursor: 'pointer',
                                          }}/>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(8)
        },
        {
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                </span>
            ),
            // width: Utils.RemToPx(13)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Reports.getReports(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        setPage(page);
        setSize(size);
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div className="snapShotsTable">
                <div style={{float: 'none', marginBottom: '10px'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="route">Reports</span>
                        </Breadcrumbs>
                    </div>
                </div>
                <h3 className="tableTitle marginZero">ACP : Reports</h3>
                <section className="content">
                    <div id="table-container">
                        <CustomTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                     texts={{noItems: "No reports..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to delete the report?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteReport} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ReportsTable;