import React from 'react';
import {styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const RbacInput = (props) => {

    return (
        <StyledInputBase
            placeholder="Search…"
            inputProps={{'aria-label': 'search'}}
            value={props.value}
            onChange={props.onChange}
        />
    );
};

export default RbacInput;