import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {API} from "../../../../../api/API";
import {Permission} from "../../../../../rbac/Permissions";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {UsersStore} from "../../../../../stores/UsersStore";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";

export const DomainInfo = (props) => {
    const {state: userState} = useContext(UsersStore);
    const [domainInfo, setDomainInfo] = useState({});
    const [openForUnmount, setOpenForUnmount] = useState(false);
    const [openForDelete, setOpenForDelete] = useState(false);
    const type = [
        'Static',
        'Dynamic'
    ];

    useEffect(() => {
        (async () => {
            const table = await API.Hosts.getDomainInfo(props.hostId, props.domainId);
            setDomainInfo({
                ...domainInfo,
                id: table.data.id,
                domainName: table.data.domainName,
                hostId: table.data.hostId,
                hostName: table.data.hostName,
                type: table.data.type,
                snapshotFileName: table.data.snapshotFileName,
                configName: table.data.configName,
                configId: table.data.configId
            });
        })();
    }, []);

    const unmountOnClicked = (e, id) => {
        e.preventDefault();
        setOpenForUnmount(true);
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForUnmount(false);
        setOpenForDelete(false);
    };

    const unmountDomain = async () => {
        await API.SnapShots.unmountDomain(domainInfo.id, domainInfo.hostId);
        setOpenForUnmount(false);
    };

    const deleteDomain = async () => {
        await API.SnapShots.deleteDomain(domainInfo.id, domainInfo.hostId);
        setOpenForDelete(false);
    };

    return (
        <>
            <div className="targetItemInfo">
                <div className="col-1 margin-left-20px"><label className="col-form-label">#</label></div>
                <div className="col-11 d-flex align-items-center">
                    <span className="pl-2">{domainInfo.id}</span>
                </div>
            </div>
            <div className="targetItemInfo">
                <div className="col-1 margin-left-20px"><label className="col-form-label">Domain</label></div>
                <div className="col-11 d-flex align-items-center">
                    <span className="pl-2">{domainInfo.domainName}</span>
                </div>
            </div>
            <div className="targetItemInfo">
                <div className="col-1 margin-left-20px"><label className="col-form-label">Host</label></div>
                <div className="col-11 d-flex align-items-center">
                    <Link to={"/host/" + domainInfo.hostId}
                          style={{textDecoration: 'underline', color: "black"}}>
                        <span className="pl-2">{domainInfo.hostName}</span>
                    </Link>
                </div>
            </div>
            <div className="targetItemInfo">
                <div className="col-1 margin-left-20px"><label className="col-form-label">Config</label></div>
                <div className="col-11 d-flex align-items-center">
                    <Link to={"/config/" + domainInfo.configId}
                          style={{textDecoration: 'underline', color: "black"}}>
                        <span className="pl-2">{domainInfo.configName}</span>
                    </Link>
                </div>
            </div>
            <div className="targetItemInfo">
                <div className="col-1 margin-left-20px"><label className="col-form-label">Snapshot</label></div>
                <div className="col-11 d-flex align-items-center">
                    <Link to={"/snapshot/" + domainInfo.snapshotFileName}
                          style={{textDecoration: 'underline', color: "black"}}>
                        <span className="pl-2">{domainInfo.snapshotFileName}</span>
                    </Link>
                </div>
            </div>
            <div className="targetItemInfo">
                <div className="col-1 margin-left-20px"><label className="col-form-label">Type</label></div>
                <div className="col-11 d-flex align-items-center">
                    <span className="pl-2">{type[domainInfo.type]}</span>
                </div>
            </div>
            <div className="targetItemInfo">
                <div className="col-1 margin-left-20px"><label className="col-form-label">Actions</label></div>
                <div className="col-11 d-flex align-items-center">
                    {
                        userState.permissions?.includes(Permission.UnmountDomains) || userState.permissions?.includes(Permission.UnmountOwnDomains) ||
                        userState.permissionsAssigned?.includes(Permission.UnmountDomains) || userState.permissionsAssigned?.includes(Permission.UnmountOwnDomains)
                            ? <StyledButton onClick={e => unmountOnClicked(e, domainInfo.id)}
                                            icon="fas fa-eraser" type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.DeleteDomains) || userState.permissions?.includes(Permission.DeleteOwnDomains) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteDomains) || userState.permissionsAssigned?.includes(Permission.DeleteOwnDomains)
                            ? <StyledButton onClick={e => deleteOnClicked(e, domainInfo.id)}
                                            icon="fas fa-solid fa-trash-can" type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 4
                                            }}/>
                            : null
                    }
                </div>
            </div>
            <Dialog open={openForUnmount} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Are you sure you want to unmount the domain?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={unmountDomain} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForUnmount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Are you sure you want to delete the domain?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteDomain} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    )
};


