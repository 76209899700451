import React, {useCallback, useEffect, useState} from 'react';
import AsyncCreatableSelect from "react-select/async-creatable";
import makeAnimated from "react-select/animated";

import Utils from "../../Auth/Utils";
import AsyncSelect from "react-select/async";

// In parent component
// const [selected, setSelected] = useState("");
// ...
// <AsyncSearchBar setSelected={setSelected} />

export const StyledCreatableAsyncSelect = props => {

    const {
        getOptionLabel, getOptionValue, isMulti = false, fetchMethod, customStyles,
        onChange: onChangeCallback, className, keyValue, isInputValueCorrect
    } = props;

    const [defaultValue, setDefaultValue] = useState(props.defaultValue?.length > 0 ? props.defaultValue : []);
    const [value, setValue] = useState(props.defaultValue?.length > 0 ? props.defaultValue : []);
    const [query, setQuery] = useState("");

    const onChange = (e) => {
        let newValue;
        if (isMulti) {
            switch (keyValue) {
                case 'excludePackages':
                    newValue = [...Object.assign([], e)].filter((v, i, a) => a.findIndex(v2 => (v2.excludePackageName === v.excludePackageId)) === i);
                    break;
                case 'emailName':
                    newValue = [...Object.assign([], e)].filter((v, i, a) => a.findIndex(v2 => (v2.emailName === v.emailId)) === i);
                    break;
                default:
                newValue = [...Object.assign([], e)].filter((v, i, a) => a.findIndex(v2 => (v2[keyValue] === v[keyValue])) === i);
            }
        }
        if (!isMulti) newValue = e;
        setValue(newValue);
        onChangeCallback(newValue);
    };

    useEffect(() => {
        if (props.defaultValue && props.defaultValue instanceof Array
            && (defaultValue?.length !== props.defaultValue?.length || !Utils.isEquivalent(defaultValue, props.defaultValue || []))
        ) {
            setDefaultValue([...props.defaultValue]);
            setValue([...props.defaultValue]);
            return;
        }
        if (props.defaultValue?.length > 0 && keyValue === 'excludePackages') {
            setValue([...value, ...props.defaultValue].filter((v, i, a) => a.findIndex(v2 => (v2.excludePackageName === v.excludePackageId)) === i));
        } else if (props.defaultValue?.length > 0) {
            setValue([...value, ...props.defaultValue].filter((v, i, a) => a.findIndex(v2 => (v2[keyValue] === v[keyValue])) === i));
        }
    }, [props.defaultValue]);

    //get animated components wrapper
    const animatedComponents = makeAnimated();

    const styles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
        }),
        menu: (provided, state) => ({
            ...provided,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            border: isInputValueCorrect ? 'none' : '1px solid #ff2635',
            borderRight: 'none',
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            border: isInputValueCorrect ? 'none' : '1px solid #ff2635',
            borderRight: 'none',
            borderLeft: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            border: isInputValueCorrect ? 'none' : '1px solid #ff2635',
            borderLeft: 'none',
        }),
    };

    const loadOptions = useCallback(async (query) => {
        const response = await fetchMethod(query !== '' ? query : '');
        if (query) {
            switch (keyValue) {
                case 'emailName': response.data.push({emailName: query, emailId: query});
                break;
                case 'excludePackages':response.data.push({excludePackageName: query, excludePackageId: query});
                break;
            }
        }

        return new Promise((resolve => resolve(response ? response.data : [])));
    }, [query]);

    const createOptions = useCallback(async (query) => {
        const response = [];
        if (query) {
            switch (keyValue) {
                case 'emailName': response.push({emailName: query, emailId: query});
                    break;
                case 'excludePackages':response.push({excludePackageName: query, excludePackageId: query});
                    break;
            }
        }

        return new Promise((resolve => resolve(response ? response : [])));
    }, [query]);

    const onQuery = (value) => {

        setQuery(value);
    };

    return (
        <AsyncCreatableSelect
            isMulti={isMulti}
            value={value}
            components={animatedComponents}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            loadOptions={fetchMethod ? loadOptions : createOptions}
            onInputChange={(value) => onQuery(value)}
            onChange={onChange}
            classNamePrefix={className}
            styles={customStyles ? customStyles : styles}
            openMenuOnClick={true}
            isClearable
        />
    );
};
