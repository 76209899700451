import React, {useContext, useEffect, useState} from 'react';
import {API} from "../../../../../api/API";
import "../../../../../styles/All.css"
import {Link} from "react-router-dom";
import {Permission} from "../../../../../rbac/Permissions";
import {UsersStore} from "../../../../../stores/UsersStore";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {StyledAsyncSelect} from "../../../../../customstyled/AsyncSelect/StyledAsyncSelect";
import {TableStore} from "../../../../../stores/TableStore";

export const SnapshotFileNameInfo = (props) => {
    const [fileNameInfo, setFileNameInfo] = useState({});
    const {state: userState} = useContext(UsersStore);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [snapshotFileName, setSnapshotFileName] = useState('');
    const [openForUnmount, setOpenForUnmount] = useState(false);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [reRender, setReRender] = useState(false);
    const status = [
        'Deployed',
        'ReDeployed',
        'Removed',
        'UnPacked',
        'Patched',
        'Mounted',
        'UnMounted',
        'Custom',
        'Removing'
    ];

    useEffect( () => {
        (async () => {
            const table = await API.SnapShots.getSnapshotInfo(props.fileName);
            setFileNameInfo({
                ...fileNameInfo,
                id: table.data.id,
                fileName: table.data.fileName,
                config: table.data.deployConfig.configName,
                domains: table.data.domainsCount,
                status: table.data.status,
                created: table.data.created,
                updated: table.data.updated,
                history: table.data.historyCount,
                configId: table.data.deployConfig.id
            });
        })();
    }, [reRender]);

    const onClicked = (e, fileName) => {
        e.preventDefault();
        setOpen(true);
        setSnapshotFileName(fileName)
    };

    const unmountOnClicked = (e, fileName) => {
        e.preventDefault();
        setSnapshotFileName(fileName);
        setOpenForUnmount(true);
    };

    const deleteOnClicked = (e, fileName) => {
        e.preventDefault();
        setSnapshotFileName(fileName);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenForUnmount(false);
        setOpenForDelete(false);
    };

    const mount = async () => {
        await API.SnapShots.snapshotMount(snapshotFileName, value);
        setOpen(false);
        setValue('');
    };

    const unmount = async () => {
        await API.SnapShots.snapshotUnmount(snapshotFileName);
        setOpenForUnmount(false);
    };

    const deleteSnapshot = async () => {
        await API.SnapShots.snapshotDelete(snapshotFileName);
        setOpenForDelete(false);
        setReRender(!reRender);
    };

    const downloadOnClicked = async (e, fileName) => {
        e.preventDefault();
        await API.SnapShots.downloadSnapshot(fileName);
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setValue({domains: domainNames.join(',')});
        } else if (selected && selected.length === 0) {
            setValue(domainNames);
        }
    };

    const FileNameInfo = () => {
        return (
            <>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">#</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{fileNameInfo.id}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Filename</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{fileNameInfo.fileName}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Config</label></div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.ViewSnapshotsInfo) || userState.permissions?.includes(Permission.ViewOwnSnapshotsInfo) ||
                            userState.permissionsAssigned?.includes(Permission.ViewSnapshotsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshotsInfo)
                            ? <Link to={"/config/" + fileNameInfo.configId} style={{textDecoration: 'underline', color: "black"}}>
                                    <span className="pl-2">{fileNameInfo.config}</span>
                                </Link>
                                : <span className="pl-2">{fileNameInfo.config}</span>
                        }
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Domains</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{fileNameInfo.domains}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Status</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{status[fileNameInfo.status]}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Created</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(fileNameInfo.created).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Updated</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(fileNameInfo.updated).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">History</label></div>
                    <div className="col-11 d-flex align-items-center">
                            <span className="pl-2">
                                {fileNameInfo.history}
                            </span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Actions</label></div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.MountSnapshots) || userState.permissions?.includes(Permission.MountOwnSnapshots) ||
                            userState.permissionsAssigned?.includes(Permission.MountSnapshots) || userState.permissionsAssigned?.includes(Permission.MountOwnSnapshots)
                                ? <StyledButton onClick={e => onClicked(e, fileNameInfo.fileName)}
                                                icon="fas fa-arrow-alt-circle-down"
                                                // titleClassname="addItemButton"
                                                // title={"Mount snapshot to domains"}
                                                tooltip={"Mount snapshot to domains"}
                                                type="danger"
                                                variant="contained"
                                                style={{
                                                    padding: 5,
                                                    background: '#343a40',
                                                    color: '#c2c7d0',
                                                    border: 'none',
                                                    borderRadius: 3,
                                                    cursor: 'pointer',
                                                }}/>
                                : null
                        }
                        {
                            userState.permissions?.includes(Permission.UnmountSnapshots) || userState.permissions?.includes(Permission.UnmountOwnSnapshots) ||
                            userState.permissionsAssigned?.includes(Permission.UnmountSnapshots) || userState.permissionsAssigned?.includes(Permission.UnmountOwnSnapshots)
                                ? <StyledButton onClick={e => unmountOnClicked(e, fileNameInfo.fileName)}
                                                icon="fas fa-eraser"
                                                // titleClassname="addItemButton"
                                                // title={"Unmount all domains with this snapshot"}
                                                tooltip={"Unmount all domains with this snapshot"}
                                                type="danger"
                                                variant="contained"
                                                style={{
                                                    padding: 5,
                                                    background: '#343a40',
                                                    color: '#c2c7d0',
                                                    border: 'none',
                                                    borderRadius: 3,
                                                    cursor: 'pointer',
                                                    marginLeft: 3
                                                }}/>
                                : null
                        }
                        {
                            userState.permissions?.includes(Permission.DownloadSnapshots) || userState.permissions?.includes(Permission.DownloadOwnSnapshots) ||
                            userState.permissionsAssigned?.includes(Permission.DownloadSnapshots) || userState.permissionsAssigned?.includes(Permission.DownloadOwnSnapshots)
                                ? <StyledButton onClick={e => downloadOnClicked(e, fileNameInfo.fileName)}
                                                icon="fas fa-download"
                                                // titleClassname="addItemButton"
                                                // title={"Download snapshot"}
                                                tooltip={"Download snapshot"}
                                                type="danger"
                                                variant="contained"
                                                style={{
                                                    padding: 5,
                                                    background: '#343a40',
                                                    color: '#c2c7d0',
                                                    border: 'none',
                                                    borderRadius: 3,
                                                    cursor: 'pointer',
                                                    marginLeft: 3
                                                }}/>
                                : null
                        }
                        {
                            userState.permissions?.includes(Permission.RebuildSnapshots) || userState.permissions?.includes(Permission.RebuildOwnSnapshots) ||
                            userState.permissionsAssigned?.includes(Permission.RebuildSnapshots) || userState.permissionsAssigned?.includes(Permission.RebuildOwnSnapshots)
                                ? <StyledButton onClick={e => deleteOnClicked(e, fileNameInfo.fileName)}
                                                icon="fas fa-solid fa-trash-can"
                                                // titleClassname="addItemButton"
                                                // title={"Delete snapshot"}
                                                tooltip={"Delete snapshot"}
                                                type="danger"
                                                variant="contained"
                                                style={{
                                                    padding: 5,
                                                    background: '#343a40',
                                                    color: '#c2c7d0',
                                                    border: 'none',
                                                    borderRadius: 3,
                                                    cursor: 'pointer',
                                                    marginLeft: 3
                                                }}/>
                                : null
                        }
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
        <div className="w-100 d-flex flex-column pl-5">
            <FileNameInfo/>
        </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Mount snapshot to domains
                </DialogTitle>
                <DialogContent dividers style={{height: 400}}>
                    <div className="form-group w-100 d-flex mb-0 border-bottom-none align-items-center">
                        <div className="col-1"><label className="col-form-label">Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={handleChange}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={mount} title="Mount to this domains" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForUnmount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={unmount} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForUnmount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot & then delete it?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteSnapshot} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            </>
    );
};

