import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {API} from "../../../api/API";
import {Link, useLocation} from "react-router-dom";
import "../../../styles/All.css"
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {UsersStore} from "../../../stores/UsersStore";
import {Permission} from "../../../rbac/Permissions";
import {StyledButton} from "../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";

export const CurrentBuildRequestInfo = () => {
    const {id} = useParams();
    const {state: userState} = useContext(UsersStore);
    const [BuildRequestInfo, setBuildRequestInfo] = useState({});
    const [openForDevMount, setOpenForDevMount] = useState(false);
    const [buildRequestId, setBuildRequestId] = useState('');
    const [openForProdMount, setOpenForProdMount] = useState(false);
    const [openForDelete, setOpenForDelete] = useState(false);
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);
    const status = [
        'Processing',
        'WaitBuildServer',
        'Building',
        'BuildError',
        'SnapshotUploaded',
        'MountedForTesting',
        'MountedForProduction'
    ];
    const type = {
        20: 'BuildFailed',
        30: 'BuildSuccess'
    };

    useEffect(() => {
        (async () => {
            const table = await API.BuildRequests.getBuildRequestInfo(id);
            setBuildRequestInfo({
                ...BuildRequestInfo,
                id: table.data.id,
                snapshot: table.data.snapshotFileName,
                backupSnapshot: table.data.backupSnapshotFileName,
                buildServer: table.data.buildServerName,
                config: table.data.configName,
                domains: table.data.domainsCount,
                status: table.data.status,
                created: table.data.created,
                configId: table.data.configId,
                notes: table.data.notes,
                report: table.data.reportId,
                reportType: table.data.reportType,
                reportMessage: table.data.reportMessage
            });
        })();
    }, []);

    const unicodeToChar = (text) => {
        return text?.replace(/\\u([\d\w]{5})/gi, match => {
            return String.fromCodePoint(parseInt(match.replace(/\\u/gi, ''), 16));
        });
    };

    const onClicked = (e, id) => {
        e.preventDefault();
        setOpenForDevMount(true);
        setBuildRequestId(id)
    };

    const unmountOnClicked = (e, id) => {
        e.preventDefault();
        setBuildRequestId(id);
        setOpenForProdMount(true);
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setBuildRequestId(id);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForDevMount(false);
        setOpenForProdMount(false);
        setOpenForDelete(false);
    };

    const devMount = async () => {
        await API.BuildRequests.buildRequestDevMount(buildRequestId);
        setOpenForDevMount(false);
    };

    const prodMount = async () => {
        await API.BuildRequests.buildRequestProdMount(buildRequestId);
        setOpenForProdMount(false);
    };

    const deleteBuildRequest = async () => {
        await API.BuildRequests.buildRequestDelete(buildRequestId);
        setOpenForDelete(false);
    };

    const LinkRouter = (props) => {
        return (
            <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                <Link to="/" className="black routes">
                    <span>Dashboard</span>
                </Link>
                <Link to="/build-requests" className="black routes">
                    <span>Build Requests table</span>
                </Link>
                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                    if (last) {
                        return (
                            <Typography color="textPrimary" key={to} className="lastItem">
                                {'Build Request : ' + id}
                            </Typography>
                        )
                    }
                })}
            </Breadcrumbs>
        );
    };

    const BuildServerInfo = () => {
        return (
            <>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">#</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{BuildRequestInfo.id}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Config</label></div>
                    <div className="col-11 d-flex align-items-center black">
                        {
                            userState.permissions?.includes(Permission.ViewConfigs) ||
                            userState.permissionsAssigned?.includes(Permission.ViewConfigs)
                                ? <Link to={"/config/" + BuildRequestInfo.configId}
                                        style={{textDecoration: 'underline', color: 'black'}}>
                                    <span className="pl-2">{BuildRequestInfo.config}</span>
                                </Link>
                                : <span className="pl-2">{BuildRequestInfo.config}</span>
                        }
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Created At</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(BuildRequestInfo.created).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Build Server</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{BuildRequestInfo.buildServer}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Snapshot</label></div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.ViewSnapshots) || userState.permissions?.includes(Permission.ViewOwnSnapshots) ||
                            userState.permissionsAssigned?.includes(Permission.ViewSnapshots) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshots)
                                ? <Link to={'/snapshot/' + BuildRequestInfo.snapshot}
                                        style={{textDecoration: 'underline', color: 'black'}}>
                                    <div className="d-flex flex-column align-items-center black">
                                        <span className="pl-2">{BuildRequestInfo.snapshot}</span>
                                    </div>
                                </Link>
                                : <div className="d-flex flex-column align-items-center black">
                                    <span className="pl-2">{BuildRequestInfo.snapshot}</span>
                                </div>
                        }
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Backup Snapshot</label>
                    </div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.ViewSnapshotsInfo) || userState.permissions?.includes(Permission.ViewOwnSnapshotsInfo) ||
                            userState.permissionsAssigned?.includes(Permission.ViewSnapshotsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnSnapshotsInfo)
                                ? <Link to={'/snapshot/' + BuildRequestInfo.backupSnapshot}
                                        style={{textDecoration: 'underline', color: 'black'}}>
                                    <div className="d-flex flex-column align-items-center black">
                                        <span className="pl-2">{BuildRequestInfo.backupSnapshot}</span>
                                    </div>
                                </Link>
                                : <div className="d-flex flex-column align-items-center black">
                                    <span className="pl-2">{BuildRequestInfo.backupSnapshot}</span>
                                </div>
                        }
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Last status</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{status[BuildRequestInfo.status]}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Notes</label></div>
                    <div className="col-11 d-flex align-items-center">
                            <span className="pl-2">
                                {BuildRequestInfo.notes === null ? 'no notes' : BuildRequestInfo.notes}
                            </span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Report</label></div>
                    <div className="col-11 d-flex align-items-center">
                            <span className="pl-2">
                                {
                                    (BuildRequestInfo.report === null) ? (
                                        <div className="d-flex flex-column align-items-center black">
                                            <span>no report</span>
                                        </div>
                                    ) : (
                                        userState.permissions?.includes(Permission.ViewConfigs) ||
                                        userState.permissionsAssigned?.includes(Permission.ViewConfigs)
                                            ? <Link to={'/report/' + BuildRequestInfo.report}
                                                    style={{textDecoration: 'underline', color: 'black'}}>
                                                <div className="d-flex flex-column align-items-center black">
                                                    <span>{BuildRequestInfo.report}</span>
                                                </div>
                                            </Link>
                                            : <div className="d-flex flex-column align-items-center black">
                                                <span>{BuildRequestInfo.report}</span>
                                            </div>
                                    )
                                }
                            </span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Report Type</label></div>
                    <div className="col-11 d-flex align-items-center">
                            <span className="pl-2">
                                {type[BuildRequestInfo.reportType]}
                            </span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Report Message</label>
                    </div>
                    <div className="col-11 d-flex align-items-center">
                            <span className="pl-2">
                                {unicodeToChar(BuildRequestInfo.reportMessage)}
                            </span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Actions</label>
                    </div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.MountBuildRequestToDomains) || userState.permissions?.includes(Permission.MountOwnBuildRequestsToDomains) ||
                            userState.permissionsAssigned?.includes(Permission.MountBuildRequestToDomains) || userState.permissionsAssigned?.includes(Permission.MountOwnBuildRequestsToDomains)
                                ? <StyledButton onClick={e => onClicked(e, BuildRequestInfo.id)} type="danger"
                                                tooltip={"Mount for testing"}
                                                variant="contained"
                                                style={{
                                                    height: 25,
                                                    width: 28,
                                                    background: '#343a40',
                                                    color: '#c2c7d0',
                                                    border: 'none',
                                                    borderRadius: 3,
                                                    cursor: 'pointer',
                                                }}/>
                                : null
                        }
                        {
                            userState.permissions?.includes(Permission.MountBuildRequestToDomains) || userState.permissions?.includes(Permission.MountOwnBuildRequestsToDomains) ||
                            userState.permissionsAssigned?.includes(Permission.MountBuildRequestToDomains) || userState.permissionsAssigned?.includes(Permission.MountOwnBuildRequestsToDomains)
                                ? <StyledButton onClick={e => unmountOnClicked(e, BuildRequestInfo.id)}
                                                tooltip={"Mount for production"}
                                                type="danger"
                                                variant="contained"
                                                style={{
                                                    height: 25,
                                                    width: 28,
                                                    background: '#343a40',
                                                    color: '#c2c7d0',
                                                    border: 'none',
                                                    borderRadius: 3,
                                                    cursor: 'pointer',
                                                    marginLeft: 3
                                                }}/>
                                : null
                        }
                        {
                            userState.permissions?.includes(Permission.DeleteBuildRequest) || userState.permissions?.includes(Permission.DeleteOwnBuildRequest) ||
                            userState.permissionsAssigned?.includes(Permission.DeleteBuildRequest) || userState.permissionsAssigned?.includes(Permission.DeleteOwnBuildRequest)
                                ?
                                <StyledButton onClick={e => deleteOnClicked(e, BuildRequestInfo.id)}
                                              icon="fas fa-solid fa-trash-can"
                                              type="danger"
                                              tooltip={"Delete build request"}
                                              variant="contained"
                                              style={{
                                                  height: 25,
                                                  width: 28,
                                                  background: '#343a40',
                                                  color: '#c2c7d0',
                                                  border: 'none',
                                                  borderRadius: 3,
                                                  cursor: 'pointer',
                                                  marginLeft: 3
                                              }}/>
                                : null
                        }
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
            <div className="snapShotsTable">
                <LinkRouter/>
                <div style={{float: 'none', height: '22px', marginBottom: '10px'}}/>
                <h3 className="tableTitle fileNameInfo marginZero">
                    <span>ACP : </span>
                    <Link to={"/build-requests"} style={{textDecoration: "underline", color: "black"}}>
                        <span>Build Request</span>
                    </Link>
                    <span> : {id}</span>
                </h3>
                <div className="w-100 d-flex flex-column pl-5 buildRequest">
                    <BuildServerInfo/>
                </div>
            </div>
            <Dialog open={openForDevMount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to mount build to development domains
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={devMount} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDevMount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForProdMount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to mount build to production domains?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={prodMount} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForProdMount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount all domains with this snapshot & then delete it?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteBuildRequest} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};
