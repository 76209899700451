import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Link, useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {API} from "../../../../../api/API";
import "../../../../../styles/All.css"
import {DomainSnapshotInfo} from "./DomainSnapshotinfo";
import {DomainInfo} from "./DomainInfo";

export const CurrentDomainInfo = () => {
    const {hostId, domainId} = useParams();
    const [domainInfo, setDomainInfo] = useState({});
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);

    useEffect(() => {
        (async () => {
            const table = await API.Hosts.getDomainInfo(hostId, domainId);
            setDomainInfo({
                ...domainInfo,
                id: table.data.id,
                domainName: table.data.domainName,
                hostId: table.data.hostId,
                hostName: table.data.hostName,
                type: table.data.type,
                snapshotFileName: table.data.snapshotFileName,
                configName: table.data.configName,
                configId: table.data.configId
            });
        })();
    }, []);

    const LinkRouter = (props) => {
        return (
            <div>
                <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                    <Link to="/" className="black routes">
                        <span>Dashboard</span>
                    </Link>
                    <Link to="/hosts" className="black routes">
                        <span>Hosts</span>
                    </Link>
                    <Link to={"/host/" + hostId} className="black routes">
                        <span>Host : {domainInfo.hostName}</span>
                    </Link>
                    {pathNames.map((value, index) => {
                        const last = index === pathNames.length - 1;
                        const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                        if (last) {
                            return (
                                <Typography color="textPrimary" key={to} className="lastItem">
                                    {'Domain : ' + domainInfo.domainName}
                                </Typography>
                            )
                        }
                    })}
                </Breadcrumbs>
                <div style={{float: 'none', height: '22px', marginBottom: '10px'}}/>
            </div>
        );
    };

    const DomainSnapshotsInfo = () => {
        return (
            <>
                <h3 className="tableTitle fileNameInfo marginZero">
                    <span>Snapshots with configs : {domainInfo.configName}</span>
                </h3>
                <DomainSnapshotInfo configId={domainInfo.configId}/>
            </>
        );
    };

    return (
        <div className="snapShotsTable">
            <LinkRouter/>
            <h3 className="tableTitle fileNameInfo marginZero">
                <span>Domain : {domainInfo.domainName}</span>
            </h3>
            <div className="currentDomainInfo">
                <div className="w-100 d-flex flex-column pl-5">
                    <DomainInfo hostId={hostId} domainId={domainId}/>
                </div>
            </div>
            <DomainSnapshotsInfo/>
        </div>
    );
};
