import React, {useEffect, useState} from 'react';
import {API} from "../../api/API";
import {Link} from "react-router-dom";
import {StyledButton} from "../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {Role} from "../../rbac/Roles";
import TextField from "@mui/material/TextField";
import {StyledCreatableAsyncSelect} from "../../customstyled/CreatableAsyncSelect/StyledCreatableAsyncSelect";
import {StyledAsyncSelect} from "../../customstyled/AsyncSelect/StyledAsyncSelect";
import MenuItem from "@material-ui/core/MenuItem";

const RenderRoleName = roleId => Object.keys(Role)
    .find(key => Role[key] === roleId) || `UnknownRole#${roleId}`;

const UserInfo = (props) => {
    const [userInfo, setUserInfo] = useState({});
    const [openForBlockUnblock, setOpenForBlockUnblock] = useState(false);
    const [openForTelegramIdEdit, setOpenForTelegramIdEdit] = useState(false);
    const [telegramId, setTelegramId] = useState('');

    useEffect(() => {
        (async () => {
            const table = await API.Users.getCurrentUser(props.id);
            setUserInfo({
                ...userInfo,
                id: table.data.id,
                email: table.data.email,
                created: table.data.created,
                lastActivity: table.data.lastActivity,
                lastVisited: table.data.lastVisited,
                updated: table.data.updated,
                roles: table.data.roles,
                isBlocked: table.data.isBlocked,
                defaultDeployKeyId: table.data.defaultDeployKeyId
            });
        })();
    }, []);

    const blockUnblockClicked = (e) => {
        e.preventDefault();
        setOpenForBlockUnblock(true);
    };

    const telegramIdEditClicked = (e) => {
        e.preventDefault();
        setOpenForTelegramIdEdit(true);
    };

    const blockUnBlockUser = async () => {
        userInfo.isBlocked === false ? (await API.Users.userBlock(userInfo.id)) : (await API.Users.userUnblock(userInfo.id));
        setOpenForBlockUnblock(false);
        const table = await API.Users.getCurrentUser(props.email);
        setUserInfo({
            ...userInfo,
            isBlocked: table.data.isBlocked
        });
    };

    const editTelegramId = async () => {
        await API.Users.editTelegramId(userInfo.id, {telegramId: telegramId})
    };

    const handleClose = () => {
        setOpenForBlockUnblock(false);
        setOpenForTelegramIdEdit(false);
    };

    const UserInfo = () => {
        return (
            <>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">#</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{userInfo.id}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Email</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{userInfo.email}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Created</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(userInfo.created).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Is blocked</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{userInfo.isBlocked ? 'Yes' : 'No'}</span>
                        <StyledButton onClick={e => blockUnblockClicked(e, userInfo.id)}
                                      icon={userInfo.isBlocked === false ? "fas fa-lock-open" : "fas fa-lock-alt"}
                                      tooltip={"Block/Unblock user"}
                                      type="danger"
                                      variant="contained"
                                      style={{
                                          height: 25,
                                          width: 28,
                                          background: '#343a40',
                                          color: '#c2c7d0',
                                          border: 'none',
                                          borderRadius: 3,
                                          cursor: 'pointer',
                                          marginLeft: 5
                                      }}/>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Last activity</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(userInfo.lastActivity).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Last visited</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(userInfo.lastVisited).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Updated</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(userInfo.updated).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Actions</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <StyledButton onClick={e => telegramIdEditClicked(e, userInfo.id)}
                                      icon={userInfo.isBlocked === false ? "fas fa-lock-open" : "fas fa-lock-alt"}
                                      tooltip={"Edit telegram id"}
                                      type="danger"
                                      variant="contained"
                                      style={{
                                          height: 25,
                                          width: 28,
                                          background: '#343a40',
                                          color: '#c2c7d0',
                                          border: 'none',
                                          borderRadius: 3,
                                          cursor: 'pointer',
                                          marginLeft: 5
                                      }}/>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-20px"><label className="col-form-label">Roles</label></div>
                    <div className="col-11 d-flex align-items-center">
                        {userInfo.roles?.length === 0 ?
                            <span className="pl-2">No roles</span>
                            :
                            userInfo.roles?.map((role, index) => (
                                <span className="pl-2" key={'roles' + index}>{RenderRoleName(role)}</span>
                            ))}
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
            <div className="userInfoContainer">
                <h3 className="currentKeyTableTitle tableTitle marginZero">
                    <Link to={"/users"} style={{textDecoration: "underline", color: "black"}}>
                        <span>User</span>
                    </Link>
                    <span>: {props.id}</span>
                </h3>
                <div className="w-100 d-flex flex-column pl-5 snapshotFilenameInfo report">
                    <UserInfo/>
                </div>
            </div>
            <Dialog open={openForBlockUnblock} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to {userInfo.isBlocked === false ? 'block' : 'unblock'} the user?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={blockUnBlockUser} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForBlockUnblock(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForTelegramIdEdit} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to edit telegram id?
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0 padding-4">
                        <div className="col-1"><label className="col-form-label">Telegram id:</label></div>
                        <TextField
                            margin="dense"
                            select
                            id="wildcard"
                            type="text"
                            value={telegramId || ''}
                            fullWidth
                            variant="standard"
                            onChange={(event => setTelegramId(event.target.value))}
                            style={{width: 335}}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={editTelegramId} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForTelegramIdEdit(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserInfo;