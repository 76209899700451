import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../../../stores/MainStore";
import {TableStore} from "../../../../../stores/TableStore";
import Utils from "../../../../../Auth/Utils";
import {Link} from "react-router-dom";
import {API} from "../../../../../api/API";
import CustomTable from "../../../../customTables/CustomTable";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {Permission} from "../../../../../rbac/Permissions";
import {UsersStore} from "../../../../../stores/UsersStore";

export const KeyHostsInfo = (props) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [openForUnmount, setOpenForUnmount] = useState(false);
    const [hostId, setHostId] = useState('');
    const [id, setId] = useState('');

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const unmountOnClicked = (e, id) => {
        e.preventDefault();
        setHostId(id);
        setId('');
        setOpenForUnmount(true);
    };

    const handleClose = () => {
        setOpenForUnmount(false);
    };

    const unmountDomain = async () => {
        await API.SnapShots.unmountDomain(hostId, id);
        setOpenForUnmount(false);
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 23}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 23}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(4)
        },
        {
            name: 'hostName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Name</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    {
                        userState.permissions?.includes(Permission.ViewHosts) || userState.permissions?.includes(Permission.ViewOwnHosts) ||
                        userState.permissionsAssigned?.includes(Permission.ViewHosts) || userState.permissionsAssigned?.includes(Permission.ViewOwnHosts)
                            ? <Link to={'/host/' + rowData.id}>
                                <div className="fileName" style={{textAlign: 'left!important'}}>
                                    <span style={{textDecoration: 'underline'}}>{rowData.hostName}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{textAlign: 'left!important'}}>
                                <span>{rowData.hostName}</span>
                            </div>
                    }
                </div>
            ),
            minWidth: Utils.RemToPx(10)
        },
        {
            name: 'ipAddress',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Ip</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.ipAddress}</span>
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            name: 'port',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Port</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.port}</span>
                </div>
            ),
            width: Utils.RemToPx(8)
        },
        {
            name: 'selfHost',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Is Me?</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.selfHost ? 'Yes' : 'No'}</span>
                </div>
            ),
            width: Utils.RemToPx(8)
        },
        {
            name: 'hasWildcard',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Wildcard?</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.hasWildcard ? 'Yes' : 'No'}</span>
                </div>
            ),
            width: Utils.RemToPx(8)
        },
        {
            name: 'domainsCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Domains</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewHosts) || userState.permissions?.includes(Permission.ViewOwnHosts) ||
                        userState.permissionsAssigned?.includes(Permission.ViewHosts) || userState.permissionsAssigned?.includes(Permission.ViewOwnHosts)
                            ? <Link to={'/host/' + rowData.id}>
                                <div className="fileName" style={{textAlign: 'left!important'}}>
                                    <span
                                        style={{textDecoration: 'underline'}}>{rowData.domainsCount + " [ details ] "}</span>
                                </div>
                            </Link>
                            : <div className="fileName" style={{textAlign: 'left!important'}}>
                                <span>{rowData.domainsCount + " [ details ] "}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(11)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex align-items-center justify-content-center">
                    <StyledButton onClick={e => unmountOnClicked(e, rowData.id)}
                                  tooltip={"Unmount domain"}
                                  icon="fas fa-eraser" type="danger"
                                  variant="contained"
                                  style={{
                                      padding: 5,
                                      background: '#343a40',
                                      color: '#c2c7d0',
                                      border: 'none',
                                      borderRadius: 3,
                                      cursor: 'pointer',
                                  }}/>
                </div>
            ),
            width: Utils.RemToPx(8)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Keys.getKeyHostsInfo(props.keys, page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <section className="content">
                <div id="table-container">
                    <CustomTable state={{
                        typeOfRender: state.renderType,
                        canSelect: false,
                        canHover: false,
                        canSearch: false,
                        pagination: true
                    }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                 texts={{noItems: "No results..."}}/>
                </div>
            </section>
            <Dialog open={openForUnmount} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to unmount the domain?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={unmountDomain} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForUnmount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

