import {customFetchDeployApi} from "../requests/requests";

export class ReportsAPI {

    static async getReports(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/reports?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getReportInfo(id) {
        return await customFetchDeployApi({path: "/api/v2/report/" + id, method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async ReportDelete(id) {
        return await customFetchDeployApi({path: "/api/v2/report/" + id, method: 'DELETE'})
            .then(response => {
                return response
            })
    }
}