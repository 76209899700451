import React, {useContext, useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {ConfigStore} from "../../stores/ConfigStore";
import {API} from "../../api/API";
import {StyledButton} from "../../customstyled/StyledButton";
import {Link, useHistory} from "react-router-dom";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {DashboardStore} from "../../stores/DashboardStore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

export const IncrementalBuild = () => {
    const {state: config, dispatch: setConfig} = useContext(ConfigStore);
    const {state: dashboardConfig, dispatch: setDashboardConfig} = useContext(DashboardStore);
    const [open, setOpen] = useState(false);
    const location = useHistory();

    useEffect(() => {
        (async () => {
            const buildData = await API.BuildRequests.buildRequest();
            setDashboardConfig({type: 'SET_BUILD_DATA', payload: buildData.data});
            setDashboardConfig({type: 'SET_SCENARIO', payload: 0});
        })();
    }, []);

    const continueOnClicked = () => {
        setConfig({type: "SET_DATA_INPUT", payload: config.dataInput});
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const createBuildRequest = async () => {
        await API.BuildRequests.addBuildRequest({
            key: dashboardConfig.deployKey,
            buildServerId: parseInt(dashboardConfig.dataInputBuildServer) || dashboardConfig.buildServer[0].id,
            scenario: dashboardConfig.scenario,
            configId: 0,
            configParsed: JSON.stringify({
                buildType: dashboardConfig.scenario !== 3 ? 'release' : 'snapshot',
                buildNumber: dashboardConfig.buildNumber,
                deployKey: dashboardConfig.deployKey,
                configName: config.staticData.configName,
                buildServerId: dashboardConfig.dataInputBuildServer || dashboardConfig.buildServer[0].id,
                unityBranch: config.staticData.unityBranch,
                webglBranch: config.staticData.webglBranch,
                Il2CppType: config.staticData.Il2CppType,
                buildConfig: config.staticData.buildConfig,
                logLevel: config.staticData.logLevel,
                developDomains: config.staticData.developDomains,
                productionDomains: config.staticData.productionDomains,
                excludePackages: config.staticData.excludePackages,
                cdnLink: config.staticData.cdnLink,
                needManualTest: config.staticData.needManualTest,
                ready4Product: config.staticData.ready4Product,
                onlyStableStaging: config.staticData.onlyStableStaging
            })
        });
        setOpen(false);
        location.push('/dashboard');
    };

    return (
        <>
            <div className="contentWrapper">
                <div className="incrementalBuildNav">
                    <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                        <Link to="/" className="black routes">
                            <span>Dashboard</span>
                        </Link>
                        <span className="route">Incremental Build</span>
                    </Breadcrumbs>
                </div>
                <div className="dashboardTitle">
                    <h3>Correct build request config if needed:</h3>
                </div>
                <div className="flex-column backgroundIncremental">
                    <div>
                    <span>
                        1) Build Server:
                    </span>
                        <div>
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                value={dashboardConfig.buildServer.length > 0 ? (dashboardConfig.dataInputBuildServer || dashboardConfig.buildServer[0].id) : ''}
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setDashboardConfig({
                                    type: 'SET_DATA_INPUT_BUILD_SERVER',
                                    payload: parseInt(event.target.value)
                                }))}
                            >
                                {dashboardConfig.buildServer.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.id}>
                                        {option.serverName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </div>
                <div className="buttonBackground">
                    <StyledButton onClick={continueOnClicked} title="Continue" type="danger"
                                  variant="contained" style={{
                        padding: 10,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Create build request?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={createBuildRequest} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};
