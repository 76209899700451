import React from 'react';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import {useParams} from "react-router";

const Profile = () => {
    const {email} = useParams();


    return (
        <div className="snapShotsTable">
            <section>
                <div style={{float: 'none'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="profileRoute"> Profile</span>
                        </Breadcrumbs>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card card-primary card-outline">
                                <div className="card-body box-profile">

                                </div>
                            </div>
                            <div className="card card-primary">

                            </div>
                        </div>
                        <div className="col-md-9">

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Profile;