// Copyright © 2015-2020 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Utils from "../../Auth/Utils";

// Material UI
import 'react-virtualized/styles.css';

// Components
import Pagination from "@material-ui/lab/Pagination";
import Select from "react-select";
import MuiTable from "./MuiTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import {TableStore} from "../../stores/TableStore";
import {styled} from '@mui/material/styles';

let prevBody = {};

export default function CustomTable(props) {
    const {
        state: {
            typeOfRender,
            canSelect = true, canHover = true, canSearch = true, pagination = false,
            reRender = false, setReRender = () => false
        },
        callbacks: {
            getList, getListItems, getColumns, selectSingleItem = () => false,
            getSelectedItems = () => [], setSelectedItems = () => false, clearLists
        },
        texts = {},
        onError
    } = props;
    const {noItems = null} = texts;

    const {state: storeState} = useContext(TableStore);

    // Render type === basic
    const [offset, setOffset] = useState(0);

    // Render type === search
    const [searchOffset, setSearchOffset] = useState(0);

    // Base table state
    const [renderType, setRenderType] = useState(typeOfRender);
    useEffect(() => {
        setRenderType(typeOfRender);
    }, [typeOfRender]);

    const [size, setSize] = useState(20);
    const [total, setTotal] = useState(size);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [needRender, setNeedRender] = useState(true);
    const [tableWidth, setTableWidth] = useState(0);

    const getCurrentPageFromOffset = useCallback(() => {
        return renderType === "search" ? (searchOffset === 0 ? 1 : (searchOffset / size) + 1) : (offset === 0 ? 1 : (offset / size) + 1);
    }, [renderType, offset, searchOffset, size]);

    const [page, setPage] = useState(getCurrentPageFromOffset());

    // Height calculation
    const getTableSize = () => {
        const tableContainer = document.querySelector("#table-container");

        if (tableContainer) {
            const tableContainerWidth = tableContainer.getBoundingClientRect().width;
            setTableWidth(tableContainerWidth - 2);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", getTableSize);
        getTableSize();

        return () => {
            window.removeEventListener("resize", getTableSize);
            if (canSelect) setSelectedItems([]);
            prevBody = {};
        };
    }, []);

    const requestToRenderList = useCallback(async body => {
        const total = await getListItems(renderType, body, onError);
        setTotal(Math.ceil(total / size));

        setNeedRender(false);
        setIsPageLoaded(true);
    }, [isPageLoaded, renderType, size]);
    const buildBody = useCallback(() => ({
        page: renderType === "search" ? searchOffset : page,
        size,
        text: "",
        renderType
    }), [offset, renderType, searchOffset, size]);
    const callRender = () => {
        setIsPageLoaded(false);

        const body = buildBody();
        if (!Utils.isEquivalent(body, prevBody) || reRender) {
            prevBody = body;
            requestToRenderList(body);
            if (reRender) setReRender(false);
        }
    };

    // Handlers
    const onPageChanged = (event, clickedPage) => {
        if (typeof window !== "undefined" && clickedPage !== page) {
            const newOffset = clickedPage === 1 ? 0 : (clickedPage - 1) * size;
            setPage(clickedPage);
            if (renderType === "search") setSearchOffset(newOffset);
            else setOffset(newOffset);

            setPage(clickedPage);
            if (canSelect) setSelectedItems([]);
            setNeedRender(true);

            if (canSearch) document.querySelector('#table-search-input').scrollIntoView({behavior: 'smooth'});
        }
    };

    const onRowsPerPageChanged = async count => {
        await clearLists();
        setSize(count.value);
        setNeedRender(true);
    };

    // Effects
    useEffect(callRender, [renderType]);

    useEffect(() => {
        if (needRender) callRender();
    }, [needRender]);

    useEffect(() => {
        if (needRender !== reRender && reRender) {
            setOffset(0);
            setSearchOffset(0);

            setNeedRender(reRender);
        }
    }, [reRender]);

    // Styles
    const StyledPagination = styled(Pagination)(({theme}) => ({
        '& .MuiPaginationItem-root': {
            borderRadius: 0,
            margin: 0
        }
    }));

    const customSelect = {
        input: (provided, state) => ({
            ...provided,
            paddingBottom: 0,
            paddingTop: 0,
            margin: 0
        }),
        control: (provided, state) => ({
            ...provided,
            minHeight: 32,
            height: 32
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0px 8px',
        }),
    };

    let elementCount = document.querySelector(".MuiPagination-root")?.childElementCount;
    const paginationWidth = () => {
        if (elementCount) return elementCount * 32;
    };

    return isPageLoaded && getList(renderType)?.length > 0 ? (
        <>
            <MuiTable
                data={getList(renderType) || []}
                width={tableWidth}
                rowHeight={Utils.RemToPx(2)}
                columns={getColumns()}
                isCellSelected={(column, rowData) => canSelect ? (getSelectedItems()).some(id => rowData && rowData.id === id) : false}
                isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) => canHover && rowData.id && rowData.id === hoveredRowData.id}
                onCellClick={(event, {rowData}) => {
                    if (!rowData?.isDisabled && canSelect) selectSingleItem(rowData.id);
                }}
                includeHeaders={true}
            />
            {pagination && total > 0 && storeState.table && storeState.table.data ? <div className="mt-3 pagination" id="pagination">
                <div className="d-flex justify-content-end align-items-center">
                    <div style={{width: `${Utils.RemToPx(7)}px`}}>
                        <Select menuPlacement="top"
                                onChange={selected => onRowsPerPageChanged(selected)}
                                value={typeof size === "number" ? {label: size, value: size} : size}
                                options={[10, 20, 30].map(el => ({label: el, value: el}))}
                                styles={customSelect}
                        />
                    </div>
                    <div style={{marginLeft: 5, minWidth: paginationWidth()}}>
                        <StyledPagination count={total} page={page} onChange={onPageChanged} color="primary"
                                          size="medium"
                                          variant="outlined"
                        />
                    </div>
                    <h4 className="tableNavigation">
                        <div className="paginationInfoContainer">
                            <div className="paginationInfo">
                                        <span>
                                            {
                                                'Showing ' + ((storeState.table.data.length === 0) ? (
                                                    '0') : (storeState.table.currentPage * storeState.table.pageSize - (storeState.table.pageSize - 1) || 0) +
                                                    ' to ' + Math.min(storeState.table.currentPage * storeState.table.pageSize, storeState.table.totalCount || 0)) +
                                                ' of ' + (storeState.table.totalCount || 0) + ' items (' + (storeState.table.pageSize || 20) + ' items per page)'
                                            }
                                        </span>
                            </div>
                        </div>
                    </h4>
                </div>
            </div> : null}
        </>
    ) : (
        <div className="card" style={{width: '100%', height: '4rem'}}>
            <div className="card-body empty-table">
                <div className="d-flex justify-content-center align-items-center w-100 py-3">
                    <h6 className="mr-3 mb-0">{!isPageLoaded ? 'Search is initiated. Please wait a second...'
                        : noItems ?? "No items found by query, please use filters to change query parameters."}</h6>
                    {!isPageLoaded ? <CircularProgress/> : null}
                </div>
            </div>
        </div>
    );
}
