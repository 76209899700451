import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {ConfigInfo} from "./components/config/ConfigInfo";
import {Snapshots} from "./components/config/Snapshots";
import {Link, useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {API} from "../../../api/API";

export const CurrentConfigInfo = () => {
    const {id} = useParams();
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);
    const [configName, setConfigName] = useState({});

    useEffect(() => {
        (async () => {
            const table = await API.Configs.getConfigInfo(id);
            setConfigName({
                ...configName,
                configName: table.data.configName,
            });
        })();
    }, []);

    const LinkRouter = (props) => {
        return (
            <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                <Link to="/" className="black routes">
                    <span>Dashboard</span>
                </Link>
                <Link to="/configs" className="black routes">
                    <span>Configs table</span>
                </Link>
                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                    if (last) {
                        return (
                            <Typography color="textPrimary" key={to} className="lastItem">
                                {'Config : {'  + configName.configName +  '}'}
                            </Typography>
                        )
                    }
                })}
            </Breadcrumbs>
        );
    };
    return (
        <div className="snapShotsTable">
            <LinkRouter/>
            <div style={{float: 'none', height: '22px', marginBottom: '10px'}}/>
                <ConfigInfo id={id}/>
            <div className="snapshotInfo">
                <h3 className="tableTitle snapshots marginZero">
                    <span>ACP : </span>
                    <Link to={"/snapshots"} style={{textDecoration: "underline", color: "black"}}>
                        <span>Snapshots</span>
                    </Link>
                </h3>
                <Snapshots id={id}/>
            </div>
        </div>
    );
};
