import React, {useCallback, useContext, useEffect} from 'react';
import {Store} from "../../../../../stores/MainStore";
import {TableStore} from "../../../../../stores/TableStore";
import Utils from "../../../../../Auth/Utils";
import {API} from "../../../../../api/API";
import CustomTable from "../../../../customTables/CustomTable";

export const SnapshotHistoryInfo = (props) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const status = [
        'Deployed',
        'ReDeployed',
        'Removed',
        'UnPacked',
        'Patched',
        'Mounted'
    ];

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 23}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 23}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(8),
        },
        {
            name: 'timeStamp',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Time</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center fileName">
                    <div className="fileName" style={{textAlign: 'left!important'}}>
                        <span>{new Date(rowData.timeStamp).toLocaleString()}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(25)
        },
        {
            name: 'eventStatus',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Status</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <div className="fileName" style={{textAlign: 'left!important'}}>
                        <span>{status[rowData.eventStatus]}</span>
                    </div>
                </div>
            ),
            minWidth: Utils.RemToPx(9)
        },
        {
            name: 'message',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Additional message</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.message}
                </span>
                </div>
            ),
            width: Utils.RemToPx(30)
        },
        {
            name: 'email',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>User</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.email}
                </span>
                </div>
            ),
            width: Utils.RemToPx(20)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);


    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.SnapShots.getSnapshotHistoryInfo(page, size, props.fileName);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <div className="snapshotHistory">
            <section className="content">
                <div id="table-container">
                    <CustomTable state={{
                        typeOfRender: state.renderType,
                        canSelect: false,
                        canHover: false,
                        canSearch: false,
                        pagination: true
                    }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                 texts={{noItems: "No results..."}}/>
                </div>
            </section>
        </div>
    );
};
