import {customFetch, customFetchDeployApi} from "../requests/requests";

export class UsersAPI {

    static async getUsers(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/users?Page=" + page + "&PageSize=" + size,
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async userBlock(id) {
        return await customFetchDeployApi({path: "/api/v2/user/" + id, method: 'DELETE'})
            .then(response => {
                return response
            })
    }

    static async userUnblock(id) {
        return await customFetchDeployApi({path: "/api/v2/user/" + id + "/unblock"})
            .then(response => {
                return response
            })
    }

    static async addNewUser(body) {
        return await customFetchDeployApi({path: "/api/v2/users/", body, method: 'PUT'})
            .then(response => {
                return response
            })
    }

    static async getModel(userId, onError, network) {
        return await customFetch({path: "/jsonRpc/user.getUserModel", body: {userId}, network})
            .then(response => response)
            .catch(onError);
    }

    static async getList(body, onError, network = null) {
        return await customFetch({path: "/jsonRpc/user.listUsers", body, network})
            .then(resultData => resultData)
            .catch(onError);
    }

    static async search(body, onError, network = null) {
        return await customFetch({path: "/jsonRpc/user.searchUsers", body, network})
            .then(resultData => resultData)
            .catch(onError);
    }

    static async getRBAC() {
        return await customFetchDeployApi({path: "/api/v2/user/rbac", method: "GET"})
            .then(response => {
                return response
            })
    }

    static async getUserRBAC(id) {
        return await customFetchDeployApi({path: "/api/v2/user/"+ id +"/roles/permissions", method: "GET"})
            .then(response => {
                return response
            })
    }

    static async getCurrentUser(id) {
        return await customFetchDeployApi({path: "/api/v2/users/user/"+ id, method: "GET"})
            .then(response => {
                return response
            })
    }

    static async editUserRBAC(id, data) {
        return await customFetchDeployApi({path: "/api/v2/users/user/"+ id + "/edit", body: data})
            .then(response => {
                return response
            })
    }

    static async getUserInfo() {
        return await customFetchDeployApi({path: "/api/v2/users/myself", method: "GET"})
            .then(response => {
                return response
            })
    }

    static async getEmailsList(inputValue) {
        return await customFetchDeployApi({path: "/api/v2/user/email/" + inputValue, method: "GET"})
            .then(response => {
                return response
            })
    }

    static async editTelegramId(userId, telegramId) {
        return await customFetchDeployApi({path: "/api/v2/user/" + userId +"/telegram/edit", telegramId})
            .then(response => {
                return response
            })
    }

}