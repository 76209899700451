import React, {createContext, useReducer} from 'react';

export const initialState = {
    unity: [],
    webgl: [],
    configName: [],
    buildServer: [],
    buildServers: [],
    il2CppType: [
        {value: 'asm.js', name: 'ASM.js'},
        {value: 'wasm', name: 'WASM'},
        {value: 'both', name: 'ASM.js & WASM'}
    ],
    buildConfig: [
        {value: 'DefaultConfiguration', name: 'DefaultConfiguration'},
        {value: 'PresentationConfig', name: 'PresentationConfig'},
        {value: 'Epam_Config', name: 'Epam_Config'},
        {value: 'Fabuwood_Config', name: 'Fabuwood_Config'},
        {value: 'PSU_Config', name: 'PSU_Config'},
        {value: 'SchoolFare', name: 'SchoolFare'}
    ],
    logLevel: [
        {value: 'none', name: 'None'},
        {value: 'errors', name: 'Explicitly Thrown Exceptions Only'},
        {value: 'without-logs', name: 'Full Without Stacktrace'},
        {value: 'with-logs', name: 'Full With Stacktrace'}
    ],
    defaultConfigData: [],
    staticValue: {
        buildServer: "2",
        configName: '1',
        unityBranch: 'develop',
        webglBranch: 'development',
        Il2CppType: 'asm.js',
        buildConfig: 'DefaultConfiguration',
        logLevel: 'none',
        developDomains: [],
        productionDomains: '',
        excludePackages: '[net.roomful.ios, net.roomful.android]',
        cdnLink: '',
        needManualTest: false,
        ready4Product: false,
        onlyStableStaging: false
    },
    scenario: '',
    buildNumber: '',
    deployKey: '',
    getConfigInfo: [],
    dataInput: [],
    dataInputConfigName: '1',
    dataInputBuildServer: '',
    dataInputUnityBranch: '',
    dataInputWebglBranch: '',
    dataInputIl2cppType: '',
    dataInputBuildConfig: '',
    dataInputLogLevel: '',
    dataInputDevelopDomains: [],
    dataInputProductionDomains: [],
    dataInputExcludePackages: '',
    dataInputCdLink: '',
    dataInputNeedManualTesting: false,
    dataInputReadyForProduction: false,
    dataInputOnlyStableStaging: false
};

export const DashboardStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_UNITY_BRANCH':
            return {...state, unity: action.payload};
        case 'SET_BRANCHES':
            return {...state, unity: action.payload.unity,
                webgl: action.payload.webgl};
        case 'SET_WEBGL_BRANCH':
            return {...state, webgl: action.payload};
        case 'SET_CONFIG_NAME':
            return {...state, configName: action.payload};
        case 'SET_BUILD_SERVER':
            return {...state, buildServer: action.payload};
        case 'SET_DEFAULT_CONFIG_DATA':
            return {...state, defaultConfigData: action.payload};
        case 'SET_DATA_INPUT':
            return {...state, dataInput: action.payload};
        case 'SET_SCENARIO':
            return {...state, scenario: action.payload};
        case 'SET_BUILD_NUMBER':
            return {...state, buildNumber: action.payload};
        case 'SET_DEPLOY_KEY':
            return {...state, deployKey: action.payload};
        case 'SET_CONFIG_INFO':
            return {...state, getConfigInfo: action.payload,
                dataInputConfigName: action.payload.configName,
                dataInputBuildServer: action.payload.buildServer,
                dataInputUnityBranch: action.payload.unityBranch,
                dataInputWebglBranch: action.payload.webglBranch,
                dataInputIl2cppType: action.payload.il2CppType,
                dataInputBuildConfig: action.payload.buildConfig,
                dataInputLogLevel: action.payload.logLevel,
                dataInputDevelopDomains: action.payload.developDomains,
                dataInputProductionDomains: action.payload.productionDomains,
                dataInputExcludePackages: action.payload.excludePackages,
                dataInputCdLink: action.payload.cdnLink,
                dataInputNeedManualTesting: action.payload.needManualTesting,
                dataInputReadyForProduction: action.payload.readyForProduction,
                dataInputOnlyStableStaging: action.payload.needOnlyStableStaging
            };
        case 'SET_DATA_INPUT_CONFIG_NAME':
            return {...state, dataInputConfigName: action.payload};
        case 'SET_DATA_INPUT_BUILD_SERVER':
            return {...state, dataInputBuildServer: action.payload};
        case 'SET_DATA_INPUT_UNITY_BRANCH':
            return {...state, dataInputUnityBranch: action.payload};
        case 'SET_DATA_INPUT_WEBGL_BRANCH':
            return {...state, dataInputWebglBranch: action.payload};
        case 'SET_DATA_INPUT_IL2CPP_TYPE':
            return {...state, dataInputIl2cppType: action.payload};
        case 'SET_DATA_INPUT_BUILD_CONFIG':
            return {...state, dataInputBuildConfig: action.payload};
        case 'SET_DATA_INPUT_LOG_LEVEL':
            return {...state, dataInputLogLevel: action.payload};
        case 'SET_DATA_INPUT_DEVELOP_DOMAINS':
            return {...state, dataInputDevelopDomains: action.payload};
        case 'SET_DATA_INPUT_PRODUCTION_DOMAINS':
            return {...state, dataInputProductionDomains: action.payload};
        case 'SET_DATA_INPUT_EXCLUDE_PACKAGES':
            return {...state, dataInputExcludePackages: action.payload};
        case 'SET_DATA_INPUT_CD_LINK':
            return {...state, dataInputCdLink: action.payload};
        case 'SET_DATA_INPUT_NEED_MANUAL_TESTING':
            return {...state, dataInputNeedManualTesting: action.payload};
        case 'SET_DATA_INPUT_READY_FOR_PRODUCTION':
            return {...state, dataInputReadyForProduction: action.payload};
        case 'SET_DATA_INPUT_ONLY_STABLE_STAGING':
            return {...state, dataInputOnlyStableStaging: action.payload};
        case 'SET_BUILD_DATA':
            return {...state, buildNumber: action.payload.buildNumber,
                deployKey: action.payload.deployKey,
                buildServer: action.payload.buildServers,
                configName: action.payload.configs,
                unity: action.payload.branches.unity,
                webgl: action.payload.branches.webgl
            };
        default:
            return state;
    }
}

export const DashboardProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <DashboardStore.Provider value={value}>{props.children}</DashboardStore.Provider>;
};