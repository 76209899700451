import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../../../stores/MainStore";
import Utils from "../../../../../Auth/Utils";
import {API} from "../../../../../api/API";
import JobsTable from "../../../../customTables/JobsTable";
import {SnapshotsJobsStore} from "../../../../../stores/SnapshotJobsStore";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {Permission} from "../../../../../rbac/Permissions";
import {UsersStore} from "../../../../../stores/UsersStore";

export const SnapshotJobsInfo = (props) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(SnapshotsJobsStore);
    const {state: userState} = useContext(UsersStore);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [jobId, setJobId] = useState('');

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setJobId(id);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForDelete(false);
    };

    const deleteJob = async () => {
        await API.Jobs.JobDelete(jobId);
        setOpenForDelete(false);
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 23}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 23}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(5)
        },
        {
            name: 'type',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Type</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.jobType}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            name: 'data',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Data</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.jobData}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(30)
        },
        {
            name: 'snapshot',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Snapshot</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.snapshotFileName}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(20)
        },
        {
            name: 'waitTo',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Wait to</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{new Date(rowData.waitDelay).toLocaleString()}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'isCanceled',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Is Canceled?</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.isCanceled ? 'Yes' : 'No'}</span>
                    </div>
                </div>
            ),
            minWidth: Utils.RemToPx(5)
        },
        {
            name: 'lastReason',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Last reason</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.lastReason}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex justify-content-center align-items-center">
                    {
                        userState.permissions?.includes(Permission.DeleteJobs) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteJobs)
                            ?
                            <StyledButton onClick={e => deleteOnClicked(e, rowData.id)} icon="fas fa-solid fa-trash-can"
                                          type="danger"
                                          variant="contained"
                                          style={{
                                              padding: 5,
                                              background: '#343a40',
                                              color: '#c2c7d0',
                                              border: 'none',
                                              borderRadius: 3,
                                              cursor: 'pointer',
                                          }}/>
                            : null
                    }

                </div>
            ),
            width: Utils.RemToPx(10)
        }
    ].filter(el => el));

    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.SnapShots.getSnapshotJobsInfo(props.fileName, page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        const total = table.totalCount;
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <section className="content">
                <div id="table-container">
                    <JobsTable state={{
                        typeOfRender: state.renderType,
                        canSelect: false,
                        canHover: false,
                        canSearch: false,
                        pagination: true
                    }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                               texts={{noItems: "No results"}}/>
                </div>
            </section>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to cancel the job in queue?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteJob} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};


