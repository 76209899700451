import React, {useEffect, useState} from "react";
import {Route, useHistory} from "react-router-dom";
import {API} from "../api/API";
import Panel from "../Panel";

export const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest}
               component={() =>
                   Component ? <Panel><Component/></Panel> : null
               }
        />
    );
};

export const PublicRoute = ({component: Component, ...rest}) => (
    <Route {...rest}
           component={() => <Component/>}/>
);
