import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {API} from "../../../api/API";
import {Link, useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {Permission} from "../../../rbac/Permissions";
import {StyledButton} from "../../../customstyled/StyledButton";
import {UsersStore} from "../../../stores/UsersStore";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";

export const CurrentReportInfo = () => {
    const {id} = useParams();
    const {state: userState} = useContext(UsersStore);
    const [reportInfo, setReportInfo] = useState({});
    const [openForDelete, setOpenForDelete] = useState(false);
    const [reportId, setReportId] = useState('');
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);

    const LinkRouter = (props) => {
        return (
            <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                <Link to="/" className="black routes">
                    <span>Dashboard</span>
                </Link>
                <Link to="/reports" className="black routes">
                    <span>Reports table</span>
                </Link>
                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                    if (last) {
                        return (
                            <Typography color="textPrimary" key={to} className="lastItem">
                                {'Report : ' + value}
                            </Typography>
                        )
                    }
                })}
            </Breadcrumbs>
        );
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setReportId(id);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForDelete(false);
    };

    const deleteReport = async () => {
        await API.Reports.ReportDelete(reportId);
        setOpenForDelete(false);
    };

    const unicodeToChar = (text) => {
        if (text !== undefined) {
            return text.replace(/\\u([\d\w]{5})/gi, match => {
                return String.fromCodePoint(parseInt(match.replace(/\\u/gi, ''), 16));
            });
        }
    };

    useEffect(() => {
        (async () => {
            const table = await API.Reports.getReportInfo(id);
            setReportInfo({
                ...reportInfo,
                id: table.data.id,
                created: table.data.created,
                data: table.data.data,
                message: table.data.message
            });
        })();
    }, []);

    const ReportInfo = () => {
        return (
            <>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">#</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{reportInfo.id}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Created At</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{new Date(reportInfo.created).toLocaleString()}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Data</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{reportInfo.data === null ? 'no data' : reportInfo.data}</span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Message</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{unicodeToChar(reportInfo.message)}</span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-15px"><label className="col-form-label">Actions</label></div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.DeleteReports) ||
                            userState.permissionsAssigned?.includes(Permission.DeleteReports)
                                ?
                                <StyledButton onClick={e => deleteOnClicked(e, reportInfo.id)}
                                              icon="fas fa-solid fa-trash-can"
                                              type="danger"
                                              tooltip={"Delete report"}
                                              variant="contained"
                                              style={{
                                                  height: 25,
                                                  width: 28,
                                                  background: '#343a40',
                                                  color: '#c2c7d0',
                                                  border: 'none',
                                                  borderRadius: 3,
                                                  cursor: 'pointer',
                                              }}/>
                                : null
                        }
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
            <div className="snapShotsTable">
                <LinkRouter/>
                <div style={{float: 'none', height: '22px', marginBottom: '10px'}}/>
                <h3 className="currentReportTableTitle tableTitle marginZero">
                    <Link to={"/reports"} style={{textDecoration: "underline", color: "black"}}>
                        <span>Report</span>
                    </Link>
                    <span> id : {id}</span>
                </h3>
                <div className="w-100 d-flex flex-column pl-5 report">
                    <ReportInfo/>
                </div>
            </div>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to delete the report?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteReport} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};
