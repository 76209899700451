import {customFetchDeployApi} from "../requests/requests";

export class SnapShotsAPI {

    static async getSnapShots(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/snapshots?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getSnapshotInfo(fileName) {
        return await customFetchDeployApi({path: "/api/v2/snapshot/" + fileName, method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async getSnapshotHistoryInfo(page, size, fileName) {
        return await customFetchDeployApi({
            path: "/api/v2/snapshot/" + fileName + "/history?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getSnapshotDomainsInfo(page, size, fileName) {
        return await customFetchDeployApi({
            path: "/api/v2/snapshot/" + fileName + "/domains?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getSnapshotJobsInfo(fileName, page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/snapshot/" + fileName + "/jobs?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async snapshotMount(fileName, body, onError) {
        return await customFetchDeployApi({path: "/api/v2/snapshot/" + fileName + "/mount", body})
            .then(response => ({response: response, status: response.status}))
            .catch(onError);
    }

    static async snapshotUnmount(fileName, onError) {
        return await customFetchDeployApi({path: "/api/v2/snapshot/" + fileName + "/unmount"})
            .then(response => ({response: response, status: response.status}))
            .catch(onError);
    }

    static async snapshotDelete(fileName, onError) {
        return await customFetchDeployApi({path: "/api/v2/snapshot/" + fileName, method: 'DELETE'})
            .then(response => ({response: response, status: response.status}))
            .catch(onError);
    }

    static async snapshotUpload(body, onError) {
        return await customFetchDeployApi({path: "/api/v2/snapshot/upload", body, method: 'PUT'})
            .then(response => ({response: response, status: response.status}))
            .catch(onError);
    }

    static async unmountDomain(hostId, domainId) {
        return await customFetchDeployApi({path: "/api/v2/host/" + hostId + '/' + domainId + "/unmount"})
            .then(response => ({response: response, status: response.status}))
    }

    static async addDomainInHost(hostId, body) {
        return await customFetchDeployApi({path: "/api/v2/host/" + hostId, body, method: "PUT"})
            .then(response => ({response: response, status: response.status}))
    }


    static async deleteDomain(hostId, domainId) {
        return await customFetchDeployApi({path: "/api/v2/host/" + hostId + '/' + domainId, method: 'DELETE'})
            .then(response => ({response: response, status: response.status}))
    }

    static async downloadSnapshot(fileName) {
        return await customFetchDeployApi({
            path: "api/v2/snapshot/" + fileName + '/download',
            method: 'GET',
            responseType: 'blob'
        })
            .then(response => response)
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    static async searchDomains(inputValue) {
        return await customFetchDeployApi({path: "/api/v2/domains/" + inputValue, method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async searchHosts(inputValue) {
        return await customFetchDeployApi({path: "/api/v2/hosts/" + inputValue, method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async ValidateDomains(validationKey, data) {
        return await customFetchDeployApi({path: "/api/v2/" + validationKey + "/validate", body: data})
            .then(response => {
                return response
            })
    }
}