import React, {createContext, useReducer} from 'react';

export const initialState = {
    unity: [],
    webgl: [],
    configName: '',
    buildServer: [],
    il2CppType: [
        {value: 'asm.js', name: 'ASM.js'},
        {value: 'wasm', name: 'WASM'},
        {value: 'both', name: 'ASM.js & WASM'}
    ],
    buildConfig: [
        {value: 'DefaultConfiguration', name: 'DefaultConfiguration'},
        {value: 'PresentationConfig', name: 'PresentationConfig'},
        {value: 'Epam_Config', name: 'Epam_Config'},
        {value: 'Fabuwood_Config', name: 'Fabuwood_Config'},
        {value: 'PSU_Config', name: 'PSU_Config'},
        {value: 'SchoolFare', name: 'SchoolFare'}
    ],
    logLevel: [
        {value: 'none', name: 'None'},
        {value: 'errors', name: 'Explicitly Thrown Exceptions Only'},
        {value: 'without-logs', name: 'Full Without Stacktrace'},
        {value: 'with-logs', name: 'Full With Stacktrace'}
    ],
    defaultConfigData: [],
    dataInput: [],
    staticValue: {
        buildServer: "2",
        configName: '1'
    },
    staticData: {
        configName: '',
        unityBranch: 'develop',
        webglBranch: 'development',
        Il2CppType: 'wasm',
        buildConfig: 'DefaultConfiguration',
        logLevel: 'with-logs',
        developDomains: ['omega-dev.roomful.net'],
        productionDomains: ['omega.roomful.net'],
        excludePackages: ['net.roomful.ios, net.roomful.android'],
        cdnLink: 'https://d18kgm0crag10.cloudfront.net',
        needManualTest: true,
        ready4Product: false,
        onlyStableStaging: false
    },
    dataInputBuildServer: '2',
    page: '',
    size: '',

};

export const ConfigStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_UNITY_BRANCH':
            return {...state, unity: action.payload};
        case 'SET_WEBGL_BRANCH':
            return {...state, webgl: action.payload};
        case 'SET_CONFIG_NAME':
            return {...state, configName: action.payload};
        case 'SET_BUILD_SERVER':
            return {...state, buildServer: action.payload};
        case 'SET_DEFAULT_CONFIG_DATA':
            return {...state, defaultConfigData: action.payload};
        case 'SET_DATA_INPUT':
            return {...state, dataInput: action.payload};
        case 'SET_DATA_INPUT_BUILD_SERVER':
            return {...state, dataInputBuildServer: action.payload};
        case 'SET_PAGE':
            return {...state, page: action.payload};
        case 'SET_SIZE':
            return {...state, size: action.payload};
        default:
            return state;
    }
}

export const ConfigProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <ConfigStore.Provider value={value}>{props.children}</ConfigStore.Provider>;
};