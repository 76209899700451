import {customFetchDeployApi} from "../requests/requests";

export class KeysAPI {

    static async getKeys(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/keys?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async getKeyInfo(key) {
        return await customFetchDeployApi({path: "/api/v2/key/" + key, method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async getKeyHostsInfo(key) {
        return await customFetchDeployApi({path: "/api/v2/key/" + key + "/hosts", method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async getKeyDomainsInfo(key, page, size) {
        return await customFetchDeployApi({path: "/api/v2/key/" + key + "/domains?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size), method: 'GET'})
            .then(response => {
                return response
            })
    }

    static async keyBlock(id) {
        return await customFetchDeployApi({path: "/api/v2/keys/" + id, method: 'DELETE'})
            .then(response => {
                return response
            })
    }

    static async keyUnBlock(id) {
        return await customFetchDeployApi({path: "/api/v2/keys/" + id + "/unblock"})
            .then(response => {
                return response
            })
    }

    static async addNewKey(body) {
        return await customFetchDeployApi({path: "/api/v2/keys/", body, method: "PUT"})
            .then(response => {
                return response
            })
    }

    static async editKey(id, body) {
        return await customFetchDeployApi({path: "/api/v2/keys/" + id, body})
            .then(response => {
                return response
            })
    }
}