import {createTheme} from '@material-ui/core/styles';

export const THEME = createTheme({
    overrides: {
        MuiTab: {
            root: {
                opacity: "unset !important"
            }
        }
    },
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            paper: "#fff",
            default: "#fafafa"
        },
        primary: {
            light: "#007cba",
            main: "#006ba1",
            dark: "#005a87",
            contrastText: "#fff"
        },
        secondary: {
            light: "rgba(231, 125, 131, 1)",
            main: "rgba(206, 7, 10, 1)",
            dark: "rgba(141, 4, 6, 1)",
            contrastText: "#fff"
        },
        error: {
            light: "rgba(231, 125, 131, 1)",
            main: "rgba(206, 7, 10, 1)",
            dark: "rgba(141, 4, 6, 1)",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        },
        success: {
            light: "rgba(155, 222, 168, 1)",
            main: "rgba(0, 157, 31, 1)",
            dark: "rgba(2, 60, 14, 1)",
            hint: "rgba(0, 0, 0, 0.38)"
        }
    }
});
