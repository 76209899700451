import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from "react-router";
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import Utils from "../../../Auth/Utils";
import {Link, useLocation} from "react-router-dom";
import {API} from "../../../api/API";
import CustomTable from "../../customTables/CustomTable";
import {StyledButton} from "../../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@mui/material/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {UsersStore} from "../../../stores/UsersStore";
import {Permission} from "../../../rbac/Permissions";

export const CurrentHostInfo = () => {
    const {id} = useParams();
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [openForUnmount, setOpenForUnmount] = useState(false);
    const [openForDelete, setOpenForDelete] = useState(false);
    const [openForAddDomainInHost, setOpenForAddDomainInHost] = useState(false);
    const [domainId, setDomainId] = useState('');
    const [hostName, setHostName] = useState({});
    const [newDomainInHostData, setNewDomainInHostData] = useState({});
    const [configs, setConfigs] = useState([]);
    const location = useLocation();
    const pathNames = location.pathname.split('/').filter((x) => x);
    const type = [
        'static',
        'Dynamic'
    ];

    useEffect(() => {
        (async () => {
            const table = await API.Hosts.getHostInfo(id);
            setHostName({
                ...hostName,
                hostName: table.hostName,
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const configs = await API.Configs.getConfigs();
            setConfigs(configs);
            if (configs?.totalCount > configs?.pageSize) {
                const configNames = await API.Configs.getConfigs(1, configs.totalCount);
                setConfigs(configNames);
            }
        })();
    }, []);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const unmountOnClicked = (e, id) => {
        e.preventDefault();
        setDomainId(id);
        setOpenForUnmount(true);
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setDomainId(id);
        setOpenForDelete(true);
    };

    const handleClose = () => {
        setOpenForUnmount(false);
        setOpenForDelete(false);
        setOpenForAddDomainInHost(false);
    };

    const unmountDomain = async () => {
        await API.SnapShots.unmountDomain(domainId, id);
        setOpenForUnmount(false);
    };

    const deleteDomain = async () => {
        await API.SnapShots.deleteDomain(domainId, id);
        setOpenForDelete(false);
    };

    const addDomainInHostOnClicked = (e) => {
        e.preventDefault();
        setOpenForAddDomainInHost(true);
    };

    const addDomainInHost = async () => {
        await API.SnapShots.addDomainInHost(id, {
            domainName: newDomainInHostData.domainName,
            configId: newDomainInHostData.configId
        });
        setOpenForAddDomainInHost(false);
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 23}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{ marginLeft: 23}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(4)
        },
        {
            name: 'domainName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Domain</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    {
                        userState.permissions?.includes(Permission.ViewHostsInfo) || userState.permissions?.includes(Permission.ViewOwnHostsInfo) ||
                        userState.permissionsAssigned?.includes(Permission.ViewHostsInfo) || userState.permissionsAssigned?.includes(Permission.ViewOwnHostsInfo)
                            ? <Link to={'/host/' + id + '/domain/' + rowData.id}>
                                <div className="black">
                                    <span style={{textDecoration: 'underline'}}>{rowData.domainName}</span>
                                </div>
                            </Link>
                            : <div className="black">
                                <span>{rowData.domainName}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'type',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Type</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{type[rowData.type]}</span>
                </div>
            ),
            width: Utils.RemToPx(15)
        },
        {
            name: 'configName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Config</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center black">
                    {
                        userState.permissions?.includes(Permission.ViewConfigs) ||
                        userState.permissionsAssigned?.includes(Permission.ViewConfigs)
                            ? <Link to={'/config/' + rowData.configId}>
                                <div className="black" style={{textAlign: 'left!important'}}>
                                    <span style={{textDecoration: 'underline'}}>{rowData.configName}</span>
                                </div>
                            </Link>
                            : <div className="black" style={{textAlign: 'left!important'}}>
                                <span>{rowData.configName}</span>
                            </div>
                    }
                </div>
            ),
            width: Utils.RemToPx(16)
        },
        {
            name: 'keysCount',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Keys count</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.keysCount}</span>
                </div>
            ),
            width: Utils.RemToPx(10)
        },
        {
            name: 'snapshotFileName',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Has snapshot?</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData.snapshotFileName}</span>
                </div>
            ),
            width: Utils.RemToPx(18)
        },
        {
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                </span>
            ),
            // width: Utils.RemToPx(13)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex align-items-center justify-content-center">
                    {
                        userState.permissions?.includes(Permission.UnmountDomains) || userState.permissions?.includes(Permission.UnmountOwnDomains) ||
                        userState.permissionsAssigned?.includes(Permission.UnmountDomains) || userState.permissionsAssigned?.includes(Permission.UnmountOwnDomains)
                            ? <StyledButton onClick={e => unmountOnClicked(e, rowData.id)}
                                            icon="fas fa-eraser" type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                            }}/>
                            : null
                    }
                    {
                        userState.permissions?.includes(Permission.DeleteDomains) || userState.permissions?.includes(Permission.DeleteOwnDomains) ||
                        userState.permissionsAssigned?.includes(Permission.DeleteDomains) || userState.permissionsAssigned?.includes(Permission.DeleteOwnDomains)
                            ? <StyledButton onClick={e => deleteOnClicked(e, rowData.id)}
                                            icon="fas fa-solid fa-trash-can" type="danger"
                                            variant="contained"
                                            style={{
                                                padding: 5,
                                                background: '#343a40',
                                                color: '#c2c7d0',
                                                border: 'none',
                                                borderRadius: 3,
                                                cursor: 'pointer',
                                                marginLeft: 4
                                            }}/>
                            : null
                    }
                </div>

            ),
            width: Utils.RemToPx(10)
        }
    ].filter(el => el));
    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Hosts.getHostInfo(id, page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    const LinkRouter = (props) => {
        return (
            <div className="routeContainer">
                <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                    <Link to="/" className="black routes">
                        <span>Dashboard</span>
                    </Link>
                    <Link to="/hosts" className="black routes">
                        <span>Hosts</span>
                    </Link>
                    {pathNames.map((value, index) => {
                        const last = index === pathNames.length - 1;
                        const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                        if (last) {
                            return (
                                <Typography color="textPrimary" key={to} className="lastItem">
                                    {'Host : ' + hostName.hostName}
                                </Typography>
                            )
                        }
                    })}
                </Breadcrumbs>
                <div style={{float: 'none', height: '22px'}}/>
            </div>
        );
    };

    return (
        <>
            <div className="snapShotsTable">
                <LinkRouter/>
                <div className="tableTitle d-flex justify-content-between">
                    <h3 className="currentHostTableTitle">
                        <span>ACP : </span>
                        <Link to={"/hosts"} style={{textDecoration: "underline", color: "black"}}>
                            <span>Host</span>
                        </Link>
                        <span> : {hostName.hostName} : Domains</span>
                    </h3>
                    {
                        userState.permissions?.includes(Permission.AddDomainInHost) || userState.permissions?.includes(Permission.AddDomainInOwnHost) ||
                        userState.permissionsAssigned?.includes(Permission.AddDomainInHost) || userState.permissionsAssigned?.includes(Permission.AddDomainInOwnHost)
                            ? <StyledButton onClick={e => addDomainInHostOnClicked(e)}
                                            titleClassname="addItemButton"
                                            icon="fas fa-plus"
                                            title="Add Domain" type="danger"
                                            variant="contained" style={{
                                padding: 5,
                                paddingLeft: 10,
                                paddingRight: 10,
                                background: '#343a40',
                                color: '#c2c7d0',
                                border: 'none',
                                borderRadius: 3,
                                cursor: 'pointer',
                                marginBottom: 10,
                                float: 'right'
                            }}
                            />
                            : null
                    }
                </div>
                <section className="content">
                    <div id="table-container">
                        <CustomTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                     texts={{noItems: "No results..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={openForUnmount} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Are you sure you want to unmount the domain?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={unmountDomain} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForUnmount(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Are you sure you want to delete the domain?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteDomain} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForAddDomainInHost} onClose={handleClose} disableEnforceFocus>
                <DialogTitle>
                    Add deploy domain
                </DialogTitle>
                <DialogContent>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Domain Name:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Domain Name"
                                id="name"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setNewDomainInHostData({
                                    ...newDomainInHostData,
                                    domainName: event.target.value
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Config Name:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                label="Config Name"
                                id="configName"
                                type="text"
                                defaultValue={''}
                                fullWidth
                                variant="standard"
                                onChange={(event => setNewDomainInHostData({
                                    ...newDomainInHostData,
                                    configId: event.target.value
                                }))}
                            >
                                {configs.data?.map((option, i) => (
                                    <MenuItem key={`row-configsName-${i}`} value={option.id}>
                                        {option.configName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={addDomainInHost} title="Add domain" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForAddDomainInHost(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};
