import React, {useContext, useEffect, useState} from "react";
import {API} from "../../../../../api/API";
import "../../../../../styles/All.css"
import {Link} from "react-router-dom";
import {Permission} from "../../../../../rbac/Permissions";
import {StyledButton} from "../../../../../customstyled/StyledButton";
import {UsersStore} from "../../../../../stores/UsersStore";
import {ConfigStore} from "../../../../../stores/ConfigStore";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {StyledAsyncSelect} from "../../../../../customstyled/AsyncSelect/StyledAsyncSelect";
import Checkbox from "@material-ui/core/Checkbox";
import {StyledCreatableAsyncSelect} from "../../../../../customstyled/CreatableAsyncSelect/StyledCreatableAsyncSelect";

export const ConfigInfo = (props) => {
    const {state: userState} = useContext(UsersStore);
    const {state: config, dispatch: setConfig} = useContext(ConfigStore);
    const [configInfo, setConfigInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [defaultConfigData, setDefaultConfigData] = useState({});
    const [configDataInput, setConfigDataInput] = useState({
        buildServer: '',
        unityBranch: '',
        webglBranch: '',
        il2CppType: '',
        buildConfig: '',
        logLevel: '',
        developDomains: [],
        productionDomains: [],
        excludePackages: [],
        cdnLink: '',
        needManualTesting: false,
        readyForProduction: false,
        needOnlyStableStaging: false,
        isIncrementalBuild: false,
        isDefaultBuild: false
    });
    const [configId, setConfigId] = useState('');
    const [openForDelete, setOpenForDelete] = useState(false);

    useEffect(() => {
        (async () => {
            const table = await API.Configs.getConfigInfo(props.id);
            setConfigInfo({
                ...configInfo,
                id: table.data.id,
                configName: table.data.configName,
                configData: table.data.configData
            });
        })();
    }, []);

    const onClicked = async (e, configId, configName, configData) => {
        e.preventDefault();
        const data = await API.Configs.getConfigsInit();
        const buildServer = await API.BuildServers.getBuildServers();
        if (configData) {
            configData = JSON.parse(configData);
            setConfig({type: "SET_UNITY_BRANCH", payload: data.data.branches.unity});
            setConfig({type: "SET_WEBGL_BRANCH", payload: data.data.branches.webgl});
            setConfig({type: "SET_CONFIG_NAME", payload: configName});
            setConfig({type: "SET_BUILD_SERVER", payload: buildServer.data});
            try {
                setConfig({type: "SET_DEFAULT_CONFIG_DATA", payload: configData});
            } catch (e) {
                console.log(e);
            }
            setDefaultConfigData({
                ...defaultConfigData,
                buildServer: configData.buildServer,
                unityBranch: configData.unityBranch,
                webglBranch: configData.webglBranch,
                il2CppType: configData.il2CppType,
                buildConfig: configData.buildConfig,
                logLevel: configData.logLevel,
                developDomains: configData.developDomains,
                productionDomains: configData.productionDomains,
                excludePackages: configData.excludePackages,
                cdnLink: configData.cdnLink,
                needManualTesting: configData.needManualTesting,
                readyForProduction: configData.readyForProduction,
                needOnlyStableStaging: configData.needOnlyStableStaging,
                isIncrementalBuild: configData.isIncrementalBuild,
                isDefaultBuild: (configData.isDefaultBuild ? configData.isDefaultBuild : false)
            });
            setNameInput(configName);
        }
        setConfigId(configId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenForDelete(false);
    };

    const editConfig = async () => {
        let body = {
            configId: configId,
            configName: nameInput,
            configData: JSON.stringify(defaultConfigData)
        };
        await API.Configs.ConfigDataUpdate(configId, body);
        setOpen(false);
        const table = await API.Configs.getConfigInfo(props.id);
        setConfigInfo({
            ...configInfo,
            id: table.data.id,
            configName: table.data.configName,
            configData: table.data.configData
        });
    };

    const deleteOnClicked = (e, id) => {
        e.preventDefault();
        setConfigId(id);
        setOpenForDelete(true);
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setDefaultConfigData({
                ...defaultConfigData,
                developDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setDefaultConfigData({
                ...defaultConfigData,
                developDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        }
    };

    const selectedProductionDomains = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setDefaultConfigData({
                ...defaultConfigData,
                productionDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setDefaultConfigData({
                ...defaultConfigData,
                productionDomains: domainNames
            });
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        }
    };

    const excludePackagesChange = (selected) => {
        let excludePackages = [];
        if (selected && selected.length > 0) {
            excludePackages = selected?.map(excludePackages => (excludePackages.excludePackageName));
            setDefaultConfigData({
                ...defaultConfigData,
                excludePackages: excludePackages
            });
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        } else if (selected && selected.length === 0) {
            setDefaultConfigData({
                ...defaultConfigData,
                excludePackages: excludePackages
            });
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        }
    };

    const deleteConfig = async () => {
        await API.Configs.configDelete(configId);
        setOpenForDelete(false);

    };

    const ConfigInfo = () => {
        return (
            <>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px" style={{marginLeft: 15}}><label className="col-form-label">#</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{configInfo.id}</span>
                    </div>
                </div>
                <div className="targetItemInfo">
                    <div className="col-1 margin-left-15px" style={{marginLeft: 15}}><label className="col-form-label">Name</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{configInfo.configName}</span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-15px" style={{marginLeft: 15}}><label className="col-form-label">Data</label></div>
                    <div className="col-11 d-flex align-items-center">
                        <span className="pl-2">{configInfo.configData}</span>
                    </div>
                </div>
                <div className="targetItemInfo last-el">
                    <div className="col-1 margin-left-15px" style={{marginLeft: 15}}><label className="col-form-label">Actions</label></div>
                    <div className="col-11 d-flex align-items-center">
                        {
                            userState.permissions?.includes(Permission.EditConfigs) ||
                            userState.permissionsAssigned?.includes(Permission.EditConfigs)
                                ? <StyledButton
                                    onClick={e => onClicked(e, configInfo.id, configInfo.configName, configInfo.configData)}
                                    tooltip={"Edit config"}
                                    icon="fas fa-edit"
                                    type="danger"
                                    variant="contained" style={{
                                    height: 32,
                                    width: 40,
                                    background: '#343a40',
                                    color: '#c2c7d0',
                                    border: 'none',
                                    borderRadius: 3,
                                    cursor: 'pointer',
                                }}
                                />
                                : null
                        }
                        {
                            userState.permissions?.includes(Permission.DeleteConfigs) ||
                            userState.permissionsAssigned?.includes(Permission.DeleteConfigs)
                                ?
                                <StyledButton onClick={e => deleteOnClicked(e, configInfo.id)} icon="fas fa-solid fa-trash-can"
                                              tooltip={"Delete config"}
                                              type="danger"
                                              variant="contained" style={{
                                    height: 32,
                                    width: 40,
                                    background: '#343a40',
                                    color: '#c2c7d0',
                                    border: 'none',
                                    borderRadius: 3,
                                    cursor: 'pointer',
                                    marginLeft: 3
                                }}
                                />
                                : null
                        }
                    </div>
                </div>
            </>
        )
    };

    return (
        <>
            <h3 className="currentConfigsTableTitle tableTitle marginZero">
                <Link to={"/configs"} style={{textDecoration: "underline", color: "black"}}>
                    <span>Config</span>
                </Link>
                <span> : {configInfo.configName}</span>
            </h3>
            <div className="w-100 d-flex flex-column pl-5 snapshotFilenameInfo">
                <ConfigInfo/>
            </div>
            <Dialog open={open} onClose={handleClose} sx={{width: 600}} disableEnforceFocus>
                <DialogTitle>
                    Edit deploy config
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Config Name:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Config Name"
                                id="name"
                                type="text"
                                value={nameInput}
                                fullWidth
                                variant="standard"
                                onChange={(event => setNameInput(
                                    event.target.value
                                ))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">2) Build Server:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                value={defaultConfigData.buildServer}
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    buildServer: parseInt(event.target.value)
                                }))}
                            >
                                {config.buildServer.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.id}>
                                        {option.serverName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">3) Unity Branch:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.unityBranch}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    unityBranch: event.target.value
                                }))}
                            >
                                {config.unity.map((option, i) => (
                                    <MenuItem key={`row-unity-branch-${i}`} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">4) WebGL Branch:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.webglBranch}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    webglBranch: event.target.value
                                }))}
                            >
                                {config.webgl.map((option, i) => (
                                    <MenuItem key={`row-webgl-branch-${i}`} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">5) Il2Cpp Type:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.il2CppType}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    il2CppType: event.target.value
                                }))}
                            >
                                {config.il2CppType.map((option, i) => (
                                    <MenuItem key={`row-webgl-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">6) Build Config:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.buildConfig}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    buildConfig: event.target.value
                                }))}
                            >
                                {config.buildConfig.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">7) Log Level:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                select
                                id="name"
                                type="text"
                                value={defaultConfigData.logLevel}
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    logLevel: event.target.value
                                }))}
                            >
                                {config.logLevel.map((option, i) => (
                                    <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">8) Develop Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={defaultConfigData.developDomains?.map(domainName => ({domainName}))}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={handleChange}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">9) Production Domains:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledAsyncSelect
                                isMulti={true}
                                defaultValue={defaultConfigData.productionDomains?.map(domainName => ({domainName}))}
                                getOptionLabel={(e) => e.domainName}
                                getOptionValue={(e) => e.domainName}
                                onChange={(value => selectedProductionDomains(value))}
                                className={"styledAsyncSelect"}
                                fetchMethod={API.SnapShots.searchDomains}
                                keyValue='domainName'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">10) Exclude Packages:</label></div>
                        <div className="col-11 align-items-center">
                            <StyledCreatableAsyncSelect
                                isMulti={true}
                                defaultValue={defaultConfigData.excludePackages?.map((excludePackage) => ({
                                    excludePackageName: excludePackage,
                                    excludePackageId: excludePackage
                                }))}
                                getOptionLabel={(e) => e.excludePackageName}
                                getOptionValue={(e) => e.excludePackageId}
                                onChange={excludePackagesChange}
                                className={"styledAsyncSelect"}
                                isInputValueCorrect={true}
                                keyValue='excludePackages'
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">11) CDN Link:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                type="text"
                                value={defaultConfigData.cdnLink}
                                multiline
                                fullWidth
                                variant="standard"
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    cdnLink: event.target.value
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">12) Need manual testing:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.needManualTesting || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    needManualTesting: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">13) Ready for production:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.readyForProduction || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    readyForProduction: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">14) Need only stable staging:</label>
                        </div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.needOnlyStableStaging || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    needOnlyStableStaging: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">15) Is incremental build:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.isIncrementalBuild || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    isIncrementalBuild: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">16) Is default build:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <Checkbox
                                checked={defaultConfigData?.isDefaultBuild || false}
                                onChange={(event => setDefaultConfigData({
                                    ...defaultConfigData,
                                    isDefaultBuild: event.target.checked
                                }))}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={editConfig} title="Edit config" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={openForDelete} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to delete the config?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={deleteConfig} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3
                    }}/>
                    <StyledButton onClick={() => setOpenForDelete(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};