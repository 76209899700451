import {customFetchDeployApi} from "../requests/requests";

export class JobsAPI {

    static async getJobs(page, size) {
        return await customFetchDeployApi({
            path: "/api/v2/jobs?Page=" + (page === undefined ? 1 : page) + "&PageSize=" + (size === undefined ? 20 : size),
            method: 'GET'
        })
            .then(response => {
                return response
            })
    }

    static async JobDelete(id) {
        return await customFetchDeployApi({path: "/api/v2/jobs/" + id, method: 'DELETE'})
            .then(response => {
                return response
            })
    }
}