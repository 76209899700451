import React, {useContext, useEffect, useState} from 'react';
import {API} from "../../api/API";
import {StyledButton} from "../../customstyled/StyledButton";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import {DashboardStore} from "../../stores/DashboardStore";
import {Link, useHistory} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {StyledAsyncSelect} from "../../customstyled/AsyncSelect/StyledAsyncSelect";
import {StyledCreatableAsyncSelect} from "../../customstyled/CreatableAsyncSelect/StyledCreatableAsyncSelect";

let configData = {};
let currentConfigName;

export const ConfigDomainsBuild = () => {
    const {state: config, dispatch: setConfig} = useContext(DashboardStore);
    const [open, setOpen] = useState(false);
    const [stagingVersions, setStagingVersions] = useState([]);
    const [configDataInput, setConfigDataInput] = useState({
        configName: 1,
    });
    const [defaultValue, setDefaultValue] = useState([]);

    const location = useHistory();

    useEffect(() => {
        (async () => {
            const buildData = await API.BuildRequests.buildRequest();
            setConfig({type: 'SET_BUILD_DATA', payload: buildData.data});
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const configName = configDataInput.configName === null ? 1 : configDataInput.configName;
            currentConfigName = configName;
            const getConfig = await API.Configs.getConfigInfo(configName);
            if (configDataInput.configName !== null && currentConfigName !== getConfig.data.id) return;
            let _configData = JSON.parse(getConfig.data.configData);
            setConfig({type: 'SET_SCENARIO', payload: 2});
            try {
                currentConfigName = sessionStorage.getItem('buildConfigName');
                if (currentConfigName !== configName) {
                    sessionStorage.setItem('buildConfigName', configName);
                    configData = {};
                } else {
                    configData = JSON.parse(sessionStorage.getItem('buildConfigData')) || {};
                }
            } catch (_) {
                configData = {};
            }
            _configData = {
                buildServer: configData.buildServer || _configData.buildServer,
                unityBranch: configData.unityBranch || _configData.unityBranch,
                webglBranch: configData.webglBranch || _configData.webglBranch,
                il2CppType: configData.il2CppType || _configData.il2CppType,
                buildConfig: configData.buildConfig || _configData.buildConfig,
                logLevel: configData.logLevel || _configData.logLevel,
                developDomains: configData.developDomains || _configData.developDomains,
                productionDomains: configData.productionDomains || _configData.productionDomains,
                excludePackages: configData.excludePackages || _configData.excludePackages,
                cdnLink: configData.cdnLink || _configData.cdnLink,
                needManualTesting: configData.needManualTesting || _configData.needManualTesting,
                readyForProduction: configData.readyForProduction || _configData.readyForProduction,
                needOnlyStableStaging: configData.needOnlyStableStaging || _configData.needOnlyStableStaging
            };
            sessionStorage.setItem('buildConfigData', JSON.stringify(configData = _configData));
            setConfig({type: 'SET_CONFIG_INFO', payload: _configData});
            setConfig({type: "SET_DATA_INPUT", payload: configDataInput});
            setConfigDataInput({
                ...configDataInput,
                excludePackages: _configData.excludePackages,
                developDomains: _configData.developDomains,
                productionDomains: _configData.productionDomains,
            })
        })();
    }, [configDataInput.configName]);

    useEffect(() => {
        config.unity.forEach(branch => {
            if (branch.startsWith('staging/') && branch.substr(8)) {
                setStagingVersions(branch.substr(8));
            }
        });
    }, [configDataInput.configName, configDataInput.unityBranch]);

    const continueOnClicked = () => {
        setConfig({type: "SET_DATA_INPUT", payload: configDataInput});
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const createConfig = async () => {
        const body = {
            key: config.deployKey,
            buildServerId: parseInt(config.dataInputBuildServer),
            scenario: config.scenario,
            configId: configDataInput.configName,
            configParsed: JSON.stringify({
                buildType: config.scenario !== 3 ? 'release' : 'snapshot',
                buildNumber: config.buildNumber,
                deployKey: config.deployKey,
                configName: configDataInput.configName,
                buildServerId: config.dataInputBuildServer,
                unityBranch: config.dataInputUnityBranch,
                webglBranch: config.dataInputWebglBranch,
                Il2CppType: config.dataInputIl2cppType,
                buildConfig: config.dataInputBuildConfig,
                logLevel: config.dataInputLogLevel,
                developDomains: configDataInput.developDomains,
                productionDomains: configDataInput.productionDomains,
                excludePackages: configDataInput.excludePackages,
                cdnLink: config.dataInputCdLink,
                needManualTesting: config.dataInputNeedManualTesting,
                readyForProduction: config.dataInputReadyForProduction,
                needOnlyStableStaging: config.dataInputOnlyStableStaging
            })
        };
        await API.BuildRequests.addBuildRequest(body);
        setOpen(false);
        location.push('/dashboard');
    };

    const handleChange = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setConfigDataInput({
                ...configDataInput,
                developDomains: domainNames
            });
        }
    };

    const selectedProductionDomains = (selected) => {
        let domainNames = [];
        if (selected && selected.length > 0) {
            domainNames = selected?.map(domainNames => (domainNames.domainName));
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        } else if (selected && selected.length === 0) {
            setConfigDataInput({
                ...configDataInput,
                productionDomains: domainNames
            });
        }
    };

    const onChange = (key, type, payload) => {
        configData[key] = payload;
        sessionStorage.setItem('buildConfigData', JSON.stringify(configData));
        setConfig({
            type,
            payload
        });
    };

    const excludePackagesChange = (selected) => {
        let excludePackages = [];
        if (selected && selected.length > 0) {
            excludePackages = selected?.map(excludePackages => (excludePackages.excludePackageName));
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        } else if (selected && selected.length === 0) {
            setConfigDataInput({
                ...configDataInput,
                excludePackages: excludePackages
            });
        }
    };

    return (
        <>
            <div className="contentWrapper">
                <div className={"configDomainsBuildNav"}>
                    <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                        <Link to="/" className="black routes">
                            <span>Dashboard</span>
                        </Link>
                        <span className="route">Config Domains Build</span>
                    </Breadcrumbs>
                </div>
                <div className="flex-column">
                    <div className="dashboardTitle">
                        <h3>Correct build request config if needed:</h3>
                    </div>
                    <div className="configDomainsBackground">
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">Change Config:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={config?.configName.length > 0 ? (configDataInput.configName || '1') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={(event => {
                                        setConfigDataInput({
                                            ...configDataInput,
                                            configName: event.target.value
                                        })
                                    })}
                                >
                                    {config.configName.map((option, i) => (
                                        <MenuItem key={`row-configName-${i}`} value={option.id}>
                                            {option.configName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">1) Build Server:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    value={config?.buildServer?.length > 0 ? (config.dataInputBuildServer || '') : ''}
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('buildServer', 'SET_DATA_INPUT_BUILD_SERVER', parseInt(event.target.value))}
                                >
                                    {config.buildServer?.map((option, i) => (
                                        <MenuItem key={`row-serverName-branch-${i}`} value={option.id}>
                                            {option.serverName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">2) Unity Branch:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={config?.unity?.length > 0 ? ((config.dataInputUnityBranch === '@last_staging' ? stagingVersions === undefined ||
                                    stagingVersions === false || stagingVersions === null ? '@last_staging' : ('staging/' + stagingVersions) : config.dataInputUnityBranch) || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('unityBranch', 'SET_DATA_INPUT_UNITY_BRANCH', event.target.value)}
                                >
                                    {config.unity.map((option, i) => (
                                        <MenuItem key={`row-unity-branch-${i}`} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">3) WebGL Branch:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={config?.webgl?.length > 0 ? (config?.dataInputWebglBranch || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('webglBranch', 'SET_DATA_INPUT_WEBGL_BRANCH', event.target.value)}
                                >
                                    {config.webgl.map((option, i) => (
                                        <MenuItem key={`row-webgl-branch-${i}`} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">4) Il2Cpp Type:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={config?.il2CppType?.length > 0 ? (config?.dataInputIl2cppType || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('Il2CppType', 'SET_DATA_INPUT_IL2CPP_TYPE', event.target.value)}
                                >
                                    {config.il2CppType.map((option, i) => (
                                        <MenuItem key={`row-webgl-branch-${i}`} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">5) Build Config:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={config?.buildConfig?.length > 0 ? (config?.dataInputBuildConfig || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('buildConfig', 'SET_DATA_INPUT_BUILD_CONFIG', event.target.value)}
                                >
                                    {config.buildConfig.map((option, i) => (
                                        <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">6) Log Level:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    select
                                    id="name"
                                    type="text"
                                    value={config?.logLevel?.length > 0 ? (config?.dataInputLogLevel || '') : ''}
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('logLevel', 'SET_DATA_INPUT_LOG_LEVEL', event.target.value)}
                                >
                                    {config.logLevel.map((option, i) => (
                                        <MenuItem key={`row-serverName-branch-${i}`} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">7) Develop Domains:</label></div>
                            <div className="col-11 align-items-center">
                                <div style={{padding: '6px 0'}}>
                                    <StyledAsyncSelect
                                        isMulti={true}
                                        defaultValue={configDataInput.developDomains?.map(domainName => ({domainName}))}
                                        getOptionLabel={(e) => e.domainName}
                                        getOptionValue={(e) => e.domainName}
                                        onChange={handleChange}
                                        className={"dashboardStyledAsyncSelect"}
                                        fetchMethod={API.SnapShots.searchDomains}
                                        keyValue='domainName'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">8) Production Domains:</label>
                            </div>
                            <div className="col-11 align-items-center">
                                <div style={{padding: '6px 0'}}>
                                    <StyledAsyncSelect
                                        isMulti={true}
                                        defaultValue={configDataInput.productionDomains?.map(domainName => ({domainName}))}
                                        getOptionLabel={(e) => e.domainName}
                                        getOptionValue={(e) => e.domainName}
                                        onChange={selectedProductionDomains}
                                        className={"dashboardStyledAsyncSelect"}
                                        fetchMethod={API.SnapShots.searchDomains}
                                        keyValue='domainName'
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">9) Exclude Packages:</label></div>
                            <div className="col-11 align-items-center">
                                <div style={{padding: '6px 0'}}>
                                    <StyledCreatableAsyncSelect
                                        isMulti={true}
                                        defaultValue={configDataInput.excludePackages?.map((excludePackage) => ({
                                            excludePackageName: excludePackage,
                                            excludePackageId: excludePackage
                                        }))}
                                        getOptionLabel={(e) => e.excludePackageName}
                                        getOptionValue={(e) => e.excludePackageId}
                                        onChange={excludePackagesChange}
                                        className={"dashboardStyledAsyncSelect"}
                                        isInputValueCorrect={true}
                                        keyValue='excludePackages'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">10) CDN Link:</label></div>
                            <div className="col-11 d-flex align-items-center">
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    type="text"
                                    value={config?.dataInputCdLink || ''}
                                    multiline
                                    fullWidth
                                    variant="standard"
                                    onChange={event => onChange('CdLink', 'SET_DATA_INPUT_CD_LINK', event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">11) Need manual testing:</label>
                            </div>
                            <div className="col-11 d-flex align-items-center">
                                <Checkbox
                                    checked={config?.dataInputNeedManualTesting || false}
                                    onChange={event => onChange('needManualTesting', 'SET_DATA_INPUT_NEED_MANUAL_TESTING', event.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">12) Ready for production:</label>
                            </div>
                            <div className="col-11 d-flex align-items-center">
                                <Checkbox
                                    checked={config?.dataInputReadyForProduction || false}
                                    onChange={event => onChange('readyForProduction', 'SET_DATA_INPUT_READY_FOR_PRODUCTION', event.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="form-group w-100 d-flex mb-0">
                            <div className="col-1"><label className="col-form-label">13) Need only stable
                                staging:</label>
                            </div>
                            <div className="col-11 d-flex align-items-center">
                                <Checkbox
                                    checked={config?.dataInputOnlyStableStaging || false}
                                    onChange={event => onChange('onlyStableStaging', 'SET_DATA_INPUT_ONLY_STABLE_STAGING', event.target.checked)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttonBackground">
                        <StyledButton onClick={continueOnClicked} title="Continue" type="danger"
                                      variant="contained" style={{
                            padding: 10,
                            background: '#343a40',
                            color: '#c2c7d0',
                            border: 'none',
                            borderRadius: 3,
                            cursor: 'pointer'
                        }}/>
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Create build request?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={createConfig} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer'
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};
