import React, {createContext, useReducer} from 'react';

export const initialState = {
    list: [],
    renderType: "basic",
    total: 0,
};

export const SnapshotsJobsStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_LIST':
            return {...state, list: action.payload};

        case 'SET_RENDER_TYPE':
            return {...state, renderType: action.payload};
        case 'SET_TOTAL_COUNT':
            return {...state, total: action.payload};
        default:
            return state;
    }
}

export const SnapshotsJobsProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <SnapshotsJobsStore.Provider value={value}>{props.children}</SnapshotsJobsStore.Provider>;
};