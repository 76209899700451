import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Store} from "../../../stores/MainStore";
import {TableStore} from "../../../stores/TableStore";
import Utils from "../../../Auth/Utils";
import {API} from "../../../api/API";
import CustomTable from "../../customTables/CustomTable";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {StyledButton} from "../../../customstyled/StyledButton";
import TextField from "@mui/material/TextField";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import {UsersStore} from "../../../stores/UsersStore";
import {Permission} from "../../../rbac/Permissions";

const UsersTable = () => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(TableStore);
    const {state: userState} = useContext(UsersStore);
    const [openForBlockUnblock, setOpenForBlockUnblock] = useState(false);
    const [userId, setUserId] = useState('');
    const [open, setOpen] = useState(false);
    const [addNewUser, setAddNewUser] = useState({});
    const [isBlocked, setIsBlocked] = useState(false);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(0);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const blockUnblockClicked = (e, id, isBlocked) => {
        e.preventDefault();
        setUserId(id);
        setIsBlocked(isBlocked);
        setOpenForBlockUnblock(true);
    };

    const handleClose = () => {
        setOpenForBlockUnblock(false);
    };

    const blockUnBlockUser = async () => {
        isBlocked === false ? (await API.Users.userBlock(userId)) : (await API.Users.userUnblock(userId));
        setOpenForBlockUnblock(false);
        const table = await API.Users.getUsers(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    const onClicked = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const addUser = async () => {
        await API.Users.addNewUser(addNewUser);
        setOpen(false);
        const table = await API.Users.getUsers(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "start", marginLeft: 21}}>#</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-start">
                    <div className="fileName" style={{marginLeft: 21}}>
                        <span>{rowData.id}</span>
                    </div>
                </div>
            ),
            width: Utils.RemToPx(5)
        },
        {
            name: 'email',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Email</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-left">
                    <div>
                        <span>{rowData.email}</span>
                    </div>
                </div>
            ),
            minWidth: Utils.RemToPx(40)
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex justify-content-center align-items-center">
                    {
                        userState.permissions?.includes(Permission.EditUsers) || userState.permissions?.includes(Permission.EditUserOwner) ||
                        userState.permissionsAssigned?.includes(Permission.EditUsers) || userState.permissionsAssigned?.includes(Permission.EditUserOwner)
                            ? <>
                                <StyledButton onClick={e => blockUnblockClicked(e, rowData.id, rowData.isBlocked)}
                                              icon={rowData.isBlocked === false ? "fas fa-lock-open" : "fas fa-lock-alt"}
                                              tooltip={"Block/Unblock user"}
                                              type="danger"
                                              variant="contained"
                                              style={{
                                                  height: 25,
                                                  width: 28,
                                                  background: '#343a40',
                                                  color: '#c2c7d0',
                                                  border: 'none',
                                                  borderRadius: 3,
                                                  cursor: 'pointer',
                                              }}/>

                                <Link to={'/users/user/' + rowData.id}>
                                    <StyledButton tooltip={"Edit config"}
                                                  icon="fas fa-edit"
                                                  type="danger"
                                                  variant="contained" style={{
                                        height: 25,
                                        width: 28,
                                        background: '#343a40',
                                        color: '#c2c7d0',
                                        border: 'none',
                                        borderRadius: 3,
                                        cursor: 'pointer',
                                        marginLeft: 3
                                    }}
                                    />
                                </Link>
                            </>
                            : null
                    }
                </div>
            ),
            width: Utils.RemToPx(8)
        }
    ].filter(el => el));
    const getList = useCallback(renderType => {
        return state.list;
    }, [state.list]);

    const getListItems = useCallback(async (renderType, {page, size, text}, onError) => {
        const table = await API.Users.getUsers(page, size);
        dispatch({type: "SET_TABLE_INFO", payload: table});
        const total = table.totalCount;
        setPage(page);
        setSize(size);
        dispatch({type: "SET_LIST", payload: table.data});
        dispatch({type: "SET_TOTAL_COUNT", payload: table.totalCount});

        return total ?? 0;
    }, []);

    const setRenderType = renderType => dispatch({type: "SET_RENDER_TYPE", payload: renderType});
    const clearLists = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearLists();
            dispatch({type: "SET_LIST", payload: 0});
            setRenderType("basic");
        };
    }, []);

    return (
        <>
            <div className="snapShotsTable">
                <div style={{float: 'none'}}>
                    <div className="routeContainer">
                        <Breadcrumbs aria-label="breadcrumb" className="currentSnapshotInfo">
                            <Link to="/" className="black routes">
                                <span>Dashboard</span>
                            </Link>
                            <span className="route">Users</span>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="tableTitle d-flex justify-content-between">
                    <h3 className="userTableTitle">ACP : Users</h3>
                    <StyledButton onClick={e => onClicked(e)}
                                  titleClassname="addItemButton"
                                  icon="fas fa-plus"
                                  title="Add user" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        height: 28,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}
                    />
                </div>
                <section className="content">
                    <div id="table-container">
                        <CustomTable state={{
                            typeOfRender: state.renderType,
                            canSelect: false,
                            canHover: false,
                            canSearch: false,
                            pagination: true
                        }} callbacks={{getList, getColumns, getListItems, setRenderType, clearLists, onError}}
                                     texts={{noItems: "No users..."}}/>
                    </div>
                </section>
            </div>
            <Dialog open={openForBlockUnblock} onClose={handleClose}>
                <DialogTitle>
                    Are you sure you want to {isBlocked === false ? 'block' : 'unblock'} the user?
                </DialogTitle>
                <DialogActions>
                    <StyledButton onClick={blockUnBlockUser} title="Confirm" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpenForBlockUnblock(false)} autoFocus
                                  disabled={false} title="Cancel"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    Add user to acp
                </DialogTitle>
                <DialogContent dividers>
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">1) Email:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                className="user-email-model"
                                autoFocus
                                margin="dense"
                                id="name"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setAddNewUser({...addNewUser, email: event.target.value}))}
                            />
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 last">
                        <div className="col-1"><label className="col-form-label">1) Password:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={(event => setAddNewUser({...addNewUser, password: event.target.value}))}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={addUser} title="Add user" type="danger"
                                  variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                    <StyledButton onClick={() => setOpen(false)} autoFocus
                                  disabled={false} title="Close"
                                  type="success" variant="contained" style={{
                        padding: 5,
                        background: '#343a40',
                        color: '#c2c7d0',
                        border: 'none',
                        borderRadius: 3,
                        cursor: 'pointer',
                    }}/>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UsersTable;